import { TRootState } from '@/redux/rootReducer';

export const getIsCampsite = (state: TRootState) =>
  state.listing.data?.rental_category === 'campground';

export const getIsStationaryCamperCampsite = (state: TRootState) =>
  state.listing.data?.campsite_category?.category_type === 'stationary_camper_site';

export const getCampsiteCampgroundCatalog = (state: TRootState) =>
  state.listing.data?.campground_catalog;

export const getIsCampgroundListing = (state: TRootState) => state.campgroundListing.data !== null;

export const getCampground = (state: TRootState) => state.listing.data?.campground || undefined;
