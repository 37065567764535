import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRouter } from '@/hooks/useRouter';
import { updateUser } from '@/redux/modules/auth';
import { useThunkDispatch } from '@/utility/redux/dispatch';

import InlineErrorMessage from '../switchback/InlineErrorMessage/InlineErrorMessage';
import Modal, { ModalSize } from '../switchback/Modal/Modal';
import { useToast } from '../switchback/Toast/ToastProvider';
import css from './TermsAndConditionsModal.module.css';

export interface ITermsAndConditionsDialog {
  version?: number;
  htmlContent?: string;
}

const TermsAndConditionsModal: React.FC<ITermsAndConditionsDialog> = ({ version, htmlContent }) => {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useThunkDispatch();
  const { addToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!htmlContent || !version || router.pathname.includes('/security')) {
    return null;
  }

  const onClickOutside = () => {
    if (!showWarning) {
      setShowWarning(true);
    }
  };

  const onAccept = async () => {
    setIsSubmitting(true);
    const isSuccess = await dispatch(updateUser({ accepted_tos_version: version }));

    if (isSuccess) {
      setIsSubmitting(true);
      setIsModalOpen(false);
    } else {
      setIsSubmitting(false);
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!' }),
        description: intl.formatMessage({
          defaultMessage: 'There was an error updating your user data. Please try again.',
        }),
        type: 'TOAST_ERROR',
      });
    }
  };

  return (
    <Modal
      size={ModalSize.medium}
      title={intl.formatMessage({
        defaultMessage: "We've updated our Outdoorsy Terms of Service",
      })}
      open={isModalOpen}
      onClose={onClickOutside}
      disabledManualClose={true}
      footer={
        <div className="flex flex-col md:flex-row">
          {showWarning && (
            <InlineErrorMessage className="self-center">
              {intl.formatMessage({
                defaultMessage: 'Accept the new terms before proceeding.',
              })}
            </InlineErrorMessage>
          )}
          <div className="md:ml-auto">
            <Button
              className="w-full md:w-auto"
              variant={EButtonColorVariant.Primary}
              label={
                <FormattedMessage
                  defaultMessage="Accept"
                  description="Terms and Conditions Modal > Terms and Conditions Modal Footer"
                />
              }
              onClick={onAccept}
              disabled={isSubmitting}
            />
          </div>
        </div>
      }>
      <div className={`${css.content} mb-4`}>
        <div className="" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
