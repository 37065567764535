import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { updateQualtricTracking } from '@/components/qualtrics/qualtric.service';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useRouter } from '@/hooks/useRouter';
import { getIsAuthenticated, getUser } from '@/redux/selectors/auth/user';
import { getBookingData } from '@/redux/selectors/checkout/booking';
import { getODAnonymousId } from '@/services/segment';
import { getAuthToken } from '@/utility/session';

const useUpdateQualtricTracking = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);
  const [anonymousId, setAnonymousId] = useState<string>();
  const booking = useSelector(getBookingData);
  const { isMobile } = useBreakpoint();
  const router = useRouter();
  const currentCheckoutStep = (router.query.step as string) || null;
  const pathname = router.asPath || '';

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && !getAuthToken()) {
        const anonId = await getODAnonymousId();
        anonId && setAnonymousId(anonId);
      } else {
        setAnonymousId(undefined);
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    const PageName = pathname.split('?')[0];
    const PageTitle = document.title?.replace(' | Outdoorsy', '');
    const UserId = user?.id;
    const LoggedIn = isAuthenticated;
    const Email = user?.email;
    const FirstName = user?.first_name;
    const LastName = user?.last_name;
    const Platform = isMobile ? 'MWeb' : 'Web';
    const BookingId = booking?.id;
    const CheckoutStep = currentCheckoutStep;

    let AccountType = 'unknown';

    if (user) {
      AccountType = user?.owner ? 'owner' : 'renter';
    }

    const getTripType = () => {
      if (!booking) return;
      if (booking.delivery.stationary) return 'Stationary delivery';
      if (booking.pickup.delivery) return 'Delivery';
      return 'Pickup';
    };
    const TripType = getTripType();

    const qualtricTrackingData = {
      PageName,
      PageTitle,
      UserId,
      AnonymousId: anonymousId,
      LoggedIn,
      Email,
      FirstName,
      LastName,
      TripType,
      Platform,
      BookingId,
      CheckoutStep,
      AccountType,
    };

    (Object.keys(qualtricTrackingData) as Array<keyof typeof qualtricTrackingData>).forEach(key => {
      if (qualtricTrackingData[key] == null) {
        delete qualtricTrackingData[key];
      }
    });

    updateQualtricTracking(qualtricTrackingData);
  }, [anonymousId, booking, currentCheckoutStep, isAuthenticated, isMobile, pathname, user]);

  return null;
};

export default useUpdateQualtricTracking;
