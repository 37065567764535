import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { TLocale } from '@/config/locales';
import { useGetSoonestTripIdQuery } from '@/graphql/types-and-hooks';
import { getUser } from '@/redux/selectors/auth/user';
import { getUserCurrency } from '@/redux/selectors/currency';
import { formatCurrency } from '@/utility/currency';

export interface IMenuItem {
  href: string;
  label: string;
  description?: string;
  testid?: string;
}

export const LOCALE_DESTINATIONS: { [key in TLocale]?: IMenuItem[] } = {
  'en-us': [
    { href: '/rv-rental/georgia/atlanta', label: 'Atlanta' },
    { href: '/rv-rental/california/los-angeles', label: 'Los Angeles' },
    { href: '/rv-rental/nevada/las-vegas', label: 'Las Vegas' },
    { href: '/rv-rental/washington/seattle', label: 'Seattle' },
    { href: '/rv-rental/texas/austin', label: 'Austin' },
    { href: '/rv-rental/new-york/new-york', label: 'New York' },
    { href: '/rv-rental/texas/houston', label: 'Houston' },
    { href: '/rv-rental/arizona/phoenix', label: 'Phoenix' },
    { href: '/rv-rental/california/san-francisco', label: 'San Francisco' },
    { href: '/rv-rental/california/san-diego', label: 'San Diego' },
  ],
  'en-ca': [
    {
      label: 'Winnipeg',
      href: '/rv-rental/canada/manitoba/winnipeg',
    },
    {
      label: 'Calgary',
      href: '/rv-rental/canada/alberta/calgary',
    },
    {
      label: 'Saskatoon',
      href: '/rv-rental/canada/saskatchewan/saskatoon',
    },
    {
      label: 'Vancouver',
      href: '/rv-rental/canada/british-columbia/vancouver',
    },
    {
      label: 'Nanaimo',
      href: '/rv-rental/canada/british-columbia/nanaimo',
    },
    {
      label: 'London',
      href: '/rv-rental/canada/ontario/london',
    },
    {
      label: 'Regina',
      href: '/rv-rental/canada/saskatchewan/regina',
    },
    {
      label: 'Edmonton',
      href: '/rv-rental/canada/alberta/edmonton',
    },
    {
      label: 'Hamilton',
      href: '/rv-rental/canada/ontario/hamilton',
    },
    {
      label: 'Victoria',
      href: '/rv-rental/canada/british-columbia/victoria',
    },
    {
      label: 'Ottawa',
      href: '/rv-rental/canada/ontario/ottawa',
    },
    {
      label: 'Kelowna',
      href: '/rv-rental/canada/british-columbia/kelowna',
    },
    {
      label: 'Montreal',
      href: '/rv-rental/canada/quebec/montreal',
    },
    {
      label: 'Red Deer',
      href: '/rv-rental/canada/alberta/red-deer',
    },
    {
      label: 'Barrie',
      href: '/rv-rental/canada/ontario/barrie',
    },
    {
      label: 'Kamloops',
      href: '/rv-rental/canada/british-columbia/kamloops',
    },
    {
      label: 'Toronto',
      href: '/rv-rental/canada/ontario/toronto',
    },
    {
      label: 'Windsor',
      href: '/rv-rental/canada/ontario/windsor',
    },
    {
      label: 'Banff',
      href: '/rv-rental/canada/alberta/banff',
    },
    {
      label: 'Niagara Falls',
      href: '/rv-rental/canada/ontario/niagara-falls',
    },
    {
      label: 'Prince George',
      href: '/rv-rental/canada/british-columbia/prince-george',
    },
    {
      label: 'Campbell River',
      href: '/rv-rental/canada/british-columbia/campbell-river',
    },
    {
      label: 'Sault Ste. Marie',
      href: '/rv-rental/canada/ontario/sault-ste-marie',
    },
    {
      label: 'Mississauga',
      href: '/rv-rental/canada/ontario/mississauga',
    },
    {
      label: 'Kitchener',
      href: '/rv-rental/canada/ontario/kitchener',
    },
    {
      label: 'Chilliwack',
      href: '/rv-rental/canada/british-columbia/chilliwack',
    },
    {
      label: 'Penticton',
      href: '/rv-rental/canada/british-columbia/penticton',
    },
    {
      label: 'Sarnia',
      href: '/rv-rental/canada/ontario/sarnia',
    },
  ],
  'fr-ca': [
    {
      label: 'Winnipeg',
      href: '/rv-rental/canada/manitoba/winnipeg',
    },
    {
      label: 'Calgary',
      href: '/rv-rental/canada/alberta/calgary',
    },
    {
      label: 'Saskatoon',
      href: '/rv-rental/canada/saskatchewan/saskatoon',
    },
    {
      label: 'Vancouver',
      href: '/rv-rental/canada/british-columbia/vancouver',
    },
    {
      label: 'Nanaimo',
      href: '/rv-rental/canada/british-columbia/nanaimo',
    },
    {
      label: 'London',
      href: '/rv-rental/canada/ontario/london',
    },
    {
      label: 'Regina',
      href: '/rv-rental/canada/saskatchewan/regina',
    },
    {
      label: 'Edmonton',
      href: '/rv-rental/canada/alberta/edmonton',
    },
    {
      label: 'Hamilton',
      href: '/rv-rental/canada/ontario/hamilton',
    },
    {
      label: 'Victoria',
      href: '/rv-rental/canada/british-columbia/victoria',
    },
    {
      label: 'Ottawa',
      href: '/rv-rental/canada/ontario/ottawa',
    },
    {
      label: 'Kelowna',
      href: '/rv-rental/canada/british-columbia/kelowna',
    },
    {
      label: 'Montreal',
      href: '/rv-rental/canada/quebec/montreal',
    },
    {
      label: 'Red Deer',
      href: '/rv-rental/canada/alberta/red-deer',
    },
    {
      label: 'Barrie',
      href: '/rv-rental/canada/ontario/barrie',
    },
    {
      label: 'Kamloops',
      href: '/rv-rental/canada/british-columbia/kamloops',
    },
    {
      label: 'Toronto',
      href: '/rv-rental/canada/ontario/toronto',
    },
    {
      label: 'Windsor',
      href: '/rv-rental/canada/ontario/windsor',
    },
    {
      label: 'Banff',
      href: '/rv-rental/canada/alberta/banff',
    },
    {
      label: 'Niagara Falls',
      href: '/rv-rental/canada/ontario/niagara-falls',
    },
    {
      label: 'Prince George',
      href: '/rv-rental/canada/british-columbia/prince-george',
    },
    {
      label: 'Campbell River',
      href: '/rv-rental/canada/british-columbia/campbell-river',
    },
    {
      label: 'Sault Ste. Marie',
      href: '/rv-rental/canada/ontario/sault-ste-marie',
    },
    {
      label: 'Mississauga',
      href: '/rv-rental/canada/ontario/mississauga',
    },
    {
      label: 'Kitchener',
      href: '/rv-rental/canada/ontario/kitchener',
    },
    {
      label: 'Chilliwack',
      href: '/rv-rental/canada/british-columbia/chilliwack',
    },
    {
      label: 'Penticton',
      href: '/rv-rental/canada/british-columbia/penticton',
    },
    {
      label: 'Sarnia',
      href: '/rv-rental/canada/ontario/sarnia',
    },
  ],
};

export const useHelpMenuItems = () => {
  const intl = useIntl();

  return getHelpMenuItems(intl);
};

const getHelpMenuItems = (intl: IntlShape) => {
  const HELP_LINKS: IMenuItem[] = [
    {
      href: '/how-it-works',
      label: intl.formatMessage({
        defaultMessage: 'How it works',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Rent from a pro and travel like one, too.',
        description: 'Menu constants > Help link',
      }),
    },
    {
      href: '/help',
      label: intl.formatMessage({
        defaultMessage: 'Help center',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Have a question? Let us help.',
        description: 'Menu constants > Help link',
      }),
    },
    {
      href: '/roadside-assistance',
      label: intl.formatMessage({
        defaultMessage: 'Roadside assistance',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage: 'In-person support no matter where the road takes you.',
        description: 'Menu constants > Help link',
      }),
    },
    {
      href: '/insurance',
      label: intl.formatMessage({
        defaultMessage: 'Insurance',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage: 'You’re covered with our custom protection packages.',
        description: 'Menu constants > Help link',
      }),
    },
    {
      href: '/blog',
      label: intl.formatMessage({
        defaultMessage: 'RV tricks & tips',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Tips to grow your RV rental business. Tricks to find the perfect rig.',
        description: 'Menu constants > Help link',
      }),
    },
    {
      href: '/referral/join',
      label: intl.formatMessage({
        defaultMessage: 'Refer a friend, earn $75',
        description: 'Menu constants > Help link',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'The more your referral link gets used, the more money you’ll start earning.',
        description: 'Menu constants > Help link',
      }),
    },
  ];

  return HELP_LINKS;
};

export const useMyAccountMenuItems = () => {
  const intl = useIntl();

  const { data: soonestTrip } = useGetSoonestTripIdQuery();
  const soonestTripId = soonestTrip?.getSoonestTripId?.booking_id;
  const { credits } = useSelector(getUser) || { credits: 0 };
  const currency = useSelector(getUserCurrency);

  return getMyAccountMenuItems(intl, soonestTripId, credits, currency);
};

const getMyAccountMenuItems = (
  intl: IntlShape,
  soonestTripId?: number | null,
  credits = 0,
  currency = 'USD',
) => {
  const MY_ACCOUNT_MENU: IMenuItem[] = [
    {
      href: '/referral/renter',
      label:
        credits > 0
          ? intl.formatMessage(
              {
                defaultMessage: '{availableCredits} in credits available',
                description: 'Menu constants > My account',
              },
              {
                availableCredits: formatCurrency({
                  priceInCents: credits,
                  currency,
                  digits: 2,
                }),
              },
            )
          : intl.formatMessage({
              defaultMessage: 'Refer friends, earn credits ',
              description: 'Menu constants > My account',
            }),
    },
    {
      href: '/saved',
      label: intl.formatMessage({
        defaultMessage: 'Favorites',
        description: 'Menu constants > My account',
      }),
    },
    {
      href: soonestTripId ? `/trips/${soonestTripId}` : '/trips',
      label: intl.formatMessage({
        defaultMessage: 'Trips',
        description: 'Menu constants > My account',
      }),
    },
    {
      href: '/messages',
      label: intl.formatMessage({
        defaultMessage: 'Messages',
        description: 'Menu constants > My account',
      }),
    },
    {
      href: '/dashboard/profile',
      label: intl.formatMessage({
        defaultMessage: 'Profile',
        description: 'Menu constants > My account',
      }),
    },
    {
      href: '/dashboard/account/notifications',
      label: intl.formatMessage({
        defaultMessage: 'Settings',
        description: 'Menu constants > My account',
      }),
    },
  ];

  return MY_ACCOUNT_MENU;
};
