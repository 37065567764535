export enum EDeliveryOption {
  MOVING = 'moving',
  STATIONARY = 'stationary',
  RV_STAY = 'rv-stay',
}

export enum EDeliveryFields {
  DELIVERY_ADDRESS = 'delivery-address',
  DELIVERY_DISTANCE = 'delivery-distance',
  DELIVERY_LOCATION = 'delivery-location',
  DELIVERY_VALID = 'delivery-valid',
  DELIVERY_STATIONARY = 'stationary-moving',
  LOCATION = 'location',
  RV_STAY_DELIVERY = 'rv-stay-delivery',
  DELIVERY_OPTION = 'delivery-option',
}

export type TDeliveryFields = {
  [EDeliveryFields.DELIVERY_ADDRESS]: string;
  [EDeliveryFields.DELIVERY_DISTANCE]: string;
  [EDeliveryFields.DELIVERY_LOCATION]: string;
  [EDeliveryFields.DELIVERY_VALID]: string;
  [EDeliveryFields.DELIVERY_STATIONARY]: string;
  [EDeliveryFields.LOCATION]: string;
  [EDeliveryFields.RV_STAY_DELIVERY]: boolean;
  [EDeliveryFields.DELIVERY_OPTION]: string;
};
