import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import apiRequest from '@/services/apiRequest';
import { IFaq } from '@/services/types/faq/faqs';
import { getSearchApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';

const FETCH_FAQS_FAILURE = 'faqs/FETCH_FAQS_FAILURE';
const FETCH_FAQS_SUCCESS = 'faqs/FETCH_FAQS_SUCCESS';
const FETCH_FAQS_PENDING = 'faqs/FETCH_FAQS_PENDING';

export enum FAQsContentType {
  CAMPGROUND = 'campground_faq',
  LISTING = 'listing',
  ALL = '',
}
type TFailureAction = {
  type: typeof FETCH_FAQS_FAILURE;
  contentType: FAQsContentType;
  error: AxiosError;
};
type TPendingAction = {
  type: typeof FETCH_FAQS_PENDING;
  contentType: FAQsContentType;
};
type TSuccessAction = {
  type: typeof FETCH_FAQS_SUCCESS;
  contentType: FAQsContentType;
  data: IFaq | null;
};
type TFaqsAction = TFailureAction | TPendingAction | TSuccessAction;

const fetchFAQsFailure = ({
  error,
  contentType,
}: {
  error: AxiosError;
  contentType: FAQsContentType;
}): TFailureAction => ({
  type: FETCH_FAQS_FAILURE,
  contentType,
  error,
});
const fetchFAQsSuccess = ({
  contentType,
  data,
}: {
  contentType: FAQsContentType;
  data: IFaq | null;
}): TSuccessAction => {
  return {
    type: FETCH_FAQS_SUCCESS,
    contentType,
    data,
  };
};
const fetchFAQsPending = ({ contentType }: { contentType: FAQsContentType }): TPendingAction => ({
  type: FETCH_FAQS_PENDING,
  contentType,
});
const fetchFAQs = async (contentType: FAQsContentType): Promise<IFaq | null> => {
  const url = `${getSearchApi()}/content?filter=metadata.faq_topic=${contentType}`;
  const response = await apiRequest<[IFaq]>({ url }, true);
  return response?.[0] || null;
};
export const getFAQs = (contentType: FAQsContentType) => async (dispatch: Dispatch) => {
  dispatch(fetchFAQsPending({ contentType }));
  try {
    const faqs = await fetchFAQs(contentType);
    dispatch(fetchFAQsSuccess({ data: faqs, contentType }));
  } catch (error) {
    logger.captureExceptionWithDatadog(error, { url: error });
    dispatch(fetchFAQsFailure({ error, contentType }));
  }
};

type IState = Record<
  FAQsContentType,
  { error: AxiosError | null; isFetched: boolean; data: IFaq | null }
>;

export const initialState: IState = {
  campground_faq: {
    data: null,
    error: null,
    isFetched: false,
  },
  listing: {
    data: null,
    error: null,
    isFetched: false,
  },
  '': {
    data: null,
    error: null,
    isFetched: false,
  },
};

export default function reducer(state = initialState, action: TFaqsAction) {
  const { contentType } = action;
  switch (action.type) {
    case FETCH_FAQS_PENDING:
      return {
        ...state,
        [contentType]: {
          ...state[contentType],
          isFetched: false,
        },
      };
    case FETCH_FAQS_SUCCESS:
      return {
        ...state,
        [contentType]: {
          ...state[contentType],
          data: action.data,
          isFetched: true,
        },
      };
    case FETCH_FAQS_FAILURE:
      return {
        ...state,
        [contentType]: {
          ...state[contentType],
          error: action.error,
          data: null,
          isFetched: true,
        },
      };
    default:
      return state;
  }
}
