import Head from 'next/head';
import React from 'react';

type TExternalFontsProps = {
  href: string;
  preload?: boolean;
  preconnect?: string[];
};

export const ExternalFonts = ({ href, preload = true, preconnect }: TExternalFontsProps) => (
  <Head>
    {preload && <link rel="preload" as="style" href={href} />}

    {preconnect?.map((url, index) => (
      <link key={index} rel="preconnect" href={url} crossOrigin="true" />
    ))}

    <link rel="stylesheet" href={href} />
  </Head>
);
