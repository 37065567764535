import {
  Divider,
  EHeadingStyleVariant,
  Heading,
  Icon,
  Modal,
  Text,
  TextInput,
} from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import useMapboxQuery from '@/hooks/useMapboxQuery';
import { LocationGroupType, setSurfacedLocation } from '@/utility/surfacedLocation';

enum EListVariants {
  MATCHES = 'matches',
  RECENT_SEARCHES = 'recent_searches',
}

type TMobileSearchAddressModalContentProps = {
  onCloseHandler: () => void;
  location?: { lat: number; lng: number };
  address?: string;
  onChangeAddress?: (address?: string) => void;
  popularDestinations?: { label: string }[];
  recentSearches?: IAutocompleteOption[];
  handleRecentSearchNavigation?: (url: string) => void;
  maxRecentSearches: number;
};

export const MobileSearchAddressModalContent = ({
  location,
  onCloseHandler,
  address,
  onChangeAddress,
  popularDestinations = [],
  recentSearches = [],
  handleRecentSearchNavigation,
  maxRecentSearches,
}: TMobileSearchAddressModalContentProps) => {
  const intl = useIntl();
  const [addressValue, setAddressValue] = useState(address || '');

  const placesOption = {
    text: addressValue,
    ...(location ? { location: [location.lng, location.lat] } : {}),
    types: 'country,region,postcode,district,place,locality,neighborhood,address',
  };
  const places = useMapboxQuery(addressValue.length < 3 ? null : placesOption);
  const suggestions = places.map(p => ({ label: p.place_name, value: p }));

  let options: IAutocompleteOption[] = [];
  if (suggestions.length) {
    options = suggestions;
  } else if (recentSearches?.length) {
    options = recentSearches?.slice(0, maxRecentSearches);
  }

  const onChangeAddressHandler = (value: string) => {
    setAddressValue(value);
    onChangeAddress?.(value);
    setSurfacedLocation(LocationGroupType.OTHER);
  };

  const onSelectPopularDestination = (value: string) => {
    setAddressValue(value);
    onChangeAddress?.(value);
    setSurfacedLocation(LocationGroupType.POPULAR_DESTINATIONS);
    onCloseHandler?.();
  };

  const onSelectLocation = (option: IAutocompleteOption) => {
    const { label, url } = option;
    if (url) {
      setAddressValue(label.split('•').map(part => part.trim())[0] || '');
      handleRecentSearchNavigation?.(url);
      return;
    }

    setSurfacedLocation(LocationGroupType.MATCHES);

    setAddressValue(label);
    onChangeAddress?.(label);
    onCloseHandler?.();
  };

  return (
    <>
      <Modal.Actions className="top-4" />
      <Modal.Header inlineHeader={true} className="!pb-0 !pt-[10px] !px-4">
        <Heading variant={EHeadingStyleVariant.H6}>
          <FormattedMessage defaultMessage="Where are you heading?" />
        </Heading>
      </Modal.Header>
      <Modal.Content className="!mt-5 !px-4">
        <div className="flex flex-col gap-4">
          <TextInput
            controlled
            label={intl.formatMessage({ defaultMessage: 'Location' })}
            inputValue={addressValue}
            onChange={onChangeAddressHandler}
            showClearButton
          />
          {suggestions && suggestions.length > 0 ? (
            <OptionsList
              options={options}
              onSelectOption={onSelectLocation}
              listVariant={EListVariants.MATCHES}
            />
          ) : (
            <>
              {popularDestinations &&
                popularDestinations.length > 0 &&
                addressValue.length === 0 && (
                  <PopularDestinationsList
                    popularDestinations={popularDestinations}
                    onSelectLocation={onSelectPopularDestination}
                  />
                )}
              {recentSearches && recentSearches.length > 0 && addressValue.length > 0 && (
                <OptionsList
                  options={options}
                  onSelectOption={onSelectLocation}
                  listVariant={EListVariants.RECENT_SEARCHES}
                />
              )}
            </>
          )}
        </div>
      </Modal.Content>
    </>
  );
};

type TPopularDestinationsListProps = {
  popularDestinations: {
    label: string;
  }[];
  onSelectLocation: (value: string) => void;
};

const PopularDestinationsList = ({
  popularDestinations,
  onSelectLocation,
}: TPopularDestinationsListProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Icon name="General.Star.Outline" />
          <Text className="font-medium">
            <FormattedMessage defaultMessage="Popular destinations" />
          </Text>
        </div>
        <Divider />
      </div>
      <ul>
        {popularDestinations.map((des, index) => (
          <li key={des.label}>
            <button
              onClick={() => onSelectLocation(des.label)}
              className="flex flex-col items-start w-full pt-4 gap-4">
              <Text className="text-gray-50">{des.label}</Text>
              {index !== popularDestinations.length - 1 && <Divider />}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

type TOptionsListProps = {
  options: IAutocompleteOption[];
  onSelectOption: (option: IAutocompleteOption) => void;
  listVariant: EListVariants;
};

const OptionsList = ({ options, onSelectOption, listVariant }: TOptionsListProps) => {
  return (
    <div className="flex flex-col">
      {listVariant === EListVariants.RECENT_SEARCHES && (
        <div className="flex flex-col mb-4 gap-4">
          <div className="flex items-center gap-2">
            <Icon name="General.Clock" />
            <Text className="font-medium">
              <FormattedMessage defaultMessage="Recent searches" />
            </Text>
          </div>
        </div>
      )}
      <ul>
        {options.map((option, index) => {
          const [address, ...rest] =
            listVariant === EListVariants.RECENT_SEARCHES
              ? option.label.split('•').map(part => part.trim())
              : option.label.split(',').map(part => part.trim());

          return (
            <li key={option.label}>
              <button
                onClick={() => onSelectOption(option)}
                className="flex flex-col items-start w-full pb-4 gap-4">
                {
                  <div className="flex items-center gap-3">
                    <div className="text-2xl">
                      <Icon name="General.MapPin.Outline" />
                    </div>
                    <div className="text-start">
                      <Text className="font-medium">{address}</Text>
                      <Text className="text-start text-gray-50">{rest.join(', ')}</Text>
                    </div>
                  </div>
                }
                {index !== options.length - 1 && <Divider />}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
