import { ConsentManager } from '@segment/consent-manager';
import snippet from '@segment/snippet';
import Script from 'next/script';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { isSSR } from '@/utility/isSSR';

/**
 * Note that closing out of the footer banner might present legal implications.
 * If we need to have a button visible at all times for compliance, we can use
 * the openConsentManager hook to render the consent manager modal:
 *
 *   import { openConsentManager } from '@segment/consent-manager'
 *   <button type="button" onClick={openConsentManager}>
 *     Website Data Collection Preferences
 *   </button>
 */

// TODO figure out if we have a different website data collection policy other than the cookie-policy
function GDPR() {
  const [isSegmentSnippetReady, setIsSegmentSnippetReady] = useState(false);
  const intl = useIntl();

  if (!process.env.segmentAppKey) return null;

  // Does not make sense for SSR, e.g. inEU requires window.
  if (isSSR()) return null;

  const bannerContent = intl.formatMessage(
    {
      defaultMessage:
        'We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using our website, you’re agreeing to the collection of data as described in our <a>Website Data Collection Policy</a>. For more details about cookies and how to manage them, see our <a>Cookie Policy</a>.',
    },
    {
      a: text => (
        <a href="/cookie-policy" target="_blank">
          {text}
        </a>
      ),
    },
  );
  const bannerSubContent = intl.formatMessage({
    defaultMessage: 'You can change your preferences at any time.',
  });
  const preferencesDialogTitle = intl.formatMessage({
    defaultMessage: 'Website Data Collection Preferences',
  });
  const preferencesDialogContent = intl.formatMessage({
    defaultMessage:
      'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.',
  });
  const cancelDialogTitle = intl.formatMessage({
    defaultMessage: 'Are you sure you want to cancel?',
  });
  const cancelDialogContent = intl.formatMessage({
    defaultMessage:
      'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.',
  });

  return (
    <>
      <Script
        onReady={() => {
          setIsSegmentSnippetReady(true);
        }}
        dangerouslySetInnerHTML={{
          __html: snippet.min({
            apiKey: process.env.segmentAppKey,
            page: false,
          }),
        }}
      />
      {isSegmentSnippetReady && (
        <div className="fixed bottom-0 w-full z-[2147483647]">
          <ConsentManager
            writeKey={process.env.segmentAppKey}
            bannerContent={bannerContent}
            bannerSubContent={bannerSubContent}
            preferencesDialogTitle={preferencesDialogTitle}
            preferencesDialogContent={preferencesDialogContent}
            cancelDialogTitle={cancelDialogTitle}
            cancelDialogContent={cancelDialogContent}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(GDPR);
