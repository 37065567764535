import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { AccountFormTemplate } from '@/components/auth/AccountFormTemplate';
import Modal from '@/components/switchback/Modal';
import { ModalSize } from '@/components/switchback/Modal/Modal';

import ForgotPasswordForm, { ForgotPasswordFormProps } from './ForgotPasswordForm/component';
import { IProps as ILoginFormProps, LoginForm } from './LoginForm';

interface IProps {
  isAuthenticated: boolean;
  isOpen: boolean;
  isResettingPassword: boolean;
  onSignup: () => void;
  onForgotPassword: () => void;
  onClose: () => void;
  loginFormProps: Omit<ILoginFormProps, 'onForgotPassword' | 'isModal'>;
  resetPasswordFormProps: Omit<ForgotPasswordFormProps, 'onLogin' | 'loginRedirect' | 'hideHeader'>;
}

const LoginModal: React.FC<IProps> = ({
  isAuthenticated,
  isOpen,
  isResettingPassword,
  onSignup,
  onClose,
  onForgotPassword,
  loginFormProps,
  resetPasswordFormProps,
}) => {
  const intl = useIntl();

  let modalTitle = null;
  let contentComponent = null;

  if (isResettingPassword) {
    modalTitle = intl.formatMessage({
      defaultMessage: 'Reset your password',
      description: 'Login Modal Title',
    });

    contentComponent = (
      <ForgotPasswordForm {...resetPasswordFormProps} onLogin={onForgotPassword} hideHeader />
    );
  } else {
    modalTitle = intl.formatMessage({
      defaultMessage: 'Log in with your email',
      description: 'Login Modal Title',
    });

    contentComponent = (
      <AccountFormTemplate onSignup={onSignup}>
        <LoginForm {...loginFormProps} onForgotPassword={onForgotPassword} isModal />
      </AccountFormTemplate>
    );
  }

  useEffect(() => {
    if (isAuthenticated && isOpen) {
      onClose();
    }
  }, [isAuthenticated, isOpen, onClose]);

  return (
    <Modal
      size={ModalSize.medium}
      open={isOpen}
      onClose={onClose}
      data-testid="login-modal"
      title={modalTitle}
      className="!z-[1401]">
      <hr className="h-px my-8 bg-gray-100 border-0" />
      {contentComponent}
    </Modal>
  );
};

export default LoginModal;
