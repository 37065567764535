import { Button, EButtonColorVariant, ELinkButtonVariant, LinkButton } from '@outdoorsyco/bonfire';
import React, { MutableRefObject, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import Link from '@/components/switchback/Link/Link';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';
import TextInput from '@/components/switchback/TextInput';
import { FORGOT_PAGE } from '@/constants/urls';
import { emailValidator, passwordValidator } from '@/constants/validationRules';
import { ILoginForm } from '@/services/types/auth/ILoginForm';

import { TFieldErrors } from './types/TFieldErrors';

export interface IProps {
  isModal?: boolean;
  onLogin: (credentials: ILoginForm) => void;
  isLoading?: boolean;
  error?: { error?: string | undefined } | string | null;
  onForgotPassword?: () => void;
  email?: string;
  reCaptchaToken: string;
}

const LoginForm: React.FC<IProps> = ({ onLogin, reCaptchaToken, ...props }) => {
  const recaptchaRef: MutableRefObject<ReCAPTCHA | null> = useRef(null);
  const shouldUseRecaptcha = !!reCaptchaToken;

  //#region INTL
  const intl = useIntl();
  const email = intl.formatMessage({ defaultMessage: 'Email address' });
  const password = intl.formatMessage({ defaultMessage: 'Password' });
  const btnLabelLogin = intl.formatMessage({ defaultMessage: 'Log in' });
  const linkLabelForgot = intl.formatMessage({ defaultMessage: 'Forgot your password?' });
  const labelLoginFormHeader = intl.formatMessage({
    defaultMessage: 'Log in with your email',
    description: 'Login Modal Title',
  });
  //#endregion

  //#region Form Validation & Submit
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  const onSubmit = useCallback(
    async (formValues: ILoginForm) => {
      const token = (shouldUseRecaptcha && (await recaptchaRef.current?.executeAsync())) || '';
      const data = { ...formValues, recaptcha_response: token };
      await onLogin(data);
      recaptchaRef.current?.reset();
    },
    [onLogin, shouldUseRecaptcha],
  );

  const fieldErrors: TFieldErrors<keyof ILoginForm> = {
    email: {
      required: intl.formatMessage({
        defaultMessage: 'Email is required',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Please enter a valid email address.',
      }),
    },
    password: {
      required: intl.formatMessage({
        defaultMessage: 'Password is required.',
      }),
    },
  };

  const emailErr = errors.email && fieldErrors.email[errors.email.type];
  const passErr = errors.password && fieldErrors.password[errors.password.type];

  return (
    <>
      {!props.isLoading && props.error && (
        <div className="flex items-center mb-2 text-red-800">
          <Notice icon={ALERT_OCTAGON} variant={NoticeType.critical}>
            {typeof props.error === 'string' ? props.error : props.error.error}
          </Notice>
        </div>
      )}
      {!props.isModal && <div className="mb-2">{labelLoginFormHeader}</div>}
      <form className="flex flex-col w-full pt-2 pb-5 bg-white" onSubmit={handleSubmit(onSubmit)}>
        <div className={emailErr ? 'mb-8' : 'mb-4'}>
          <TextInput
            defaultValue={props.email}
            autoComplete="email"
            label={email}
            error={emailErr}
            {...register('email', emailValidator)}
          />
        </div>

        <div className={passErr ? 'mb-8' : 'mb-4'}>
          <TextInput
            autoComplete="current-password"
            type="password"
            label={password}
            error={passErr}
            {...register('password', passwordValidator)}
          />
        </div>

        <div className="mt-6 lg:flex lg:items-center lg:justify-between space-y-4 lg:space-y-0 lg:space-x-4">
          <Button
            className="w-full lg:w-2/4"
            variant={EButtonColorVariant.Primary}
            label={btnLabelLogin}
            loading={props.isLoading}
            disabled={props.isLoading}
            type="submit"
          />
          {props.isModal ? (
            <LinkButton
              className="justify-center w-full lg:w-2/4 leading-6"
              variant={ELinkButtonVariant.Underline}
              label={linkLabelForgot}
              onClick={() => {
                if (props.onForgotPassword) {
                  props.onForgotPassword();
                }
              }}
            />
          ) : (
            <Link href={FORGOT_PAGE} className="block py-5 autoType400 lg:py-0">
              {linkLabelForgot}
            </Link>
          )}
        </div>
        {shouldUseRecaptcha && (
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={reCaptchaToken} />
        )}
      </form>
    </>
  );
};

export { LoginForm };
