import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import TextInput from '@/components/switchback/TextInput';
import { emailValidator } from '@/constants/validationRules';
import { TAuthError } from '@/redux/modules/auth';

import { TFieldErrors } from '../types/TFieldErrors';
import styles from './style.module.css';

export interface ForgotPasswordFormFields {
  email: string;
}

export interface ForgotPasswordFormProps {
  error?: TAuthError | null;
  hideHeader?: boolean;
  loading?: boolean;
  loginRedirect?: string;
  onLogin?: () => void;
  onSubmit?: (formData: ForgotPasswordFormFields) => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = props => {
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormFields>();
  const onSubmit = async (formValues: ForgotPasswordFormFields) => {
    setSubmitted(true);

    if (props.onSubmit) {
      props.onSubmit(formValues);
    }
  };

  const headTitle = intl.formatMessage({
    defaultMessage: 'Reset your password',
  });
  const email = intl.formatMessage({ defaultMessage: 'Email address' });
  const fieldErrors: TFieldErrors<keyof ForgotPasswordFormFields> = {
    email: {
      required: intl.formatMessage({
        defaultMessage: 'Email is required',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Please enter a valid email address.',
      }),
    },
  };
  const resetBtnLabel = intl.formatMessage({ defaultMessage: 'Reset Password' });
  const remeberText = intl.formatMessage({ defaultMessage: 'Remember your password?' });
  const loginBtnLabel = intl.formatMessage({ defaultMessage: 'Login' });
  const submittedText = intl.formatMessage({
    defaultMessage: 'We’ve emailed you instructions on how to reset your password.',
  });
  const emailErr = errors.email && fieldErrors.email[errors.email.type];

  const handleLoginClick = () => {
    if (props.onLogin) {
      props.onLogin();
    }
  };

  return (
    <section className={styles.ForgotPasswordForm}>
      {!props.hideHeader && (
        <header>
          <h3 className="mb-12 h3">{headTitle}</h3>
        </header>
      )}
      {submitted && !props.loading && !props.error ? (
        <p className={styles.Form}>{submittedText}</p>
      ) : (
        <form
          className={`${styles.Form} ${props.hideHeader ? '' : 'mb-6'}`}
          onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            autoComplete="email"
            label={email}
            error={emailErr}
            {...register('email', emailValidator)}
          />
          <Button
            className={styles.ResetBtn}
            type="submit"
            variant={EButtonColorVariant.Primary}
            label={resetBtnLabel}
            loading={props.loading}
          />
        </form>
      )}
      <footer className={`${styles.Footer}`}>
        <p>{remeberText}</p>
        <Button
          href={props.loginRedirect}
          className={styles.LoginBtn}
          variant={EButtonColorVariant.Secondary}
          label={loginBtnLabel}
          onClick={handleLoginClick}
        />
      </footer>
    </section>
  );
};

export default ForgotPasswordForm;
