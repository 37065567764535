import { defineMessage } from 'react-intl';

import {
  MUSIC_FESTIVAL,
  MUSIC_FESTIVAL_NOT_ALLOWED,
  PET_FRIENDLY,
  PET_NOT_ALLOWED,
  SMOKING,
  SMOKING_NOT_ALLOWED,
  TAILGATING,
  TAILGATING_NOT_ALLOWED,
} from '@/components/switchback/Icon/assets';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import { getIntl } from '@/utility/i18n';
const intl = getIntl();

export enum ECancellationPolicies {
  CUSTOM = 'custom',
  FLEXIBLE = 'flexible',
  EASYGOING = 'easy going',
  FULLYFLEXIBLE = 'fully flexible',
  MODERATE = 'moderate',
  STRICT = 'strict',
}

export const POLICY_LABEL: Record<ECancellationPolicies, string> = {
  [ECancellationPolicies.CUSTOM]: intl.formatMessage({ defaultMessage: 'Custom' }),
  [ECancellationPolicies.FLEXIBLE]: intl.formatMessage({ defaultMessage: 'Flexible' }),
  [ECancellationPolicies.EASYGOING]: intl.formatMessage({ defaultMessage: 'Easy going' }),
  [ECancellationPolicies.FULLYFLEXIBLE]: intl.formatMessage({ defaultMessage: 'Fully flexible' }),
  [ECancellationPolicies.MODERATE]: intl.formatMessage({ defaultMessage: 'Moderate' }),
  [ECancellationPolicies.STRICT]: intl.formatMessage({ defaultMessage: 'Strict' }),
};

export enum EHouseRules {
  'PET_FRIENDLY' = 'pet_friendly',
  'FESTIVAL_FRIENDLY' = 'festival_friendly',
  'TAILGATE_FRIENDLY' = 'tailgate_friendly',
  'SMOKING_ALLOWED' = 'smoking_allowed',
}

export enum EStayHouseRules {
  'SMOKING_ALLOWED' = 'smoking_allowed',
  'PET_FRIENDLY' = 'pet_friendly',
}

export enum EHouseRates {
  MINIMUM_STAY = 'minimum_days',
  SECURITY_DEPOSIT = 'security_deposit',
}

export enum EHouseRateCards {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export interface ITranslation {
  id: string;
  defaultMessage: string;
}

export interface IRule {
  feature: string;
  allowedIcon: IconType;
  allowedTitle: ITranslation;
  notAllowedIcon: IconType;
  notAllowedTitle: ITranslation;
}

export const HOUSE_RULES: IRule[] = [
  {
    feature: EHouseRules.PET_FRIENDLY,
    allowedIcon: PET_FRIENDLY,
    allowedTitle: defineMessage({
      id: `pet_friendly_allowed`,
      defaultMessage: 'Pet friendly',
    }),
    notAllowedIcon: PET_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'pet_friendly_not_allowed',
      defaultMessage: 'Not pet friendly',
    }),
  },
  {
    feature: EHouseRules.FESTIVAL_FRIENDLY,
    allowedIcon: MUSIC_FESTIVAL,
    allowedTitle: defineMessage({
      id: 'music_festival_allowed',
      defaultMessage: 'Music festival friendly',
    }),
    notAllowedIcon: MUSIC_FESTIVAL_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'music_festival_not_allowed',
      defaultMessage: 'No music festivals',
    }),
  },
  {
    feature: EHouseRules.TAILGATE_FRIENDLY,
    allowedIcon: TAILGATING,
    allowedTitle: defineMessage({
      id: 'tailgate_allowed',
      defaultMessage: 'Tailgating friendly',
    }),
    notAllowedIcon: TAILGATING_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'tailgate_not_allowed',
      defaultMessage: 'No tailgating',
    }),
  },
  {
    feature: EHouseRules.SMOKING_ALLOWED,
    allowedIcon: SMOKING,
    allowedTitle: defineMessage({
      id: 'smoking_allowed',
      defaultMessage: 'Smoking allowed',
    }),
    notAllowedIcon: SMOKING_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'smoking_not_allowed',
      defaultMessage: 'No smoking',
    }),
  },
];

export const STAY_HOUSE_RULES: IRule[] = [
  {
    feature: EStayHouseRules.PET_FRIENDLY,
    allowedIcon: PET_FRIENDLY,
    allowedTitle: defineMessage({
      id: `pet_friendly_allowed`,
      defaultMessage: 'Pet friendly',
    }),
    notAllowedIcon: PET_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'pet_friendly_not_allowed',
      defaultMessage: 'Not pet friendly',
    }),
  },
  {
    feature: EStayHouseRules.SMOKING_ALLOWED,
    allowedIcon: SMOKING,
    allowedTitle: defineMessage({
      id: 'smoking_allowed',
      defaultMessage: 'Smoking allowed',
    }),
    notAllowedIcon: SMOKING_NOT_ALLOWED,
    notAllowedTitle: defineMessage({
      id: 'smoking_not_allowed',
      defaultMessage: 'No smoking',
    }),
  },
];
