import BaseModal, { ModalProps } from '@material-ui/core/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import { CLOSE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { generateSimpleId } from '@/utility/helpers';

import Backdrop from './Backdrop';
import css from './Modal.module.css';
import ModalAnimation, { IStateStyles } from './ModalAnimation';

export enum ModalSize {
  medium = 'medium',
  large = 'large',
}

export enum ModalHeight {
  full = 'full',
  default = 'default',
}

type TBaseModalProps = Pick<
  ModalProps,
  | 'disableAutoFocus'
  | 'disableEnforceFocus'
  | 'disableEscapeKeyDown'
  | 'disablePortal'
  | 'disableRestoreFocus'
  | 'disableScrollLock'
  | 'hideBackdrop'
  | 'keepMounted'
  | 'onBackdropClick'
  | 'onEscapeKeyDown'
>;

export type TModalCloseReason = 'manual' | 'backdropClick' | 'escapeKeyDown';

interface IModalProps extends TBaseModalProps {
  /**
   * Controls if the modal is open.
   */
  open: boolean;
  /**
   * Modal title, it's fixed on the top of the modal so it's always visible.
   */
  title?: string | React.ReactNode;
  /**
   * Modal title class, add more class names here to extend the modal title style.
   */
  titleClass?: string;
  /**
   * Modal title container class, add more class names here to extend the modal title style.
   */
  titleContainerClass?: string;
  /**
   * Modal sub-title, just below the tile with a different text treatment.
   */
  subTitle?: string;
  /**
   * Modal sub-title class, add more class names here to extend the modal sub-title style.
   */
  subTitleClass?: string;
  /**
   * If you have any element inside the children that can be used to describe
   * the modal it's recommended that you pass the id to link it to the modal.
   */
  'aria-describedby'?: string;
  /**
   * size of the modal.
   */
  size?: ModalSize;
  /**
   * height of the modal.
   */
  height?: ModalHeight;
  /**
   * Content of the modal.
   */
  children: React.ReactNode;
  /**
   * Modal footer, it's fixed on the bottom of the modal so it's always visible.
   */
  footer?: React.ReactNode;
  /**
   * Optionally replace the modal footer
   */
  customFooter?: React.ReactNode;
  /**
   * Optionally replace the modal title with a custom element.
   */
  customHeader?: React.ReactNode;
  /**
   * Handler for closing the modal when clicking on the backdrop or close button.
   */
  className?: string;
  /**
   * applies for base modal
   */
  onClose?: (reason: TModalCloseReason) => void;
  /**
   * Allow passing onClose function but hide manual X button for closing the modal
   */
  disabledManualClose?: boolean;
  allowOverlay?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  open,
  title,
  titleClass = '',
  titleContainerClass = '',
  subTitle,
  subTitleClass = '',
  'aria-describedby': ariaDescribedBy,
  children,
  footer,
  customFooter,
  customHeader,
  onClose,
  size = ModalSize.large,
  height = ModalHeight.default,
  disabledManualClose,
  allowOverlay = false,
  ...rest
}) => {
  // @ts-expect-error preact bug
  if (rest && rest['class']) delete rest['class'];

  const intl = useIntl();
  const [hasScrolled, setHasScrolled] = useState(false);
  const titleId = useMemo(() => `modal_title_${generateSimpleId()}`, []);
  const { isAboveTablet } = useBreakpoint();

  useEffect(() => {
    if (!open) {
      setHasScrolled(false);
    }
  }, [open]);

  const defaultStyle = {
    transform: isAboveTablet ? 'translate3d(-50%, -45%, 0)' : 'translate3d(0%, 50%, 0)',
    opacity: 0,
  };

  const stateStyles: IStateStyles = {
    entered: {
      opacity: 1,
      transform: isAboveTablet ? 'translate3d(-50%, -50%, 0)' : 'translate3d(0, 0, 0)',
    },
  };

  const handleClose = (_event: Event, reason: TModalCloseReason) => {
    onClose?.(reason);
  };

  const handleManuaClose = () => {
    onClose?.('manual');
  };

  return (
    <BaseModal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleClose}
      {...rest}>
      <ModalAnimation
        in={open}
        duration={isAboveTablet ? 300 : 350}
        defaultStyle={defaultStyle}
        styles={stateStyles}>
        <div
          role="dialog"
          aria-modal
          aria-labelledby={titleId}
          aria-describedby={ariaDescribedBy}
          data-size={size}
          data-testid="modal"
          className={`focus:outline-none ${
            !allowOverlay ? 'md:overflow-hidden' : ''
          } absolute flex flex-col bottom-0 md:top-1/2 md:bottom-auto md:left-1/2 py-8 bg-white rounded-t-lg md:rounded-b-lg shadow-200 transform md:-translate-x-1/2 md:-translate-y-1/2 ${
            css.container
          } ${size} ${height === 'full' ? 'h-full md:h-auto' : ''}`}
          data-scrolled={String(hasScrolled)}>
          <div
            className={`mx-4 md:mx-8 transition-colors duration-100 ease-linear ${
              css.titleContainer
            } ${
              titleContainerClass
                ? titleContainerClass
                : 'pb-2 border-b border-solid border-transparent'
            }`}>
            <div className="relative">
              {customHeader ? (
                customHeader
              ) : (
                <Heading
                  level={2}
                  id={titleId}
                  className={`pr-6 text-gray-900 font-highlight autoType800 ${titleClass}`}>
                  {title}
                </Heading>
              )}
              {subTitle && (
                <Text
                  type="block"
                  className={`pr-2 mt-2 text-gray-500 autoType300 ${subTitleClass}`}>
                  {subTitle}
                </Text>
              )}

              {onClose && !disabledManualClose && (
                <button
                  type="button"
                  data-testid="close-button"
                  aria-label={intl.formatMessage({ defaultMessage: 'Close dialog' })}
                  className={`absolute top-0 right-0 z-10 text-gray-900 transition-opacity duration-150 ease-linear hover:opacity-75 focus:opacity-100 ${css.closeButton}`}
                  onClick={handleManuaClose}>
                  <Icon aria-hidden name={CLOSE} size={IconSize.normal} />
                </button>
              )}
            </div>
          </div>
          <div
            className={`px-4 ${!allowOverlay ? 'overflow-auto' : ''} md:px-8 ${
              height === 'full' ? 'xs:h-full md:h-auto' : ''
            }`}
            onScroll={e => setHasScrolled(e.currentTarget.scrollTop > 0)}>
            {children}
          </div>
          {customFooter
            ? customFooter
            : footer && <div className="mx-4 mt-6 md:mx-8">{footer}</div>}
        </div>
      </ModalAnimation>
    </BaseModal>
  );
};

export default Modal;
