import { Button, EButtonColorVariant, EModalVariants, Modal } from '@outdoorsyco/bonfire';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { IVehicle } from '@/services/types/search/vehicle';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';
import { formatDateFullRange } from '@/utility/format-date';

import { MobileSearchAddressModalContent } from '../../NavbarSearch/MobileNavbarSearch/MobileSearchAddressModalContent';
import { MobileSearchDatesModalContent } from '../../NavbarSearch/MobileNavbarSearch/MobileSearchDatesModalContent';
import { MobileSearchGuestsModalContent } from '../../NavbarSearch/MobileNavbarSearch/MobileSearchGuestsModalContent';
import { EFilterContent } from '../../NavbarSearch/MobileNavbarSearch/MobileSearchModal';
import { INavbarSearch } from '../../SearchHeader';
import { EHeaderTab, IHeaderMenu } from '../UniversalSearchContainer/menu';
import FilterTriggerButton from './FilterTriggerButton';
import css from './MobileUniversalSearch.module.css';
import MobileUniversalSearchTowingVehicles from './MobileUniversalSearchTowingVehicles';
import MobileUniversalSearchVehiclesCampgrounds from './MobileUniversalSearchVehiclesCampgrounds';

interface IMobileUniversalSearchProps extends INavbarSearch {
  openedTab?: IHeaderMenu;
  onChangeVehicle?: (vehicle?: IVehicle) => void;
  onChangeVehicleCampgrounds?: (vehicle?: IVehicleCampgrounds) => void;
  onChangeLodgingCampgrounds?: (lodging?: ILodgingCampgrounds) => void;
  vehicle?: IVehicle;
  vehicleCampgrounds?: IVehicleCampgrounds;
  lodgingCampgrounds?: ILodgingCampgrounds;
  popularDestinations?: { label: string }[];
  isStayFilterEnabled?: boolean;
  isGuestOccupancyAvailable?: boolean;
}

const MobileUniversalSearch: React.FC<IMobileUniversalSearchProps> = ({
  handleRecentSearchNavigation,
  location,
  address,
  dates,
  guests,
  recentSearches,
  maxRecentSearches,
  onSubmit,
  onChangeAddress,
  onChangeDates,
  onChangeGuests,
  loading,
  openedTab,
  onChangeVehicle,
  onChangeVehicleCampgrounds,
  vehicle,
  vehicleCampgrounds,
  popularDestinations = [],
  isStayFilterEnabled,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [filterContent, setFilterContent] = useState<EFilterContent>();

  const totalGuests =
    (guests?.adults || 0) +
    (guests?.children || 0) +
    (isGuestOccupancyAvailable ? guests?.infants || 0 : 0);

  const handleSubmit = () => {
    onSubmit?.();
  };

  const handleExpandFilter = (newContent: EFilterContent) => {
    setIsModalOpened(true);
    setFilterContent(newContent);
  };

  const hasNoDates = useMemo<boolean>(() => !dates?.from && !dates?.to, [dates]);

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }

    const datesLabel = formatDateFullRange(dates.from.toString(), dates.to.toString()) || '';

    if (dates.flexible_days) {
      return `${datesLabel} (±${dates.flexible_days})`;
    }

    return datesLabel;
  }, [dates]);

  const addDatesMessage = isStayFilterEnabled
    ? intl.formatMessage({
        defaultMessage: 'When?',
        description: 'When will you go?',
      })
    : intl.formatMessage({ defaultMessage: 'When will you go?' });

  const locationPlaceholder = intl.formatMessage({
    defaultMessage: 'Where to?',
    description: 'Global Header > Placeholder text for header search field',
  });

  return (
    <div className={`w-full bg-white rounded-[12px]`}>
      <form>
        <div className={isStayFilterEnabled ? css.stayLocation : css.location}>
          <FilterTriggerButton
            filterKey={EFilterContent.ADDRESS}
            label={intl.formatMessage({
              defaultMessage: 'Location',
            })}
            ariaExpanded={isModalOpened && filterContent === EFilterContent.ADDRESS}
            ariaLabel={intl.formatMessage(
              {
                defaultMessage:
                  'Click to {hasValue, select, true {change the} false {add a}} location',
                description: 'Global Header > Search',
              },
              {
                hasValue: !!address,
              },
            )}
            content={address || locationPlaceholder}
            onClick={() => handleExpandFilter(EFilterContent.ADDRESS)}
            btnClassName="-mx-0.5 "
          />
        </div>
        {/* TODO: these two filters should be migrated to the modal variant */}
        {openedTab?.tab === EHeaderTab.TOWABLE && (
          <div className={isStayFilterEnabled ? css.stayTowable : css.towable}>
            <MobileUniversalSearchTowingVehicles
              onChangeVehicle={onChangeVehicle}
              vehicle={vehicle}
            />
          </div>
        )}
        {openedTab?.tab === EHeaderTab.RV_SITE && (
          <div className={isStayFilterEnabled ? css.stayTowable : css.towable}>
            <MobileUniversalSearchVehiclesCampgrounds
              onChangeVehicle={onChangeVehicleCampgrounds}
              vehicle={vehicleCampgrounds}
            />
          </div>
        )}
        {/* TODO: readd lodging campground if we end up supporting lodging type */}
        <div className={isStayFilterEnabled ? css.stayDatesWrapper : css.datesWrapper}>
          <div className={isStayFilterEnabled ? css.stayDates : css.dates}>
            <FilterTriggerButton
              filterKey={EFilterContent.DATES}
              label={intl.formatMessage({
                defaultMessage: 'Dates',
              })}
              ariaExpanded={isModalOpened && filterContent === EFilterContent.DATES}
              ariaLabel={intl.formatMessage(
                {
                  defaultMessage:
                    'Click to {hasValue, select, true {change the} false {add}} dates',
                  description: 'Global Header > Search',
                },
                {
                  hasValue: !hasNoDates,
                },
              )}
              content={hasNoDates || !formattedDates ? addDatesMessage : formattedDates}
              onClick={() => handleExpandFilter(EFilterContent.DATES)}
            />
          </div>
          <div className={isStayFilterEnabled ? css.stayGuests : css.guests}>
            <FilterTriggerButton
              filterKey={EFilterContent.GUESTS}
              label={intl.formatMessage({
                defaultMessage: 'Guests',
              })}
              ariaExpanded={isModalOpened && filterContent === EFilterContent.GUESTS}
              ariaLabel={intl.formatMessage(
                {
                  defaultMessage:
                    'Click to {hasValue, select, true {change the} false {add}} guests',
                  description: 'Global Header > Search',
                },
                {
                  hasValue: !!totalGuests,
                },
              )}
              content={
                !totalGuests
                  ? intl.formatMessage({
                      defaultMessage: 'Who‘s going?',
                    })
                  : intl.formatMessage(
                      {
                        defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
                        description: 'Placeholder for header guests field',
                      },
                      { totalGuests },
                    )
              }
              onClick={() => handleExpandFilter(EFilterContent.GUESTS)}
            />
          </div>
        </div>
        <div className={isStayFilterEnabled ? css.staySearch : css.search}>
          <Button
            type="button"
            variant={EButtonColorVariant.Primary}
            label={intl.formatMessage({
              defaultMessage: 'Search',
              description: 'Search button label',
            })}
            loading={loading}
            fullWidth
            onClick={handleSubmit}
          />
        </div>
      </form>

      <Modal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        variant={EModalVariants.Bottom}
        className="z-[1410]"
        dialogClassName="h-screen">
        {filterContent === EFilterContent.ADDRESS && (
          <MobileSearchAddressModalContent
            onCloseHandler={() => setIsModalOpened(false)}
            location={location}
            address={address}
            onChangeAddress={onChangeAddress}
            popularDestinations={popularDestinations}
            recentSearches={recentSearches}
            handleRecentSearchNavigation={handleRecentSearchNavigation}
            maxRecentSearches={maxRecentSearches}
          />
        )}
        {filterContent === EFilterContent.DATES && (
          <MobileSearchDatesModalContent
            onCloseHandler={() => setIsModalOpened(false)}
            dates={dates}
            onChangeDates={onChangeDates}
          />
        )}
        {filterContent === EFilterContent.GUESTS && (
          <MobileSearchGuestsModalContent
            onCloseHandler={() => setIsModalOpened(false)}
            guests={guests}
            onChangeGuests={onChangeGuests}
            isGuestOccupancyAvailable={isGuestOccupancyAvailable}
          />
        )}
      </Modal>
    </div>
  );
};

export default MobileUniversalSearch;
