import React from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';

interface IFooterListItem {
  dataTestId?: string;
  href?: string;
}

const FooterListItem: React.FC<IFooterListItem> = ({ href, children, dataTestId }) => {
  if (!href) return null;
  return (
    <li className="block mt-4 text-white truncate border-black">
      <Anchor
        data-testid={dataTestId}
        href={href}
        className={'text-base text-white leading-6 hover:underline underline-offset-2'}>
        {children}
      </Anchor>
    </li>
  );
};

export default FooterListItem;
