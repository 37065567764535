import { EHeaderTab } from '@/components/ui/SearchHeader/UniversalSearch/UniversalSearchContainer/menu';
import { EFilterMethod } from '@/redux/modules/searchForm';
import { LocationGroupType } from '@/utility/surfacedLocation';

import { ESearchSort } from '../listings/types';
import { EMapStatus } from '../maps/types';
import { ERentalType } from '../types';

export enum ESearchEventName {
  SEARCH_RESULT_VIEWED = 'Search Results Viewed',
  RENTER_SEARCHED = 'Renter Searched',
  RENTER_SEARCH_RESULTS_EXPANDED = 'Search Results Expanded',
  SEARCH_FILTER_UPDATED = 'Search Filter Updated',
  SEARCH_CATEGORY_SELECTED = 'Search Category Selected',
  INVENTORY_TAG_SELECTED = 'Inventory Tag Selected',
  GUIDED_SEARCH_SELECTED = 'Guided Search Selected',
}

export enum ESearchSource {
  SEARCH_BAR = 'search_bar',
  MAP_ZOOM_IN = 'map_zoom_in',
  MAP_ZOOM_OUT = 'map_zoom_out',
  MAP_PAN = 'map_pan',
  NEXT_PAGE = 'next_page',
  PREVIOUS_PAGE = 'previous_page',
  FILTER_SHOW_VEHICLES = 'filters_show_vehicles',
  PAGE_CLICK_AHEAD = 'page_click_ahead',
  PAGE_CLICK_BEHIND = 'page_click_behind',
  PAGE_CLICK_SAME = 'page_click_same',
  FILTER_BUNDLE = 'filter_bundle',
  RVS_NEAR_ME = 'rvs_near_me',
}

export type TFlexibleDateRange = '1' | '3' | null | 'exact';

export interface ISearchResultInfo {
  rentalType: ERentalType;
  searchFilterCategory: ERentalType;
  filters: Record<string, unknown> | null;
  locationQuery: string | null;
  locationLongitude: number | null;
  locationLatitude: number | null;
  departureDate: number | null; // unix date
  returnDate: number | null; // unix date
  totalAdults: number | null;
  totalChildren: number | null;
  rentalIDs: number[];
  searchQuery: string;
  source: ESearchSource | null;
  listingsOnPage: number;
  listingsPerPage: number;
  totalUnavailableListings: number;
  totalPagesAvailable: number;
  totalListingsFound: number;
  currentPageViewed: number;
  listingsSort: ESearchSort;
  geoLocation: string | null;
  searcherGeoLocation: string | null;
  flexibleDateRange: TFlexibleDateRange;
  isSEO: boolean;
  experiments: Record<string, unknown> | null;
  experimentsData: Record<string, Record<string, unknown>> | null;
  isBlended: boolean;
  mapStatus: EMapStatus | null;
}

export interface ISearchCategorySelected {
  rentalCategory: ERentalType;
  source: 'home_search' | 'search_nav';
}

export interface IRenterSearchedInfo {
  rentalType: ERentalType;
  searchFilterCategory: ERentalType;
  isADAAccessible: boolean;
  isPetFriendly: boolean;
  locationQuery: string | null;
  departureDate: number | null; // unix date
  returnDate: number | null; // unix date
  totalAdults: number | null;
  totalChildren: number | null;
  totalInfants: number | null;
  flexibleDateRange: TFlexibleDateRange;
  source:
    | 'home_search'
    | 'search_nav'
    | 'serp_search'
    | 'serp_canned_filter'
    | 'guided_search_1'
    | 'guided_search_2'
    | null;
  surfacedLocations: LocationGroupType;
  hasCategories: boolean;
  selectedCategory: EHeaderTab;
  towableYear: string | null;
  towableMake: string | null;
  towableModel: string | null;
  lastQuestionSeen: string | null;
}

export interface IRenterSearchResultsExpandedInfo {
  rentalType: ERentalType;
  filters: Record<string, unknown> | null;
  locationQuery: string | null;
  departureDate: number | null; // unix date
  returnDate: number | null; // unix date
  rentalIDs: number[];
  listingsPerPage: number;
  listingsOnPage: number;
  listingsExpandedOnPage: number;
  totalListingsFound: number;
  totalUnavailableListings: number;
}

export interface IRenterSearchFilterUpdatedInfo {
  rentalType: ERentalType;
  newFilters: Record<string, unknown> | null;
  previousFilters: Record<string, unknown> | null;
  filterType: string[];
  filterMethod: EFilterMethod;
  isSEO: boolean;
}

export interface IRenterSearchFilterTagsSelectedInfo {
  source: string;
  tagName: string;
}

export interface IGuidedSearchSelectedInfo {
  source: string;
}
