import cookie from 'cookie';
import Cookies from 'js-cookie';
import { parse } from 'query-string';

import { removeCookie } from './cookie';
import { parseCurrencyCookie } from './currency';
import { isSSR } from './isSSR';
import { logger } from './logger';

export interface IOutdoorsySessionCookie {
  actorId?: number;
  authenticated?: {
    token?: string;
    authenticator?: string;
    user_id?: string;
  };
}

export const COOKIE_SESSION_NAME = 'outdoorsy-session';
const COOKIE_SESSION_EXPIRATION = 18262; // 50 years floored (in days, 365.25 * 50)
export const EVENT_AUTHENTICATE_NAME = 'authenticate';
export const EVENT_SESSION_NAME = 'session';
export const EVENT_NAME_SIGN_UP = 'signup';
export const EVENT_NAME_LOGIN = 'login';
export const EVENT_NAME_CLOSE_LOGIN = 'close-login';
export const EVENT_NAME_LOAD_USER = 'load-user';
export const EVENT_NAME_CLOSE_SIGN_UP = 'close-signup';
export const EVENT_NAME_LOGOUT = 'logout';
const TWO_FA_SESSION_NAME = '2fa-session';

export const getCurrencyCookie = () => {
  if (typeof window === 'undefined') return;
  const cookie = Cookies.get('userLocaleCurrency');
  return parseCurrencyCookie(cookie);
};

export const openSessionModal = (name: typeof EVENT_NAME_SIGN_UP | typeof EVENT_NAME_LOGIN) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const event = new CustomEvent(EVENT_AUTHENTICATE_NAME, {
      cancelable: true,
      bubbles: true,
      detail: {
        name,
      },
    });

    document.dispatchEvent(event);
  } catch (exception) {
    logger.error(exception);
  }
};

type TSession = { [key: string]: any };

type JWT = {
  account_id: string;
  user_id: number;
  actor_id: number;
  client_name: string;
  roles: string[];
  exp: number;
  jti: string;
  iat: number;
};

export type T2FASession = {
  session_id_2fa?: string;
  show_2fa_screen?: boolean;
  preferred_2fa_method?: string;
  email?: string;
  phone?: string;
  next?: string;
  initialCodeSent?: boolean;
};

export const decodeJWT = (token: string) => {
  let result = null;

  if (token) {
    try {
      if (isSSR()) {
        const decode = (base64: string) => Buffer.from(base64, 'base64').toString('binary');
        result = JSON.parse(decode(token.split('.')[1] || ''));
      } else {
        result = JSON.parse(atob(token.split('.')[1] || ''));
      }
    } catch (exception) {
      logger.error(exception);
    }
  }
  return result;
};

export const isJWTValid = (jwt: JWT): boolean => {
  let result = false;

  if (jwt) {
    // auth token exp in seconds
    result = Date.now() < jwt.exp * 1000;
  }

  return result;
};

/**
 * other request rely on the auth token being set in the cookie, probably middleware
 * @returns {boolean} rather token from URL is used or not
 */
type TUrlTokenData = {
  oldSessionCookie: TSession | null;
  decodedUrlToken: any;
};
export const setSessionFromURLToken = (): TUrlTokenData | undefined => {
  if (typeof window === 'undefined') return;

  const show2FAScreen = parse(window.location.search)['show_2fa_screen'] as string;
  if (show2FAScreen) {
    // do not set session cookie on social logins that require 2fa
    return;
  }

  const token = parse(window.location.search)['token'] as string;
  const oldSessionCookie = getSessionCookie();
  const decodedJWT = decodeJWT(token);
  if (!isJWTValid(decodedJWT)) return;
  setSessionCookie(decodedJWT.actor_id || null, {
    user_id: decodedJWT.user_id,
    token,
  });
  return {
    oldSessionCookie,
    decodedUrlToken: decodedJWT,
  };
};

export const isNewImpersonatedLogin = (urlTokenData: TUrlTokenData): boolean => {
  if (isSSR()) return false;
  const { decodedUrlToken, oldSessionCookie } = urlTokenData;
  if (!decodedUrlToken?.actor_id) return false;
  if (!oldSessionCookie?.actorId) return !!decodedUrlToken.actor_id && !!decodedUrlToken.user_id;
  const oldUserId = oldSessionCookie.authenticated?.user_id;
  if (oldSessionCookie.actorId === decodedUrlToken.actor_id)
    return !!decodedUrlToken.user_id && oldUserId !== decodedUrlToken.user_id;
  return true;
};

export const getSessionCookie = (cookies?: string): TSession | null => {
  let result: TSession | null = null;

  // SSR
  if (typeof window === 'undefined') {
    if (cookies) {
      try {
        const parsedCookies = cookie.parse(cookies);
        const sessionCookie = parsedCookies[COOKIE_SESSION_NAME];

        if (sessionCookie) {
          result = JSON.parse(sessionCookie);
        }
      } catch (err) {
        logger.error(err);
      }
    }

    return result;
  }

  // CSR
  try {
    const sessionCookie = Cookies.get(COOKIE_SESSION_NAME);
    result = sessionCookie ? JSON.parse(decodeURIComponent(sessionCookie)) : null;
  } catch (err) {
    logger.error(err);
  }

  return result;
};

export const isSessionValid = (sessionCookie: IOutdoorsySessionCookie): boolean => {
  let result = false;

  if (sessionCookie?.authenticated?.token) {
    result = isJWTValid(decodeJWT(sessionCookie?.authenticated?.token));
  }

  return result;
};

export const setSessionCookie = (actorId: number | null, data: TSession) => {
  if (typeof window === 'undefined') {
    return;
  }

  const cookieData = {
    actorId,
    authenticated: {
      ...data,
      authenticator: 'authenticator:outdoorsy',
    },
  };

  Cookies.set(COOKIE_SESSION_NAME, cookieData, {
    path: '/',
    expires: COOKIE_SESSION_EXPIRATION,
  });
};

export const resetSessionCookie = () => {
  if (typeof window === 'undefined') {
    return;
  }

  removeCookie(COOKIE_SESSION_NAME);
};

export const getAuthToken = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const sessionCookie = getSessionCookie();
  return sessionCookie?.authenticated?.token || null;
};

export const getActorId = (): number | null => {
  const sessionCookie = getSessionCookie();
  return sessionCookie?.actorId || null;
};

export const isAuthenticated = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const sessionCookie = getSessionCookie();
  return !!sessionCookie?.authenticated?.token;
};

export const isPreviousPageOutdoorsy = () => {
  if (isSSR()) return false;
  return document.referrer.indexOf(window.location.host) !== -1;
};

export const getNextRedirectURL = (next: string | string[] | undefined): string | undefined => {
  if (!next || Array.isArray(next)) return;
  let redirectURL: string | undefined;

  try {
    redirectURL = atob(decodeURIComponent(next));
    redirectURL = redirectURL.replace(/(https?:\/\/[-\w.]+)\//, '/');
  } catch (e) {
    return;
  }

  return redirectURL;
};

export const registerAuthenticateEvent = (
  type: any,
  eventHandler: any,
  dontPreventDefault?: boolean,
) => {
  if (typeof window === 'undefined' || typeof eventHandler !== 'function') return;

  window.addEventListener(
    EVENT_AUTHENTICATE_NAME,
    (event: any) => {
      if (event?.detail?.name === type) {
        if ((typeof event.cancelable !== 'boolean' || event.cancelable) && !dontPreventDefault) {
          event.preventDefault();
        }
        eventHandler(event);
      }
    },
    true,
  );
};

export const get2FASessionCookie = (): T2FASession | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  let result: T2FASession | null = null;

  try {
    const sessionCookie = Cookies.get(TWO_FA_SESSION_NAME);
    result = sessionCookie ? JSON.parse(decodeURIComponent(sessionCookie)) : null;
  } catch (err) {
    logger.error(err);
  }

  return result;
};

export const set2FASessionCookie = (data: T2FASession) => {
  if (typeof window === 'undefined') {
    return;
  }

  const expiresInAnHour = 1 / 24;

  Cookies.set(TWO_FA_SESSION_NAME, data, {
    path: '/',
    expires: expiresInAnHour,
  });
};

export const reset2FASessionCookie = () => {
  if (typeof window === 'undefined') {
    return;
  }

  removeCookie(TWO_FA_SESSION_NAME);
};
