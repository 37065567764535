import { clsx } from 'clsx';
import React from 'react';

import LoginModalContainer from '@/components/auth/LoginModalContainer';
import SignupModalContainer from '@/components/auth/SignupModalContainer';
import SmartBannerContainer from '@/components/mobile/banner/SmartBannerContainer';
import { EPage, PAGE_PATHNAME } from '@/constants/pages';
import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useIsSearchPage } from '@/hooks/useIsSearchPage';
import useQueryParams from '@/hooks/useQueryParams';
import { useRouter } from '@/hooks/useRouter';
import { ERentalType } from '@/services/analytics/types';

import { Impersonated } from '../auth/Impersonated/Impersonated';
import GlobalHeader from '../ui/GlobalHeader/GlobalHeader';
import { FixedFooter } from './footer/FixedFooter';
import Footer from './footer/Footer';
import SystemDowntimeNotice from './SystemDowntimeNotice';

const HIDDEN_HEADER_AND_FOOTER_ROUTES = [
  '/rv-rental/[location]/[slug]/photos',
  '/rv-rental/[location]/[slug]/reviews',
  '/stay-rental/[location]/[slug]/photos',
  '/stay-rental/[location]/[slug]/reviews',
  '/campsite-rental/[location]/[slug]/photos',
  '/campsite-rental/[location]/[slug]/reviews',
  '/pro/[slug]/photos',
  '/pro/[slug]/reviews',
  '/pro/[slug]/reviews/[page]',
  '/trips/[slug]/cancel',
  '/campground-rental/[location]/[slug]/photos',
  '/campground-rental/[location]/[slug]/reviews',
  '/destinations/outdoorsy-hill-country',
  '/destinations/outdoorsy-hill-country/terms-and-conditions',
  '/guided-search',
];

const HIDDEN_HEADER_AND_FOOTER_START_WITH_ROUTES = ['/checkout'];

const MOBILE_HIDDEN_HEADER_AND_FOOTER_ROUTES = ['/trips/[slug]', '/rv-rental/[location]/[slug]'];

const HIDDEN_FIXED_FOOTER_START_WITH_ROUTES = [
  '/rv-search',
  '/owner',
  '/checkout',
  '/rv-rental',
  '/stay-rental',
  '/campsite-rental',
  '/trips/',
  '/security',
  '/campground-rental',
  '/guide/seasonal',
];

const FULL_PAGE_CANVAS_ROUTES = ['/invite/b/[code]', '/become-a-host', '/generations-in-the-wild'];

const HIDDEN_NAV_SEARCH = ['/locality', '/locality-directory-by-type'];

const FLUID_PAGE_ROUTES = [PAGE_PATHNAME[EPage.SEARCH], '/guide/seasonal/summer'];

const TRANSPARENT_HEADER_ROUTES = ['/'];
const TRANSPARENT_HEADER_START_WITH_ROUTES = ['/guide/seasonal/summer'];

const NON_STICKY_HEADER_START_WITH_ROUTES = ['/guide/seasonal/summer'];

const ApplicationLayout = ({ children, pageProps }) => {
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const { isSearchPage } = useIsSearchPage();
  const queryParams = useQueryParams();

  const isSignatureCampgroundBookASite =
    router.pathname.startsWith('/campground-rental') && queryParams.signature === 'true';

  const shouldHideHeaderAndFooter =
    isSignatureCampgroundBookASite ||
    HIDDEN_HEADER_AND_FOOTER_ROUTES.includes(pageProps.pathname || router.pathname) ||
    HIDDEN_HEADER_AND_FOOTER_START_WITH_ROUTES.some(route => router.pathname.startsWith(route)) ||
    (isMobile &&
      MOBILE_HIDDEN_HEADER_AND_FOOTER_ROUTES.includes(pageProps.pathname || router.pathname));

  const shouldHideFixedFooter = HIDDEN_FIXED_FOOTER_START_WITH_ROUTES.some(route =>
    router.pathname.startsWith(route),
  );

  const showFixedFooter = !shouldHideHeaderAndFooter && !shouldHideFixedFooter;

  const isFluidPage = FLUID_PAGE_ROUTES.includes(router.pathname);
  const isFullCanvasPage = FULL_PAGE_CANVAS_ROUTES.includes(pageProps.pathname || router.pathname);
  const headerIsTransparent =
    TRANSPARENT_HEADER_ROUTES.includes(pageProps.pathname || router.pathname) ||
    TRANSPARENT_HEADER_START_WITH_ROUTES.some(route => router.pathname.startsWith(route));

  const hideSearchBarWithExperiment = showFixedFooter || !router.pathname.startsWith('/rv-search');
  const hideSearchNavbar = HIDDEN_NAV_SEARCH.includes(pageProps.pathname || router.pathname);
  const noSearchNavbar = pageProps.isHomePage || hideSearchNavbar || hideSearchBarWithExperiment;

  const isRv =
    !router.query[ESearchFilters.RENTAL_CATEGORY] ||
    router.query[ESearchFilters.RENTAL_CATEGORY] === ERentalType.RV;

  const alwaysStickyHeaders = isRv && isMobile && isSearchPage;
  const nonStickyHeader = NON_STICKY_HEADER_START_WITH_ROUTES.some(route =>
    router.pathname.startsWith(route),
  );

  const footerExtraPaddingBottom =
    showFixedFooter || router.pathname === '/rv-search' ? `pb-16 lg:pb-0` : '';

  return (
    <>
      <SignupModalContainer />
      <LoginModalContainer />

      <div className="font-sans">
        <div
          id="global-headers"
          className={clsx(
            'relative',
            !nonStickyHeader &&
              !shouldHideHeaderAndFooter && {
                'sticky top-0 z-1299': alwaysStickyHeaders,
                'md:sticky md:top-0 md:z-1299': !alwaysStickyHeaders,
              },
          )}>
          <Impersonated />
          <SmartBannerContainer />
          <SystemDowntimeNotice />

          {!shouldHideHeaderAndFooter && (
            <GlobalHeader
              noSearchNavbar={noSearchNavbar}
              isFluidPage={isFluidPage}
              isFullCanvasPage={isFullCanvasPage}
              isTransparent={headerIsTransparent}
              isSticky={isMobile ? alwaysStickyHeaders : !nonStickyHeader}
            />
          )}
        </div>

        <div
          className={clsx('relative min-h-[60vh]', {
            'bg-canvas-100': isFullCanvasPage,
          })}>
          {children}
        </div>

        {!shouldHideHeaderAndFooter && <Footer extraPaddingBottom={footerExtraPaddingBottom} />}
        {showFixedFooter && <FixedFooter />}
      </div>
    </>
  );
};

export default ApplicationLayout;
