import { makeVar } from '@apollo/client';
import { createSelector } from 'reselect';

import { getItemsPerPage } from '@/constants/pagination';
import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import { getHeaderSearchFilters } from '@/redux/selectors/globalHeader';
import {
  getCurrentFilters,
  getRentals,
  getRentalsFilters,
  getRentalsMeta,
  TCurrentFilters,
  TRentalsData,
  TRentalsFilters,
  TRentalsMeta,
} from '@/redux/selectors/listing/rentalsFilters';
import { getSearchResultsMeta, IMetaData } from '@/redux/selectors/search/meta';
import { getPageViewEventData, IPageViewEvent } from '@/redux/selectors/search/page';
import { ESearchSort } from '@/services/analytics/listings/types';
import {
  ESearchSource,
  IRenterSearchResultsExpandedInfo,
  ISearchResultInfo,
} from '@/services/analytics/search/types';
import { ERentalType } from '@/services/analytics/types';
import { parseEventDate } from '@/services/analytics/utils';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

export const lastZoomDirection = makeVar<null | 1 | -1>(null);
export const lastPaginationDelta = makeVar<number | null>(null);
export const lastMapPan = makeVar<null | 1>(null);
export const lastSearchedInSearchBar = makeVar<null | 1>(1); // since by default this is where one would be searching from on the initial search
export const setFilterVehiclesShow = makeVar<null | 1>(null);

const getSearchSource = (): ESearchSource | null => {
  const pageDelta = lastPaginationDelta();
  if (pageDelta != null) {
    if (pageDelta === -1) return ESearchSource.PREVIOUS_PAGE;
    if (pageDelta === 1) return ESearchSource.NEXT_PAGE;
    if (pageDelta < -1) return ESearchSource.PAGE_CLICK_BEHIND;
    if (pageDelta > 1) return ESearchSource.PAGE_CLICK_AHEAD;
    return ESearchSource.PAGE_CLICK_SAME;
  }
  if (lastZoomDirection() === -1) return ESearchSource.MAP_ZOOM_OUT;
  if (lastZoomDirection() === 1) return ESearchSource.MAP_ZOOM_IN;
  if (lastMapPan() === 1) return ESearchSource.MAP_PAN;
  if (setFilterVehiclesShow() === 1) return ESearchSource.FILTER_SHOW_VEHICLES;
  if (lastSearchedInSearchBar() === 1) return ESearchSource.SEARCH_BAR;
  return null;
};

export const getSearchViewedEventData = createSelector<
  TRootState,
  IPageViewEvent | null,
  IMetaData,
  ISearchResultInfo | null
>(getPageViewEventData, getSearchResultsMeta, (eventData, metadata) => {
  if (eventData) {
    const { total, startPosition, stopPosition, totalUnavailable } = metadata || {};
    const currentPageTotalRentals = stopPosition - startPosition + 1;
    const pageLimit =
      parseInt((eventData?.queryParams?.[ESearchFilters.PAGE_LIMIT] as string) || '') || 24;
    const itemsPerPage = getItemsPerPage(String(pageLimit));

    let listingsSort = ESearchSort.RECOMMENDED;

    if (eventData.queryParams.sort === 'price') {
      listingsSort = ESearchSort.PRICE_LOW;
    }

    if (eventData.queryParams.sort === '-price') {
      listingsSort = ESearchSort.PRICE_HIGH;
    }
    return {
      filters: eventData.queryParams,
      locationQuery: eventData.queryParams.address,
      locationLatitude: eventData.lat,
      locationLongitude: eventData.lng,
      departureDate: parseInt(
        (
          new Date(eventData.queryParams[ESearchFilters.DATE_FROM] as string).getTime() / 1000
        ).toFixed(0),
      ),
      returnDate: parseInt(
        (
          new Date(eventData.queryParams[ESearchFilters.DATE_TO] as string).getTime() / 1000
        ).toFixed(0),
      ),
      rentalIDs: eventData.rentalIds.map((rentalId: string) => parseInt(rentalId)),
      listingsSort,
      totalListingsFound: total,
      listingsOnPage: currentPageTotalRentals,
      listingsPerPage: pageLimit,
      totalPagesAvailable: Math.ceil(total / itemsPerPage),
      totalUnavailableListings: totalUnavailable,
      totalAdults: parseInt(eventData.queryParams[ESearchFilters.GUESTS_ADULTS] as string),
      totalChildren: parseInt(eventData.queryParams[ESearchFilters.GUESTS_CHILDREN] as string),
      currentPageViewed: Math.ceil(stopPosition / pageLimit),
      source: getSearchSource(),
    } as ISearchResultInfo;
  }

  return null;
});

type THeaderSearchFilters = TRootState['globalHeader'];
export const getSearchResultsExpandedEventData = createSelector<
  TRootState,
  TRentalsData,
  TRentalsMeta,
  TRentalsFilters,
  TCurrentFilters,
  THeaderSearchFilters,
  IRenterSearchResultsExpandedInfo
>(
  getRentals,
  getRentalsMeta,
  getCurrentFilters,
  getRentalsFilters,
  getHeaderSearchFilters,
  (rentals, meta, filters, currentFilters, headerSearchFilters) => {
    const { total_unavailable: totalUnavailableListings, total: totalListingsFound } = meta || {};
    return {
      filters: filters || null,
      locationQuery: headerSearchFilters?.address || null,
      departureDate: (filters.dateFrom && parseEventDate(filters.dateFrom as string)) || null,
      returnDate: (filters.dateTo && parseEventDate(filters.dateTo as string)) || null,
      listingsExpandedOnPage: rentals.length - currentFilters['page[limit]'] || 0,
      totalListingsFound,
      totalUnavailableListings,
      listingsOnPage: rentals.length,
      rentalIDs: rentals?.map((rental: IRentalTile) => rental.id),
      listingsPerPage: currentFilters?.['page[limit]'] || null,
      rentalType: ERentalType.RV, // @TODO update once we have a category filter
    };
  },
);
