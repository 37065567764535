import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IBooking, IBookingDeliveryLocation } from '@/services/types/booking/details';
import { IQuote } from '@/services/types/core/quotes';
import { IDeliveryProposal, ProposalTypes } from '@/services/types/proposals/proposals';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { buildPatchBookingData } from '@/utility/booking';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getBookingData } from '../selectors/checkout/booking';
import { getCurrency } from '../selectors/currency';
import { getQuoteFromAndTo } from '../selectors/listing/bill';
import { getListingId } from '../selectors/listing/page';
import { getFromAndTo } from '../selectors/queryParams';
import { bookingDetailsSelector } from './booking/selectors';
import { ISetQuoteAction } from './quote';

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
const GET_DELIVERY_COST_REQUEST = 'delivery/GET_DELIVERY_COST_REQUEST';
const GET_DELIVERY_COST_RESPONSE = 'delivery/GET_DELIVERY_COST_RESPONSE';
const GET_DELIVERY_COST_FAILURE = 'delivery/GET_DELIVERY_COST_FAILURE';
const CLEAR_DELIVERY_COST = 'delivery/CLEAR_DELIVERY_COST';

interface IGetDeliveryCostAction extends IAction {
  type: typeof GET_DELIVERY_COST_REQUEST;
}
interface IGetDeliveryCostResponseAction extends IAction {
  type: typeof GET_DELIVERY_COST_RESPONSE;
}
interface IGetDeliveryCostFailAction extends IAction {
  type: typeof GET_DELIVERY_COST_FAILURE;
  payload: string;
  error: true;
}
interface IClearDeliveryCostAction extends IAction {
  type: typeof CLEAR_DELIVERY_COST;
}

type TAction =
  | IGetDeliveryCostAction
  | IGetDeliveryCostResponseAction
  | IGetDeliveryCostFailAction
  | IClearDeliveryCostAction;

export const clearDeliveryCost = (): TAction => ({
  type: CLEAR_DELIVERY_COST,
});

const mapBookingToDelivery = (
  state: TRootState,
  {
    address,
    stationary,
    estimatedDistance,
  }: { address: IBookingDeliveryLocation; stationary: boolean; estimatedDistance?: number },
): Partial<IBooking> => {
  const delivery = state.checkout.booking?.delivery;
  const deliveryUsageItemId = state.listing?.data?.delivery_usage_item_id;

  return {
    delivery: { ...delivery, estimated: estimatedDistance, stationary },
    delivery_usage_item_id: deliveryUsageItemId,
    destination_lat: address.lat,
    destination_lng: address.lng,
    dropoff: address,
    pickup: address,
  };
};

const mapQuoteToDelivery = (
  state: TRootState,
  { address, stationary }: { address: IQuoteDeliveryLocation; stationary: boolean },
) => {
  const currency = getCurrency(state);
  const rentalId = getListingId(state);
  const { from: quoteFrom, to: quoteTo } = getQuoteFromAndTo(state);
  const { from: urlFrom, to: urlTo } = getFromAndTo(state);
  const from = quoteFrom || urlFrom;
  const to = quoteTo || urlTo;

  return {
    presentment_currency: currency,
    rental_id: rentalId,
    from,
    to,
    reserve: false,
    delivery: { stationary, location: address },
  };
};

const mapDeliveryDataToProposal = (
  booking: IBooking,
  address: IQuoteDeliveryLocation,
  stationary: boolean,
) => {
  return {
    action: ProposalTypes.AddDelivery,
    booking_id: booking.id,
    object_id: booking.id,
    object_type: 'delivery',
    dry_create: true,
    details: {
      stationary: stationary,
      location: {
        lat: Number(address.lat),
        lng: Number(address.lng),
      },
    },
  };
};

export type TUpdateDeliveryCostActions =
  | IGetDeliveryCostAction
  | IGetDeliveryCostResponseAction
  | IGetDeliveryCostFailAction
  | ISetQuoteAction;

export const getDeliveryCost =
  (
    address: IQuoteDeliveryLocation | IBookingDeliveryLocation,
    stationary: boolean,
    estimatedDistance?: number,
  ): ThunkAction<void, TRootState, void, TUpdateDeliveryCostActions> =>
  (dispatch, getState) => {
    dispatch<IGetDeliveryCostAction>({
      type: GET_DELIVERY_COST_REQUEST,
    });

    const state = getState();
    const bookingData = getBookingData(state);
    const bookingDetailsData = bookingDetailsSelector(state);
    let requestPromise: Promise<{
      estimated_distance?: number;
      price?: number;
    }>;
    if (bookingData) {
      const data = mapBookingToDelivery(state, {
        address: address as IBookingDeliveryLocation,
        stationary,
        estimatedDistance,
      });
      requestPromise = apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/bookings/${bookingData.id}`,
          data: buildPatchBookingData(bookingData, data),
          method: 'PATCH',
        },
        true,
      ).then((response: IBooking) => ({
        estimated_distance: response?.delivery?.estimated,
        price: response?.delivery?.estimated_fee,
      }));
    } else if (
      bookingDetailsData &&
      ['approved', 'imminent', 'negotiating'].includes(bookingDetailsData.status || '')
    ) {
      const deliveryProposalData = mapDeliveryDataToProposal(
        bookingDetailsData,
        address,
        stationary,
      );
      requestPromise = apiRequest<IDeliveryProposal>(
        {
          url: `${getCoreApi()}/bookings/${bookingDetailsData.id}/proposals`,
          method: 'POST',
          data: deliveryProposalData,
        },
        true,
      ).then((response: IDeliveryProposal) => {
        const location = { ...response.details?.location };
        const oldDeliveryLocation = { ...response.details?.old_booking_delivery_location };

        const isSameAddress =
          location.lat === oldDeliveryLocation.lat && location.lng === oldDeliveryLocation.lng;
        return {
          estimated_distance: response.details?.estimated_distance,
          price: response.details?.total,
          insurancePriceDifference: response.details?.insurance_price_difference,
          isSameAddress,
        };
      });
    } else {
      const data = mapQuoteToDelivery(state, { address, stationary });

      if (!data.from || !data.to) return;

      requestPromise = apiRequest<IQuote>(
        { url: `${getCoreApi()}/quotes`, data, method: 'POST' },
        true,
      ).then((response: IQuote) => ({
        estimated_distance: response?.delivery?.estimated_distance,
        price: response?.delivery_item?.price,
      }));
    }

    return new Promise((resolve, reject) =>
      requestPromise
        .then(deliveryItem =>
          resolve(
            dispatch<IGetDeliveryCostResponseAction>({
              type: GET_DELIVERY_COST_RESPONSE,
              payload: deliveryItem,
            }),
          ),
        )
        .catch(error =>
          reject(
            dispatch<IGetDeliveryCostFailAction>({
              type: GET_DELIVERY_COST_FAILURE,
              payload: error?.error || error,
              error: true,
            }),
          ),
        ),
    );
  };

interface IDeliveryQuote {
  estimated_distance: number;
  price: number;
}
interface IState {
  data?: IDeliveryQuote;
  isLoading?: boolean;
  error?: string;
}

export const initialState: IState = {};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case GET_DELIVERY_COST_REQUEST:
      return {
        ...state,
        data: undefined,
        isLoading: true,
        error: undefined,
      };
    case GET_DELIVERY_COST_RESPONSE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: undefined,
      };
    case GET_DELIVERY_COST_FAILURE:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_DELIVERY_COST:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: undefined,
      };
    default:
      return state;
  }
}
