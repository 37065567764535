import { PopoverOrigin } from '@material-ui/core/Popover';
import React from 'react';

import Modal, { ModalSize } from '@/components/switchback/Modal/Modal';
import Popup, { IPopupProps } from '@/components/switchback/Popup/Popup';
import { useBreakpoint } from '@/hooks/useBreakpoint';

export interface IDropdownProps
  extends Partial<
    Pick<
      IPopupProps,
      | 'disableAutoFocus'
      | 'disableEnforceFocus'
      | 'disableEscapeKeyDown'
      | 'disablePortal'
      | 'disableRestoreFocus'
    >
  > {
  className?: string;
  /**
   * The element to which the popup is anchored
   */
  modalClassName?: string;
  /**
   * applies for mobile modal
   */
  relativeTo: Element | null;
  /**
   * Whether or not the popup is currently visible
   */
  show?: boolean;
  /**
   * The title to be displayed in the modal on mobile
   */
  title?: string | React.ReactNode;
  /**
   * Modal title container class, add more class names here to extend the modal title style.
   */
  titleContainerClass?: string;
  /**
   * Some cases need more complex UI than the title's string
   */
  customHeader?: React.ReactNode;
  /**
   * Modal's footer
   */
  customFooter?: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  /**
   * Method to call when the dropdown is closed
   * Sets two possibly undefined, without a specific
   * type args because the calendar calls it with from
   * and to upon dissmiss
   */
  onDismiss: (arg1?: any, arg2?: any) => void;
  /**
   * Fired when the dropdown is entered. The Modal (mobile)
   * base component does not have an onExited event, so
   * onExited will be called together.
   */
  onEntered?: () => void;
  /**
   * Fired when the dropdown is exited. The Modal (mobile)
   * base component does not have an onExited event, so
   * onDismiss and onExited will be called together.
   */
  onExited?: () => void;
}

const Dropdown: React.FC<IDropdownProps> = ({
  className = 'p-8 mt-2 shadow-100 rounded-box',
  modalClassName,
  children,
  relativeTo,
  show = false,
  title,
  titleContainerClass,
  customHeader,
  customFooter,
  anchorOrigin = {
    horizontal: 'left',
    vertical: 'bottom',
  },
  transformOrigin = {
    horizontal: 'left',
    vertical: 'top',
  },
  onDismiss,
  onEntered,
  onExited,
  ...rest
}) => {
  const { isAboveTablet } = useBreakpoint();

  if (isAboveTablet) {
    return (
      <Popup
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        show={show}
        onDismiss={onDismiss}
        onExited={onExited}
        relativeTo={relativeTo}
        className={className}
        onEntered={onEntered}
        {...rest}>
        {customHeader}
        {children}
        {customFooter}
      </Popup>
    );
  }

  const handleClose = () => {
    onDismiss?.();
    onExited?.();
  };

  return (
    <Modal
      open={show}
      customHeader={customHeader}
      footer={customFooter}
      titleContainerClass={titleContainerClass}
      size={ModalSize.large}
      title={title}
      onClose={handleClose}
      className={modalClassName}
      {...rest}>
      {children}
    </Modal>
  );
};

export default Dropdown;
