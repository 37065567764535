import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';

interface IProps {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
}

const GlobalHeaderNavMyAccountAvatar: React.FC<IProps> = ({
  firstName,
  lastName,
  avatarUrl = '',
}) => {
  const intl = useIntl();
  let Content: ReactElement = <>{intl.formatMessage({ defaultMessage: 'Me' })}</>;

  if (avatarUrl) {
    Content = (
      <ResponsiveImage
        src={avatarUrl}
        sourceSizes={['square40', 'square80']}
        className="object-cover w-full"
        role="presentation"
        alt=""
      />
    );
  } else if (firstName || lastName) {
    Content = (
      <>
        {firstName?.[0]?.toUpperCase()}
        {lastName?.[0]?.toUpperCase()}
      </>
    );
  }

  return (
    <div className="inline-flex items-center justify-center overflow-hidden text-primary-contrast bg-primary-800 rounded-[4px] w-[24px] h-[24px] lg:w-[32px] lg:h-[32px] autoType200">
      {Content}
    </div>
  );
};

export default GlobalHeaderNavMyAccountAvatar;
