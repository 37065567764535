import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IRentalTag } from '@/services/types/core/reviews/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

export const FETCH_TAGS_PENDING = 'review/FETCH_TAGS_PENDING';
export const FETCH_TAGS_SUCCESS = 'review/FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'review/FETCH_TAGS_FAILURE';

interface ITagsPendingAction extends IAction {
  type: typeof FETCH_TAGS_PENDING;
}

interface ITagsSuccessAction extends IAction {
  type: typeof FETCH_TAGS_SUCCESS;
  payload: IRentalTag[];
}

interface ITagsFailureAction extends IAction {
  type: typeof FETCH_TAGS_FAILURE;
  error: boolean;
}

export type ITagsAction = ITagsPendingAction | ITagsSuccessAction | ITagsFailureAction;

const TAGS_URL = `${getCoreApi()}/tags`;

const fetchTagsSuccessAction = (payload: IRentalTag[]): ITagsAction => ({
  type: FETCH_TAGS_SUCCESS,
  payload,
});

const fetchTagsPendingAction = (): ITagsAction => ({
  type: FETCH_TAGS_PENDING,
});

const fetchTagsFailureAction = (): ITagsAction => ({
  type: FETCH_TAGS_FAILURE,
  error: true,
});

export const fetchTags =
  (): ThunkAction<Promise<void>, TRootState, void, ITagsAction> => async dispatch => {
    try {
      dispatch(fetchTagsPendingAction());

      const tags = await apiRequest<IRentalTag[]>(
        {
          url: TAGS_URL,
          method: 'GET',
        },
        true,
      );
      dispatch(fetchTagsSuccessAction(tags));
    } catch (err) {
      dispatch(fetchTagsFailureAction);
    }
  };
