import { useReactiveVar } from '@apollo/client';
import uniq from 'lodash/uniq';
import { useEffect } from 'react';

import {
  canUseLocalStorage,
  getItemFromLocalStorage,
  localStorageCache,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from '@/utility/localstorage';

let pollerId: ReturnType<typeof setInterval> | undefined;
const subscribers: string[] = [];

/**
 * Technically private, but exported so tests can reset any polling
 */
export const teardown = () => {
  if (pollerId) {
    clearInterval(pollerId);
    pollerId = undefined;
  }
};

export function useLocalStorage(
  key: string,
): [string | null, { update: (value: string | null) => void; remove: () => void }] {
  const cache = useReactiveVar(localStorageCache);
  const update = (value: string | null) => setItemInLocalStorage(key, value || '');
  const remove = () => removeItemFromLocalStorage(key);

  useEffect(() => {
    getItemFromLocalStorage(key);
    subscribers.push(key);

    if (!pollerId && canUseLocalStorage() && subscribers.length > 0) {
      pollerId = setInterval(() => {
        uniq(subscribers).forEach(key => {
          getItemFromLocalStorage(key);
        });
      }, 2000);
    }

    return () => {
      subscribers.splice(subscribers.indexOf(key), 1);
      if (pollerId && subscribers.length === 0) {
        teardown();
      }
    };
  }, [key]);

  return [cache[key] || null, { update, remove }];
}
