declare global {
  interface Window {
    qualtricTracking: IQualtricTracking;
    openQualtrics: () => void;
  }
}

interface IQualtricTracking {
  /** current path of the url */
  PageName?: string;
  PageTitle?: string | null;
  /** user id */
  UserId?: number | null;
  /** session id */
  AnonymousId?: string | null;
  LoggedIn?: boolean;
  Email?: string | null;
  FirstName?: string | null;
  LastName?: string | null;
  TripType?: string | null;
  Platform?: string | null;
  BookingId?: number | null;
  CheckoutStep?: string | null;
  AccountType?: string | null;
  PurchaseType?: string | null;
}

export const updateQualtricTracking = (tracking: IQualtricTracking) => {
  if (typeof window === 'undefined') return;
  if (!window.qualtricTracking) window.qualtricTracking = {};
  Object.assign(window.qualtricTracking, tracking);
};

export const triggerQualtrics = () => {
  if (typeof window.openQualtrics === 'function') {
    window.openQualtrics();
  }
};
