import { createSelector } from 'reselect';

import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import { ERentalType } from '@/services/analytics/types';

import { getListingData, getListingLocation } from './listing/page';
import { getSearchStayStates } from './search/page';

export const getAllowedStatesForStay = (state: TRootState) => state.stayStates.data;

//Retrieve the active state if it is included in the list of whitelisted states; otherwise, return the default address - 'United States'.
export const getAddressForStaySearch = createSelector<
  TRootState,
  ReturnType<typeof getListingLocation>,
  ReturnType<typeof getSearchStayStates>,
  TRootState['stayStates'],
  string
>(
  getListingLocation,
  getSearchStayStates,
  data => data.stayStates,
  (listingLocation, searchStates, stayStates) => {
    const activeStateObj = stayStates.data.find(
      state =>
        state.code === listingLocation?.state ||
        (searchStates.length === 1 && searchStates[0] === state.code),
    );

    return activeStateObj?.full_name || 'United States';
  },
);

export const getIsListingAllowedByWhitelist = createSelector<
  TRootState,
  ReturnType<typeof getListingData>,
  ReturnType<typeof getListingLocation>,
  ReturnType<typeof getAllowedStatesForStay>,
  boolean
>(
  getListingData,
  getListingLocation,
  getAllowedStatesForStay,
  (listing, listingLocation, allowedStatesForStay) => {
    const isStay = listing?.rental_category === ERentalType.STAY;

    if (!isStay) return true;

    return allowedStatesForStay
      ? allowedStatesForStay.some(state => state.code === listingLocation?.state)
      : false;
  },
);

export const getNearbyRentalsURL = createSelector<
  TRootState,
  ReturnType<typeof getListingData>,
  ReturnType<typeof getListingLocation>,
  ReturnType<typeof getAddressForStaySearch>,
  string
>(
  getListingData,
  getListingLocation,
  getAddressForStaySearch,
  (listing, listingLocation, addressForStaySearch) => {
    const isStay = listing?.rental_category === ERentalType.STAY;
    const addressParam = listingLocation
      ? encodeURIComponent(`${listingLocation.city},${listingLocation.state}`)
      : '';
    let href = `/rv-search?${ESearchFilters.ADDRESS}=${addressParam}&${ESearchFilters.NEAR_RENTAL}=${listing?.id}`;

    if (isStay) {
      href = `/rv-search?${ESearchFilters.ADDRESS}=${addressForStaySearch}&${ESearchFilters.RENTAL_CATEGORY}=stay`;
    }

    return href;
  },
);
