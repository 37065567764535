import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCacheEviction } from '@/hooks/useCacheEviction';
import { useRouter } from '@/hooks/useRouter';
import { useUpdateEffect } from '@/hooks/useUpdateEffect';
import useUpdateQualtricTracking from '@/hooks/useUpdateQualtricTracking';
import { fetchAllowedStatesForStay } from '@/redux/modules/stayStates';
import { getIsAuthenticated } from '@/redux/selectors/auth/user';
import { trackFeedbackCTASelectedEvent } from '@/services/analytics/feedback';
import {
  pageCategoryMap,
  parsePageUrl,
  storePageMetaData,
} from '@/services/analytics/pageEventUtils';

export const Global: React.FC = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { evict } = useCacheEviction(['me']);
  const dispatch = useDispatch();

  useUpdateQualtricTracking();

  const router = useRouter();
  const storedPageMetaData = storePageMetaData();

  useEffect(() => {
    // Options for the MutationObserver to observe changes in the DOM
    const observerOptions: MutationObserverInit = {
      childList: true,
      subtree: true,
    };

    const callback: MutationCallback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const element = document.querySelector('.QSIWebResponsive');
          if (element && storedPageMetaData?.pageCategory) {
            trackFeedbackCTASelectedEvent({
              pageCategory:
                pageCategoryMap[parsePageUrl(storedPageMetaData.pageCategory)] || 'other',
            });

            // Disconnect the observer after the event is triggered
            observer.disconnect();
            break;
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.body, observerOptions);

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.asPath]); //rerun the observer when the url is changed

  // evict the cache when the user is logged out
  useUpdateEffect(() => {
    if (!isAuthenticated) {
      evict();
    }
  }, [isAuthenticated, evict]);

  useEffect(() => {
    dispatch(fetchAllowedStatesForStay());
  }, [dispatch]);

  return null;
};
