import classnames from 'classnames';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { CAMPER_VAN, CAMPING } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { setQueryParam } from '@/redux/modules/queryParams';
import { applySearchFilter } from '@/redux/modules/search';
import { resetForm } from '@/redux/modules/searchForm';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { ERentalType } from '@/services/analytics/types';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { isProduction } from '@/utility/isSSR';

export enum ERentalTypeFilter {
  STAY = 'stay',
  RV = 'rv',
  CAMPGROUND = 'campground',
}

type TRentalTypeFilterProps = {
  isMapOpen?: boolean;
};

const RentalTypeFilter: React.FC<TRentalTypeFilterProps> = ({ isMapOpen }) => {
  const queryParams = useSelector(getQueryParams);
  const { isAboveDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const { [ESearchFilters.RENTAL_CATEGORY]: originalRentalType } = queryParams;
  const isCampgroundsWebEnabled = useExperimentIsEnabled(
    OptimizelyFlags.CAMPGROUNDS_SEARCH_TAB_AND_HOME_TAB,
  );
  const showCampgroundsTab =
    !isProduction() && (isCampgroundsWebEnabled || originalRentalType === ERentalType.CAMPGROUND);

  const handleSearch = useCallback(
    (selectedRentalType: ERentalTypeFilter) => {
      if (
        selectedRentalType === ERentalTypeFilter.RV
          ? originalRentalType
          : selectedRentalType !== originalRentalType
      ) {
        // 'filter[rental_category]' is not required in 'params' if the tab is switched to 'RVs'.

        const queryParam = {
          [ESearchFilters.RENTAL_CATEGORY]:
            selectedRentalType === ERentalTypeFilter.RV ? undefined : selectedRentalType,
          [ESearchFilters.FILTER_TYPE]: undefined,
          [ESearchFilters.ADDRESS]: queryParams[ESearchFilters.ADDRESS],
          [ESearchFilters.BOUNDS_NE]: queryParams[ESearchFilters.BOUNDS_NE],
          [ESearchFilters.BOUNDS_SW]: queryParams[ESearchFilters.BOUNDS_SW],
          [ESearchFilters.DATE_FROM]: queryParams[ESearchFilters.DATE_FROM],
          [ESearchFilters.DATE_TO]: queryParams[ESearchFilters.DATE_TO],
          [ESearchFilters.GEOIP_ADDRESS]: queryParams[ESearchFilters.GEOIP_ADDRESS],
        };

        dispatch(resetForm());
        dispatch(setQueryParam(queryParam));
        dispatch(applySearchFilter(queryParam, false, !isAboveDesktop && !!isMapOpen));
      }
    },
    [isMapOpen, isAboveDesktop, originalRentalType, queryParams, dispatch],
  );

  if (!showCampgroundsTab) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <button
        onClick={() => handleSearch(ERentalTypeFilter.RV)}
        className={classnames(
          {
            'border-b-2': !originalRentalType || originalRentalType === ERentalTypeFilter.RV,
          },
          'flex py-4 px-2.5 mr-2.5 items-center',
        )}>
        <Icon name={CAMPER_VAN} size={IconSize.large} className="w-6 h-6" />
        <span className="font-medium ml-2.5">
          <FormattedMessage defaultMessage="RVs" />
        </span>
      </button>
      {showCampgroundsTab && (
        <button
          onClick={() => handleSearch(ERentalTypeFilter.CAMPGROUND)}
          className={classnames(
            {
              'border-b-2': originalRentalType === ERentalTypeFilter.CAMPGROUND,
            },
            'flex py-4 px-2.5 items-center',
          )}>
          <Icon name={CAMPING} size={IconSize.large} className="w-6 h-6" />
          <span className="font-medium ml-2.5">
            <FormattedMessage defaultMessage="Campgrounds" />
          </span>
        </button>
      )}
    </div>
  );
};

export default RentalTypeFilter;
