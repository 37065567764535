import { ThunkAction } from 'redux-thunk';

import { fetchBooking } from '@/redux/modules/booking/actions';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IDateRangeString } from '@/services/types/date/date.types';
import { IDailyChanges, IProposal } from '@/services/types/proposals/proposals';
import { TDates } from '@/utility/dateValidator';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';

const getDateProposalUrl = (bookingId: number, proposalId?: number): string =>
  `${getCoreApi()}/bookings/${bookingId}/proposals${proposalId ? `/${proposalId}` : ''}`;

const DRY_DATE_PROPOSAL_LOADING = 'date-proposal/DRY_DATE_PROPOSAL_LOADING';
const DRY_DATE_PROPOSAL_ERROR = 'date-proposal/DRY_DATE_PROPOSAL_ERROR';
const DRY_DATE_PROPOSAL_SUCCESS = 'date-proposal/DRY_DATE_PROPOSAL_SUCCESS';
const DRY_DATE_PROPOSAL_CLEAR = 'date-proposal/DRY_DATE_PROPOSAL_CLEAR';
const POST_DATE_PROPOSAL_LOADING = 'date-proposal/POST_DATE_PROPOSAL_LOADING';
const POST_DATE_PROPOSAL_ERROR = 'date-proposal/POST_DATE_PROPOSAL_ERROR';
const POST_DATE_PROPOSAL_SUCCESS = 'date-proposal/POST_DATE_PROPOSAL_SUCCESS';
const POST_DATE_PROPOSAL_CLEAR = 'date-proposal/POST_DATE_PROPOSAL_CLEAR';
const PATCH_DATE_PROPOSAL_LOADING = 'date-proposal/PATCH_DATE_PROPOSAL_LOADING';
const PATCH_DATE_PROPOSAL_ERROR = 'date-proposal/PATCH_DATE_PROPOSAL_ERROR';
const PATCH_DATE_PROPOSAL_SUCCESS = 'date-proposal/PATCH_DATE_PROPOSAL_SUCCESS';

interface IDryDateProposalLoading extends IAction {
  type: typeof DRY_DATE_PROPOSAL_LOADING;
}
interface IDryDateProposalError extends IAction {
  type: typeof DRY_DATE_PROPOSAL_ERROR;
}
interface IDryDateProposalSuccess extends IAction {
  type: typeof DRY_DATE_PROPOSAL_SUCCESS;
  payload: IProposal;
}
interface IDryDateProposalClear extends IAction {
  type: typeof DRY_DATE_PROPOSAL_CLEAR;
}
interface IPostDateProposalLoading extends IAction {
  type: typeof POST_DATE_PROPOSAL_LOADING;
}
interface IPostDateProposalError extends IAction {
  type: typeof POST_DATE_PROPOSAL_ERROR;
}
interface IPostDateProposalSuccess extends IAction {
  type: typeof POST_DATE_PROPOSAL_SUCCESS;
  payload: IProposal;
}
interface IPostDateProposalClear extends IAction {
  type: typeof POST_DATE_PROPOSAL_CLEAR;
}
interface IPatchDateProposalLoading extends IAction {
  type: typeof PATCH_DATE_PROPOSAL_LOADING;
}
interface IPatchDateProposalError extends IAction {
  type: typeof PATCH_DATE_PROPOSAL_ERROR;
}
interface IPatchDateProposalSuccess extends IAction {
  type: typeof PATCH_DATE_PROPOSAL_SUCCESS;
}
type TDryAction =
  | IDryDateProposalError
  | IDryDateProposalLoading
  | IDryDateProposalSuccess
  | IDryDateProposalClear;
type TPostAction =
  | IPostDateProposalError
  | IPostDateProposalLoading
  | IPostDateProposalSuccess
  | IPostDateProposalClear;
type TPatchAction = IPatchDateProposalError | IPatchDateProposalLoading | IPatchDateProposalSuccess;

const dryDateProposalLoading = (): IDryDateProposalLoading => ({
  type: DRY_DATE_PROPOSAL_LOADING,
});
const dryDateProposalError = (): IDryDateProposalError => ({
  type: DRY_DATE_PROPOSAL_ERROR,
});
const dryDateProposalSuccess = (payload: IProposal): IDryDateProposalSuccess => ({
  type: DRY_DATE_PROPOSAL_SUCCESS,
  payload,
});
export const dryDateProposalClear = (): IDryDateProposalClear => ({
  type: DRY_DATE_PROPOSAL_CLEAR,
});
const postDateProposalLoading = (): IPostDateProposalLoading => ({
  type: POST_DATE_PROPOSAL_LOADING,
});
const postDateProposalError = (): IPostDateProposalError => ({
  type: POST_DATE_PROPOSAL_ERROR,
});
const postDateProposalSuccess = (payload: IProposal): IPostDateProposalSuccess => ({
  type: POST_DATE_PROPOSAL_SUCCESS,
  payload,
});
export const postDateProposalClear = (): IPostDateProposalClear => ({
  type: POST_DATE_PROPOSAL_CLEAR,
});

interface IDryDateProposalState {
  data: IProposal | null;
  error: boolean;
  isLoading: boolean;
}
export const initialDryDateProposalState: IDryDateProposalState = {
  data: null,
  error: false,
  isLoading: false,
};
function dryDateProposalReducer(state = initialDryDateProposalState, action: TDryAction) {
  switch (action.type) {
    case DRY_DATE_PROPOSAL_LOADING:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case DRY_DATE_PROPOSAL_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case DRY_DATE_PROPOSAL_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload,
      };
    case DRY_DATE_PROPOSAL_CLEAR:
      return { ...initialDryDateProposalState };
    default:
      return { ...state };
  }
}

interface IPostDateProposalState {
  data: IProposal | null;
  error: boolean;
  isLoading: boolean;
}
export const initialPostDateProposalState: IPostDateProposalState = {
  data: null,
  error: false,
  isLoading: false,
};
function postDateProposalReducer(state = initialPostDateProposalState, action: TPostAction) {
  switch (action.type) {
    case POST_DATE_PROPOSAL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case POST_DATE_PROPOSAL_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case POST_DATE_PROPOSAL_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload,
      };
    case POST_DATE_PROPOSAL_CLEAR:
      return { ...initialPostDateProposalState };
    default:
      return { ...state };
  }
}
interface IPatchDateProposalState {
  data: IProposal | null;
  error: boolean;
  isLoading: boolean;
}
const initialPatchDateProposalState: IPatchDateProposalState = {
  data: null,
  error: false,
  isLoading: false,
};
function patchDateProposalReducer(state = initialPatchDateProposalState, action: TPatchAction) {
  switch (action.type) {
    case PATCH_DATE_PROPOSAL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PATCH_DATE_PROPOSAL_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case PATCH_DATE_PROPOSAL_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}

export default {
  postDateProposalReducer,
  dryDateProposalReducer,
  patchDateProposalReducer,
};

// Selectors and actions
export const selectDryDateProposal = (state: TRootState): IDryDateProposalState =>
  state.dryDateProposal;
export const selectPostDateProposal = (state: TRootState): IPostDateProposalState =>
  state.postDateProposal;

interface IProposalDetails {
  from: string;
  to: string;
  prices?: IDailyChanges[] | null;
}

export const getDryDateProposal =
  (
    dates: TDates,
    prices?: IDailyChanges[] | null,
  ): ThunkAction<Promise<boolean>, TRootState, void, TDryAction> =>
  async (dispatch, getState) => {
    const state = getState();
    const booking = state.booking.details.data;
    const bookingId = booking?.id;
    if (!bookingId) return false;
    const url = getDateProposalUrl(bookingId);
    dispatch(dryDateProposalLoading());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(dryDateProposalError());
      return false;
    }
    try {
      // This will perform a dry-run on create so we can render future booking information.
      const details: IProposalDetails = {
        from: dates.from,
        to: dates.to,
      };
      if (prices) {
        details.prices = prices;
      }
      const data = {
        details,
        action: 'change_dates',
        dry_create: true,
      };
      const dryDateProposal = await apiRequest<IProposal>({ url, method: 'POST', data }, true);
      if (dryDateProposal) {
        dispatch(dryDateProposalSuccess(dryDateProposal));
        return true;
      } else {
        dispatch(dryDateProposalError());
        return false;
      }
    } catch (error) {
      dispatch(dryDateProposalError());
      return false;
    }
  };

export const postDateProposal =
  (
    dates: IDateRangeString,
    eventIds?: number[],
  ): ThunkAction<Promise<boolean>, TRootState, void, TPostAction> =>
  async (dispatch, getState) => {
    const state = getState();
    const bookingId = state.booking.details.data?.id;
    if (!bookingId) return false;
    const url = getDateProposalUrl(bookingId);
    dispatch(postDateProposalLoading());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(postDateProposalError());
      return false;
    }
    try {
      const data = {
        details: dates,
        action: 'change_dates',
        ...(eventIds && { user_notices_acknowledged: eventIds }),
      };

      const postDateProposal = await apiRequest<IProposal>({ url, method: 'POST', data }, true);
      if (postDateProposal) {
        await dispatch(fetchBooking(String(bookingId), true));
        dispatch(postDateProposalSuccess(postDateProposal));
        return true;
      } else {
        dispatch(postDateProposalError());
        return false;
      }
    } catch (error) {
      dispatch(postDateProposalError());
      return false;
    }
  };
