import { ERentalType } from '../types';

export enum ECheckoutEventName {
  CHECKOUT_BOOKING_REQUEST_CONTACT_STARTED = 'Booking Request Contact Started',
  CHECKOUT_BOOKING_REQUEST_CONTACT_COMPLETED = 'Booking Request Contact Completed',
  CHECKOUT_BOOKING_REQUEST_PROTECTION_STARTED = 'Booking Request Protection Started',
  CHECKOUT_BOOKING_REQUEST_PROTECTION_COMPLETED = 'Booking Request Protection Completed',
  CHECKOUT_BOOKING_REQUEST_QUESTIONS_STARTED = 'Booking Request Questions Started',
  CHECKOUT_BOOKING_REQUEST_QUESTIONS_COMPLETED = 'Booking Request Questions Completed',
  CHECKOUT_BOOKING_REQUEST_PAYMENT_STARTED = 'Booking Request Payment Started',
  CHECKOUT_BOOKING_REQUEST_PAYMENT_INITIATED = 'Booking Request Payment Method Initiated',
  CHECKOUT_BOOKING_REQUEST_PAYMENT_COMPLETED = 'Booking Request Payment Completed',
}

/**
 * INTERFACES
 */
export interface ICheckoutBookingRequest {
  rentalID: number;
  rentalType: ERentalType;
  isCampground: boolean;
  isInstabook: boolean;
  pricePerNight: number;
  basePricePerNight: number;
  discountPercentOff?: number; //discountPercentOff?: number;
  departureDate?: number; // departureDate?: number; // unix date
  returnDate?: number; // returnDate: number; // unix date
  totalNights: number;
  handoffMethod: string; // ['pickup', 'delivery']
  insuranceCoverage: string;
  insuranceCost: number;
  addOnsSelected?: string[] | [];
  addOnsCost?: number;
  mileageFeesCost?: number;
  totalCost: number;
  currencyType: string;
  displaySiteCategoryType?: string;
  siteCategoryType?: ERentalType;
  hasWeatherInsurance?: boolean;
}

export interface ICheckoutBookingRequestQuestionsStarted extends ICheckoutBookingRequest {
  bookingID: number;
  totalAvailableQuestions: number | null;
  totalRequiredQuestions: number | null;
}

export interface ICheckoutBookingRequestQuestionsCompleted
  extends ICheckoutBookingRequestQuestionsStarted {
  totalAnsweredQuestions: number | null;
}

export interface ICheckoutBookingRequestPaymentStarted extends ICheckoutBookingRequest {
  bookingID: number;
}

export interface ICheckoutBookingRequestPaymentInitiated extends ICheckoutBookingRequest {
  bookingID: number;
  paymentMethod: string; // ['apple_pay', 'google_pay', 'paypal_now', 'paypal_bnpl']
}

export interface ICheckoutBookingRequestPaymentCompleted extends ICheckoutBookingRequest {
  bookingID: number;
  promoCode?: string;
  promoDiscountAmount?: number;
  referralCode?: string;
  referralDiscountAmount?: number;
  paymentMethod?: string; // ['apple_pay', 'google_pay', 'credit_card', 'paypal_now', 'paypal_bnpl']
  hasTripInsurance: boolean;
}
