import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { countryCodes } from '@/constants/countryCodes';
import apiRequest from '@/services/apiRequest';
import { IMapboxPlacesResponse } from '@/services/types/mapbox/mapboxApi';
import { getSearchApi } from '@/utility/getCoreApi';

import useDebounce from './useDebounce';
import usePrevious from './usePrevious';

const BASE_URL = 'https://api.mapbox.com';
const MAPBOX_TOKEN = process.env.mapboxApiToken || 'xxx';
const GEOLOCATION_URL = `${getSearchApi()}/geodata/my-ip`;

type TMapboxQuery = {
  text: string;
  location?: Array<number>;
  types?: string;
  limit?: number;
  // whether to perform a reverse geocoding lookup (from lng/lat to place name)
  isReverseLookup?: boolean;
};

type TLocationLatLng = {
  lat: number;
  lng: number;
  city: string;
  state: string;
  country_code: string;
  country_name: string;
};

export const genMapboxUrl = (query: TMapboxQuery): string => {
  const { location, isReverseLookup } = query;
  // we either perform reverse geocoding lookup by lng/lat or a geocoding lookup by place name
  const searchText =
    isReverseLookup && location ? encodeURI(location.join(',')) : encodeURI(query.text);
  const url = new URL(`${BASE_URL}/geocoding/v5/mapbox.places/${searchText}.json`);
  const params = {
    access_token: MAPBOX_TOKEN,
    country: countryCodes.join(','),
    ...(location ? { proximity: location.join(',') } : {}),
    types: query.types || 'region,place,district,locality,poi',
    limit: String(query.limit || 5),
  };
  url.search = new URLSearchParams(params).toString();
  return url.toString();
};

function useMapboxQuery(query?: TMapboxQuery | null, delay?: number) {
  const { data: location } = useSWR(
    query?.location ? null : GEOLOCATION_URL,
    async (geoLocationUrl: string) => {
      const res = await apiRequest<TLocationLatLng>({ url: geoLocationUrl });
      return [res.lng, res.lat];
    },
  );

  const url = query ? genMapboxUrl({ ...query, location: query?.location ?? location }) : '';
  const debouncedUrl = useDebounce<typeof url>(url, delay);
  const previousUrl = usePrevious(debouncedUrl);
  const [results, setResults] = useState<IMapboxPlacesResponse['features']>([]);

  useEffect(() => {
    if (debouncedUrl === previousUrl) return;
    if (!debouncedUrl) {
      setResults([]);
      return;
    }
    let isCanceled = false;
    const fetchPlaces = async () => {
      const response = await apiRequest<IMapboxPlacesResponse>({ url: debouncedUrl });
      if (response && !isCanceled) {
        setResults(response.features);
      }
    };
    fetchPlaces();
    return () => {
      isCanceled = true;
    };
  }, [debouncedUrl, previousUrl]);

  return results;
}

export default useMapboxQuery;
