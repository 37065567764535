import { useBreakpoint } from './useBreakpoint';
import { ScrollDirection, useScrollDirection } from './useScrollDirection';

export const useFixedFooterVisibility = () => {
  const { isAboveDesktop } = useBreakpoint();

  const scrollingDirection = useScrollDirection({ skip: isAboveDesktop });

  if (typeof window === 'undefined') return;

  // We need scroll top check only on initial render or when breakpoint changes,
  // so we are fine to use this check directly.
  return window.scrollY === 0 || scrollingDirection === ScrollDirection.up;
};
