import {
  Button,
  Divider,
  EButtonColorVariant,
  EHeadingStyleVariant,
  ELinkVariant,
  ETextStyleVariant,
  Heading,
  Icon,
  Link,
  Modal,
  NumberInput,
  Text,
  Toggle,
} from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';

type TMobileSearchGuestsModalContentProps = {
  onCloseHandler: () => void;
  guests?: Partial<IHeaderSearchFilterGuests>;
  onChangeGuests?: (guests: IHeaderSearchFilterGuests) => void;
  isGuestOccupancyAvailable?: boolean;
};

export const MobileSearchGuestsModalContent = ({
  onCloseHandler,
  guests,
  onChangeGuests,
  isGuestOccupancyAvailable,
}: TMobileSearchGuestsModalContentProps) => {
  const intl = useIntl();

  const [adultsValue, setAdultsValue] = useState(guests?.adults || 0);
  const [childrenValue, setChildrenValue] = useState(guests?.children || 0);
  const [infantsValue, setInfantsValue] = useState(guests?.infants || 0);
  const [accessibleValue, setAccessibleValue] = useState(guests?.accessible || false);
  const [petFriendlyValue, setPetFriendlyValue] = useState(guests?.pet_friendly || false);

  const onResetHandler = () => {
    setAdultsValue(0);
    setChildrenValue(0);
    setInfantsValue(0);
    setPetFriendlyValue(false);
    setAccessibleValue(false);
  };

  const onConfirmHandler = () => {
    const newGuests = {
      adults: adultsValue || undefined,
      children: childrenValue || undefined,
      ...(isGuestOccupancyAvailable && { infants: infantsValue || undefined }),
      pet_friendly: petFriendlyValue || undefined,
      accessible: accessibleValue || undefined,
      seatbelts: guests?.seatbelts || undefined,
    };
    onChangeGuests?.(newGuests);
    onCloseHandler();
  };

  return (
    <>
      <Modal.Actions className="top-4" />
      <Modal.Header inlineHeader={true} className="flex gap-4 !p-4 !pt-[10px]">
        <Heading variant={EHeadingStyleVariant.H6}>
          <FormattedMessage defaultMessage="Guest" />
        </Heading>
        <Link
          className="z-[1400]"
          label={intl.formatMessage({ defaultMessage: 'Reset all' })}
          variant={ELinkVariant.Underline}
          onClick={onResetHandler}
        />
      </Modal.Header>
      <Modal.Divider />
      <Modal.Content className="h-full !mb-0 !my-6 !px-4">
        <div className="flex flex-col p-0 pointer-events-auto md:p-4 gap-4">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <Text variant={ETextStyleVariant.MediumBold}>
                <FormattedMessage defaultMessage="Adults" />
              </Text>
              <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
                <FormattedMessage defaultMessage="Age 13 or above" />
              </Text>
            </div>
            <NumberInput
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Adults number input',
              })}
              value={adultsValue}
              onChange={setAdultsValue}
              maxValue={10}
            />
          </div>
          <Divider />
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <Text variant={ETextStyleVariant.MediumBold}>
                <FormattedMessage defaultMessage="Children" />
              </Text>
              <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
                <FormattedMessage defaultMessage="Age 3-12" />
              </Text>
            </div>
            <NumberInput
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Children number input',
              })}
              value={childrenValue}
              onChange={setChildrenValue}
              maxValue={10}
            />
          </div>
          <Divider />
          {isGuestOccupancyAvailable && (
            <>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <Text variant={ETextStyleVariant.MediumBold}>
                    <FormattedMessage defaultMessage="Infants" />
                  </Text>
                  <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
                    <FormattedMessage defaultMessage="Ages 2 or under" />
                  </Text>
                </div>
                <NumberInput
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Infants number input',
                  })}
                  value={infantsValue}
                  onChange={setInfantsValue}
                  maxValue={10}
                />
              </div>
              <Divider />
            </>
          )}
          <div className="flex items-center justify-between my-2">
            <div className="flex items-center gap-2">
              <Icon className="text-xl font-medium" name="Amenities.Pets.PetFriendly" />
              <Text variant={ETextStyleVariant.MediumBold}>
                <FormattedMessage defaultMessage="Pet friendly" />
              </Text>
            </div>
            <Toggle
              isSelected={petFriendlyValue}
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Pet friendly toggle',
              })}
              onChange={setPetFriendlyValue}
            />
          </div>
          <Divider />
          <div className="flex items-center justify-between my-2">
            <div className="flex items-center gap-2">
              <Icon className="text-xl font-medium" name="Amenities.Ada.Ada" />
              <Text variant={ETextStyleVariant.MediumBold}>
                <FormattedMessage defaultMessage="ADA accessible" />
              </Text>
            </div>
            <Toggle
              isSelected={accessibleValue}
              ariaLabel={intl.formatMessage({
                defaultMessage: 'ADA accessible toggle',
              })}
              onChange={setAccessibleValue}
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full border-t border-gray-250">
          <div className="w-full p-4">
            <Button
              className="w-full"
              variant={EButtonColorVariant.Primary}
              label={intl.formatMessage({ defaultMessage: 'Confirm' })}
              onClick={onConfirmHandler}
            />
          </div>
        </div>
      </Modal.Content>
    </>
  );
};
