import { Slide } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Popup, { IPopupProps } from '@/components/switchback/Popup/Popup';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import css from './GlobalHeaderNavSubmenu.module.css';

interface IProps {
  onDismiss: () => void;
  anchorRef: React.RefObject<any>;
  open?: boolean;
}

const GlobalHeaderNavSubmenu: React.FC<IProps> = ({ onDismiss, anchorRef, open, children }) => {
  const { isAboveXL, isAboveDesktop } = useBreakpoint();
  const intl = useIntl();

  const backButtonLabel = intl.formatMessage({
    defaultMessage: 'Back',
    description: 'Global Header Nav: Accessible label for mobile back button',
  });

  const handleDismiss = (reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && !isAboveXL) return;
    onDismiss?.();
  };

  const handleClickBack = () => {
    onDismiss?.();
  };

  const mobileProps: Omit<IPopupProps, 'onDismiss'> = {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    show: open,
    anchorReference: 'anchorPosition',
    anchorPosition: { left: 0, top: 0 },
    PaperProps: {
      className: 'inset-0',
      variant: 'outlined',
      square: true,
      style: { border: 0 },
    },
    TransitionComponent: Slide,
    TransitionProps: { direction: 'left' } as any,
    classes: {
      root: `${css.popupRoot} mt-14}`,
      paper: css.newNavPopupPaper,
    },
    hideBackdrop: true,
    relativeTo: null,
    renderChildrenBeforePopupOpen: true,
  };

  const desktopProps: Omit<IPopupProps, 'onDismiss'> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    show: open,
    'aria-hidden': !open,
    relativeTo: anchorRef.current,
    className: 'p-8 mt-10 shadow-100 rounded-box',
    renderChildrenBeforePopupOpen: true,
  };

  const PopupProps: Omit<IPopupProps, 'onDismiss'> =
    isAboveDesktop || isAboveXL ? desktopProps : mobileProps;

  return (
    <Popup {...PopupProps} onDismiss={handleDismiss}>
      <div
        className={
          'absolute inset-0 flex flex-col -m-4 bg-canvas-100 md:relative lg:bg-transparent lg:h-auto md:h-full xl:h-auto'
        }>
        <div className={'lg:hidden'}>
          <button
            className={'flex items-center px-4 py-5 text-gray-800 border-none bg-canvas-100'}
            onClick={handleClickBack}
            aria-label={backButtonLabel}>
            <span className="sr-only">{backButtonLabel}</span>
            <Icon name={CARET_LARGE} size={IconSize.small} />
            <span className={'ml-2 text-gray-800 autoType300'}>{backButtonLabel}</span>
          </button>
          <Divider className={'!border-gray-310 mx-4'} />
        </div>
        <div className={'flex-1 p-4 overflow-y-auto'}>{children}</div>
      </div>
    </Popup>
  );
};

export default GlobalHeaderNavSubmenu;
