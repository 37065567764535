import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { EToastVariant, useToast } from '@/hooks/useToast';
import { getIsAuthenticated } from '@/redux/selectors/auth/user';
import { navigateTo } from '@/utility/navigateTo';
import { triggerLoginModal } from '@/utility/triggerLoginModal';

export const useHostingRVFlow = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { showToast } = useToast();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleRentalCreationError = useCallback(() => {
    showToast(
      EToastVariant.Error,
      intl.formatMessage({ defaultMessage: 'Rental creation failure' }),
      intl.formatMessage({
        defaultMessage: 'Unable to create the rental at the moment. Please try again later.',
      }),
    );
  }, [intl, showToast]);

  const redirectToHostingRVFlow = useCallback(async () => {
    if (isAuthenticated) {
      try {
        setLoading(true);

        navigateTo(`${process.env.hostApp}/list-your-rv`);
        setLoading(false);
        return;
      } catch {
        handleRentalCreationError();
      } finally {
        setLoading(false);
      }
    } else {
      triggerLoginModal();
    }
  }, [handleRentalCreationError, isAuthenticated]);

  return { redirectToHostingRVFlow, loading };
};
