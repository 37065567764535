import React from 'react';

import { isExternalLink } from '@/utility/isExternalLink';

const Anchor: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  children,
  target,
  rel,
  ...props
}) => {
  const finalTarget = target || (isExternalLink(href) ? '_blank' : undefined);
  const finalRef = rel || (finalTarget === '_blank' ? 'noreferrer' : undefined);

  return (
    <a href={href} target={finalTarget} rel={finalRef} {...props}>
      {children}
    </a>
  );
};

export default Anchor;
