import { TIconName } from '@outdoorsyco/bonfire';
import { defineMessage, MessageDescriptor } from 'react-intl';

import {
  FRESH_WATER,
  GENERATOR,
  PARKING_ONSITE,
  SEWAGE_TANK,
} from '@/components/switchback/Icon/assets';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import { IconType } from '@/components/switchback/Icon/IconComponent';

interface IMoreFilterContent {
  icon?: ICONS | IconType;
  bonfireIcon?: TIconName;
  label: MessageDescriptor;
  description?: MessageDescriptor;
  size?: { gte: number; lte: number };
}
interface IMoreFilterContentWithoutIcon {
  label: MessageDescriptor;
  description: MessageDescriptor;
}

export const maxTrailerWeight = { pound: 10000, kilogram: 4535 };

export const minimumRating = 0;
export const maximumRating = 5;

type TLengthFilters = '1-24' | '25-31' | '32-38' | '39-255';
export const getVehicleDetailsLengthFilters = () => {
  const result: Record<TLengthFilters, IMoreFilterContent> = {
    '1-24': {
      label: defineMessage({ defaultMessage: 'Under {lte} {length_unit}' }),
      size: {
        gte: 1,
        lte: 24,
      },
    },
    '25-31': {
      label: defineMessage({ defaultMessage: '{gte} - {lte} {length_unit}' }),
      size: {
        gte: 25,
        lte: 31,
      },
    },
    '32-38': {
      label: defineMessage({ defaultMessage: '{gte} - {lte} {length_unit}' }),
      size: {
        gte: 32,
        lte: 38,
      },
    },
    '39-255': {
      label: defineMessage({ defaultMessage: 'Over {gte} {length_unit}' }),
      size: {
        gte: 39,
        lte: 255, // max 8bit int in Go
      },
    },
  };

  return result;
};

export type THookupFilters = 'hookup_water' | 'hookup_electric' | 'hookup_sewer';

export const vehicleDetailsHookupFilters: Record<THookupFilters, IMoreFilterContent> = {
  hookup_water: {
    label: defineMessage({
      defaultMessage: 'Water',
      description: 'More filter: vehicle details - hookups - Water',
    }),
    icon: FRESH_WATER,
  },
  hookup_electric: {
    label: defineMessage({
      defaultMessage: 'Electricity',
      description: 'More filter: vehicle details - hookups - Electricity',
    }),
    icon: GENERATOR,
  },
  hookup_sewer: {
    label: defineMessage({
      defaultMessage: 'Sewage',
      description: 'More filter: vehicle details - hookups - Sewage',
    }),
    icon: SEWAGE_TANK,
  },
};

export type TRulesFilters =
  | 'pet_friendly'
  | 'tailgate_friendly'
  | 'festival_friendly'
  | 'smoking_allowed';

export type TRulesFiltersForStay = 'pet_friendly' | 'smoking_allowed' | 'stay_parking_onsite';

export enum ERulesFiltersForCampgrounds {
  ALLOWS_PETS = 'allows_pets',
  ALLOWS_CAMPFIRES = 'allows_campfires',
  ALLOWS_RV = 'allows_rv',
  ALLOWS_GENERATORS = 'allows_generators',
  ALLOWS_SLIDEOUTS = 'allows_slideouts',
  ALLOWS_SMOKING = 'allows_smoking',
  ALLOWS_PARKING = 'allows_parking',
}

export const getRulesFilters = () => {
  const result: Record<TRulesFilters, IMoreFilterContent> = {
    pet_friendly: {
      icon: ICONS.PET_FRIENDLY,
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    tailgate_friendly: {
      icon: ICONS.TAILGATING,
      label: defineMessage({
        defaultMessage: 'Tailgate-friendly',
        description: 'More filter: rules and policies - Tailgate-friendly',
      }),
    },
    festival_friendly: {
      icon: ICONS.MUSIC_FESTIVAL,
      label: defineMessage({
        defaultMessage: 'Festival-friendly',
        description: 'More filter: rules and policies - Festival-friendly',
      }),
    },
    smoking_allowed: {
      icon: ICONS.SMOKING,
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
  };
  return result;
};
export const getRulesFiltersForStay = () => {
  const result: Record<TRulesFiltersForStay, IMoreFilterContent> = {
    pet_friendly: {
      icon: ICONS.PET_FRIENDLY,
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    smoking_allowed: {
      icon: ICONS.SMOKING,
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
    stay_parking_onsite: {
      icon: PARKING_ONSITE,
      label: defineMessage({
        defaultMessage: 'Parking available',
        description: 'More filter: rules and policies - Parking available',
      }),
    },
  };
  return result;
};

export const getRulesFiltersForCampgrounds = () => {
  const result: Record<ERulesFiltersForCampgrounds, IMoreFilterContent> = {
    allows_rv: {
      bonfireIcon: 'Vehicles.Vehicles.ClassC.Simple',
      label: defineMessage({
        defaultMessage: 'Allows RVs',
        description: 'More filter: rules and policies - Allows RVs',
      }),
    },
    allows_slideouts: {
      bonfireIcon: 'Amenities.Slideout',
      label: defineMessage({
        defaultMessage: 'Allows slideouts',
        description: 'More filter: rules and policies - Allows slideouts',
      }),
    },
    allows_campfires: {
      bonfireIcon: 'Amenities.Outdoor.Firepit',
      label: defineMessage({
        defaultMessage: 'Allows campfires',
        description: 'More filter: rules and policies - Allows campfires',
      }),
    },
    allows_generators: {
      bonfireIcon: 'Amenities.Electricity.Generator',
      label: defineMessage({
        defaultMessage: 'Allows generators',
        description: 'More filter: rules and policies - Allows generators',
      }),
    },
    allows_pets: {
      bonfireIcon: 'Amenities.Pets.PetFriendly',
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    allows_smoking: {
      bonfireIcon: 'Amenities.Smoking.Smoking',
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
    allows_parking: {
      bonfireIcon: 'Amenities.Parking.Parking',
      label: defineMessage({
        defaultMessage: 'Parking available',
        description: 'More filter: rules and policies - Parking available',
      }),
    },
  };

  return result;
};

type TPoliciesFilters = 'unlimitedGenerator' | 'unlimitedMiles';
type TPoliciesFiltersForStay = 'instantBook';
export const getPoliciesFilters = () => {
  const result: Record<TPoliciesFilters, IMoreFilterContent> = {
    unlimitedGenerator: {
      icon: ICONS.GENERATOR,
      label: defineMessage({
        defaultMessage: 'Unlimited generator',
        description: 'More filter: rules and policies - Unlimited generator',
      }),
    },
    unlimitedMiles: {
      icon: ICONS.MILEAGE,
      label: defineMessage({
        defaultMessage: 'Unlimited mileage',
        description: 'More filter: rules and policies - Unlimited mileage',
      }),
    },
  };

  return result;
};

export const getPoliciesFiltersForStay = () => {
  const result: Record<TPoliciesFiltersForStay, IMoreFilterContentWithoutIcon> = {
    instantBook: {
      label: defineMessage({
        defaultMessage: 'Book instantly',
        description: 'More filter: rules and policies - Book instantly',
      }),
      description: defineMessage({
        defaultMessage: 'Stays you can book without waiting for the host’s approval.',
      }),
    },
  };

  return result;
};

export type TCancelPoliciesFilters = 'flexible';
export type TCheckInTypePolicyFilters = 'self';

export const getCancellationFilters = (
  isStay = false,
): Record<TCancelPoliciesFilters, IMoreFilterContent> =>
  isStay
    ? {
        flexible: {
          label: defineMessage({
            defaultMessage: 'Flexible cancellation',
            description: 'More filter: rules and policies - Flexible cancellation',
          }),
          description: defineMessage({
            defaultMessage:
              'Full refund of the booking total, minus taxes, if you cancel more than 5 days before your trip begins.',
          }),
        },
      }
    : {
        flexible: {
          label: defineMessage({
            defaultMessage: 'Flexible',
            description: 'More filter: rules and policies - cancellation - Flexible',
          }),
        },
      };

export const checkInTypeFilters: Record<TCheckInTypePolicyFilters, IMoreFilterContent> = {
  self: {
    label: defineMessage({
      defaultMessage: 'Self check-in',
      description: 'More filter: rules and policies - Self check-in',
    }),
    description: defineMessage({
      defaultMessage:
        'Access your stay using a keypad, smart lock, or key lockbox, without meeting the host.',
    }),
  },
};
