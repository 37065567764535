import Cookie from 'js-cookie';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useRouter } from '@/hooks/useRouter';
import { trackEvent } from '@/services/track-event';
import { getIsAndroidDevice, getIsIOSDevice, getIsMobileSafari } from '@/utility/navigator';
import { getParams } from '@/utility/queryParams';

import Banner from './Banner';

interface ISmartBannerProps {
  source: string;
  hasAppInstalled?: boolean;
}

const SmartBanner: React.FC<ISmartBannerProps> = ({ source, hasAppInstalled }) => {
  const intl = useIntl();
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const isAndroidDevice = getIsAndroidDevice();
  const isIOSDevice = getIsIOSDevice();
  const isMobileSafari = getIsMobileSafari();
  const isAbleToDownloadAPP = (isAndroidDevice || isIOSDevice) && !isMobileSafari;
  const { 'from-app': fromApp } = getParams(router);

  const bannerTitle = intl.formatMessage({ defaultMessage: 'It’s better in the app' });
  const bannerDescription = intl.formatMessage({ defaultMessage: 'Easily explore, book and go' });
  const pageUrl =
    typeof window !== 'undefined' && window.location.pathname + window.location.search;

  const onClickDownload = () => {
    if (typeof window !== 'undefined') {
      trackEvent({
        event: 'Renter : Mobile banner : Download tapped',
        platform: isIOSDevice ? 'iOS' : 'Android',
        page: pageUrl,
        selection: 'app',
        page_type: source,
      });

      window.open(isIOSDevice ? '/apps/guest/ios' : '/apps/guest/android', '_blank');
    }
  };

  const onCloseModal = () => {
    trackEvent({
      event: 'Renter : Mobile banner : Closed tapped',
      platform: 'iOS/Android',
      page: pageUrl,
      selection: 'web',
      page_type: source,
    });

    Cookie.set('app-modal-closed', 'true', { expires: 30 });
    setShowModal(false);
  };

  useEffect(() => {
    const appModalClosed = Cookie.get('app-modal-closed');
    // skip rendering if hasApp isn't initialized
    if (appModalClosed) return;

    if (!isNil(fromApp) || hasAppInstalled) {
      Cookie.set('app-modal-closed', 'true', { expires: 30 });
      return;
    }

    if (isAbleToDownloadAPP) {
      setShowModal(true);
      trackEvent({
        event: 'Renter : Mobile banner : Viewed',
        platform: isIOSDevice ? 'iOS' : 'Android',
        page: pageUrl,
        page_type: source,
      });
    }
  }, [isAbleToDownloadAPP, pageUrl, isIOSDevice, source, fromApp, hasAppInstalled]);

  if (!showModal) {
    return null;
  }

  return (
    <div className="sticky top-0 z-50 w-full border-b border-gray-300">
      <Banner
        title={bannerTitle}
        onClose={onCloseModal}
        onClickDownload={onClickDownload}
        description={bannerDescription}
      />
    </div>
  );
};

export default SmartBanner;
