import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import { setODNEventProperties } from '@/services/analytics/campground/util';
import searchApiRequest, { TQueryParams } from '@/services/searchApiRequest';
import { ICampgroundData, ISearchCampgroundsById } from '@/services/types/search/campgrounds/id';
import { filterSafeQuery } from '@/utility/filterSafeQuery';
import { logger } from '@/utility/logger';
import { IAction } from '@/utility/redux/action';

import { getCurrency } from '../selectors/currency';

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
const LISTING_LOADING = 'campground-listing/LISTING_LOADING';
const SET_LISTING = 'campground-listing/SET_LISTING';
const SET_IS_LOADING = 'campground-listing/SET_IS_LOADING';

interface ISetIsLoadingAction {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}
interface IListingLoadingAction extends IAction {
  type: typeof LISTING_LOADING;
}

interface ISetListingAction extends IAction {
  type: typeof SET_LISTING;
  payload: ICampgroundData;
}

type TAction = IListingLoadingAction | ISetListingAction | ISetIsLoadingAction;

export const setIsLoading = (payload: boolean): ISetIsLoadingAction => ({
  type: SET_IS_LOADING,
  payload,
});

const setCampgroundListing = (payload: ICampgroundData): ISetListingAction => ({
  type: SET_LISTING,
  payload,
});

export const getCampgroundListing =
  (
    rentalId: string,
    params?: TQueryParams,
  ): ThunkAction<
    Promise<ICampgroundData>,
    TRootState,
    void,
    ISetListingAction | IListingLoadingAction
  > =>
  async (dispatch, getState) => {
    dispatch<IListingLoadingAction>({
      type: LISTING_LOADING,
    });

    const currency = getCurrency(getState());
    const safeQueryParams = filterSafeQuery({ ...params });
    const endpoint = `external-campgrounds/${rentalId}`;

    return new Promise((resolve, reject) =>
      searchApiRequest<ISearchCampgroundsById>(endpoint, {
        currency,
        rejectOnError: true,
        queryParams: safeQueryParams,
      })
        .then(response => {
          if (!response?.data) {
            throw new Error('404');
          }

          dispatch(setCampgroundListing(response.data));

          return resolve(response.data);
        })
        .catch(error => {
          logger.captureExceptionWithDatadog(error, { url: error.request?._currentUrl });

          return reject(error);
        }),
    );
  };

export interface IState {
  data: ICampgroundData | null;
  isLoading: boolean;
}

export const initialState: IState = {
  data: null,
  isLoading: true,
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case LISTING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LISTING:
      setODNEventProperties(action.payload);
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return { ...state };
  }
}
