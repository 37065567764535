import * as BookingsActions from '@/redux/modules/bookings/type';
import type { IBooking } from '@/services/types/booking/details';

interface IBookingsState {
  bookings: Record<string, IBookingData | undefined>;
}

export interface IBookingData {
  data: IBooking[] | null;
  isFetching: boolean;
  error: boolean;
}

export const initialState: IBookingsState = {
  bookings: {},
};

export default function reducer(
  state: IBookingsState = initialState,
  action: BookingsActions.IBookingsAction,
): IBookingsState {
  switch (action.type) {
    case BookingsActions.FETCH_BOOKINGS_FAILURE:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.filter]: {
            data: [...(state.bookings[action.payload.filter]?.data || [])],
            isFetching: false,
            error: true,
          },
        },
      };

    case BookingsActions.FETCH_BOOKINGS_PENDING:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.filter]: {
            data: state.bookings[action.payload.filter]
              ? [...(state.bookings[action.payload.filter]?.data || [])]
              : [],
            isFetching: true,
            error: false,
          },
        },
      };
    case BookingsActions.FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.filter]: {
            data: action.payload.bookings,
            isFetching: false,
            error: false,
          },
        },
      };
    default:
      return state;
  }
}
