import { TIconName } from '@outdoorsyco/bonfire';
import { defineMessage, MessageDescriptor, useIntl } from 'react-intl';

import { CABIN, CAMPER, CANVAS_TENT } from '@/components/switchback/Icon/assets';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import { IconType } from '@/components/switchback/Icon/IconComponent';

import {
  AMENITIES_FOR_CAMPGROUND_ICONS,
  AMENITIES_FOR_CAMPGROUNDS_KEYS,
  AMENITIES_FOR_CAMPSITES_ICONS,
  AMENITIES_FOR_CAMPSITES_KEYS,
  AMENITIES_FOR_LODGING_CAMPSITES_KEYS,
  AMENITIES_FOR_RV_CAMPSITES_KEYS,
  AMENITIES_FOR_STAY_KEYS,
  AMENITIES_FOR_TENT_CAMPSITES_KEYS,
  AMENITIES_KEYS,
} from './amenities';
import { ELocationDetails } from './locationDetails';
import { TRulesFilters } from './moreFilter';
import { ESearchFilters } from './searchFilters';

export enum EAmenityGroup {
  COOKING = 'cooking',
  STAY_COOKING = 'stay_cooking',
  TEMPERATURE = 'temperature',
  BATHROOM = 'bathroom',
  STAY_BATHROOM = 'stay_bathroom',
  OTHER = 'other',
  OUTDOOR_SPACE = 'outdoor_space',
  SLEEP = 'sleep',
  OFFICE_AND_ENTERTAINMENT = 'office_and_entertainment',
  SAFETY = 'safety',
  ACCESSIBILITY = 'accessibility',
  CAMPGROUND_UTILITY = 'campground_utility',
  CAMPGROUND_FOOD_AND_BEVERAGE = 'campground_food_and_beverage',
  CAMPGROUND_SERVICES = 'campground_services',
  CAMPGROUND_ENTERTAINMENT = 'campground_entertainment',
  CAMPGROUND_SLEEP = 'campground_sleep',
  CAMPGROUND_COOKING = 'campground_cooking',
  CAMPGROUND_BATHROOM = 'campground_bathroom',
  CAMPGROUND_OUTDOOR_SPACE = 'campground_outdoor_space',
  CAMPGROUND_OFFICE_AND_ENTERTAINMENT = 'campground_office_and_entertainment',
  CAMPGROUND_TEMPERATURE = 'campground_temperature',
  CAMPGROUND_SAFETY = 'campground_safety',
  CAMPGROUND_ACCESSIBILITY = 'campground_accessibility',
  CAMPGROUND_OCCUPANCY = 'campground_occupancy',
  CAMPGROUND_ON_SITE = 'campground_on_site',
  CAMPGROUND_HOOK_UPS = 'campground_hook_ups',
  CAMPGROUND_PARKING = 'campground_parking',
}

export type TAmenitySlug =
  | AMENITIES_KEYS
  | AMENITIES_FOR_STAY_KEYS
  | AMENITIES_FOR_CAMPGROUNDS_KEYS
  | AMENITIES_FOR_RV_CAMPSITES_KEYS
  | AMENITIES_FOR_TENT_CAMPSITES_KEYS
  | AMENITIES_FOR_LODGING_CAMPSITES_KEYS
  | ELocationDetails
  | TRulesFilters
  | 'water_tank'
  | 'accessible';

interface TAmenityFilter {
  icon?: ICONS;
  bonfireIcon?: TIconName;
  label: string;
  slug: TAmenitySlug;
  helpText?: string;
}

export interface IAmenityGroup {
  title: string;
  items: TAmenityFilter[];
}

export enum ECampsiteType {
  RV_SITE = 'rv_site',
  TENT_SITE = 'tent_site',
  LODGING_SITE = 'lodging_site',
  STATIONARY_CAMPER_SITE = 'stationary_camper_site',
}

export const campsiteCategoryTypes = [
  ECampsiteType.RV_SITE,
  ECampsiteType.TENT_SITE,
  ECampsiteType.LODGING_SITE,
];

interface ICampsiteCategoryContent {
  icon: IconType;
  label: MessageDescriptor;
  description?: MessageDescriptor;
}

export const campsiteCategoryData: Record<ECampsiteType, ICampsiteCategoryContent> = {
  rv_site: {
    icon: CAMPER,
    label: defineMessage({ defaultMessage: 'RV sites' }),
    description: defineMessage({
      defaultMessage: 'An open site in a campground that allows RVs.',
    }),
  },
  tent_site: {
    icon: CANVAS_TENT,
    label: defineMessage({ defaultMessage: 'Tent sites' }),
    description: defineMessage({
      defaultMessage: 'A place to set up your tent in a campground.',
    }),
  },
  lodging_site: {
    icon: CABIN,
    label: defineMessage({ defaultMessage: 'Lodging' }),
    description: defineMessage({
      defaultMessage: 'A cabin or other lodging site within a campground.',
    }),
  },
  stationary_camper_site: {
    icon: CAMPER,
    label: defineMessage({ defaultMessage: 'Stationary Campers' }),
    description: defineMessage({
      defaultMessage: 'A stationary camper on an RV site within a campground.',
    }),
  },
};

type CampsiteAmenitiesFeaturesMap = Record<
  ECampsiteType,
  Partial<Record<EAmenityGroup, IAmenityGroup>>
>;

export const campsiteTypeToFeaturesFilterMap: Record<
  ECampsiteType,
  | ESearchFilters.CAMPSITE_RV_SITE_FEATURES
  | ESearchFilters.CAMPSITE_TENT_SITE_FEATURES
  | ESearchFilters.CAMPSITE_LODGING_FEATURES
  | ESearchFilters.CAMPSITE_STATIONARY_CAMPER_SITE_FEATURES
> = {
  [ECampsiteType.RV_SITE]: ESearchFilters.CAMPSITE_RV_SITE_FEATURES,
  [ECampsiteType.TENT_SITE]: ESearchFilters.CAMPSITE_TENT_SITE_FEATURES,
  [ECampsiteType.LODGING_SITE]: ESearchFilters.CAMPSITE_LODGING_FEATURES,
  [ECampsiteType.STATIONARY_CAMPER_SITE]: ESearchFilters.CAMPSITE_STATIONARY_CAMPER_SITE_FEATURES,
};

export const parseCampsiteCategoryType = (str?: string): ECampsiteType | undefined => {
  if (str === ECampsiteType.RV_SITE) {
    return str;
  } else if (str === ECampsiteType.TENT_SITE) {
    return str;
  } else if (str === ECampsiteType.LODGING_SITE) {
    return str;
  }
};

export const parseCampsiteCategoryTypes = (strOrArr?: string | string[]): ECampsiteType[] => {
  const result: ECampsiteType[] = [];
  if (!strOrArr) return result;
  let arr = strOrArr;
  if (typeof arr === 'string') {
    arr = arr.split(',').map(k => k.trim());
  }
  arr.forEach(k => {
    const category = parseCampsiteCategoryType(k);
    if (category) result.push(category);
  });
  return result;
};

export const useAmenitiesFilterList = (isStay = false) => {
  const intl = useIntl();
  const stayAmenities: Partial<Record<EAmenityGroup, IAmenityGroup>> = {
    [EAmenityGroup.STAY_COOKING]: {
      title: intl.formatMessage({
        description: 'search/filters: cooking amenities header',
        defaultMessage: 'Cooking',
      }),
      items: [
        {
          icon: ICONS.POTABLE_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity potable water',
            defaultMessage: 'Potable water',
          }),
          slug: 'potable_water',
        },
        {
          icon: ICONS.REFRIGERATOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity refrigerator',
            defaultMessage: 'Refrigerator',
          }),
          slug: 'refrigerator',
        },
        {
          icon: ICONS.MICROWAVE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity microwave',
            defaultMessage: 'Microwave',
          }),
          slug: 'microwave',
        },
        {
          icon: ICONS.OVEN_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity oven',
            defaultMessage: 'Oven',
          }),
          slug: 'oven',
        },
        {
          icon: ICONS.STOVE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity stove',
            defaultMessage: 'Stove',
          }),
          slug: 'stove',
        },
        {
          icon: ICONS.KITCHEN_SINK_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity kitchen sink',
            defaultMessage: 'Kitchen sink',
          }),
          slug: 'kitchen_sink',
        },
        {
          icon: ICONS.DINING_TABLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dining table',
          }),
          slug: 'dining_table',
        },
        {
          icon: ICONS.COFFEE_MAKER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity coffee maker',
            defaultMessage: 'Coffee maker',
          }),
          slug: 'coffee_maker',
        },
        {
          icon: ICONS.COOKING_BASICS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity cooking basics',
            defaultMessage: 'Cooking basics',
          }),
          slug: 'cooking_basics',
        },
        {
          icon: ICONS.POTS_AND_PANS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pots and pans',
            defaultMessage: 'Pots and pans',
          }),
          slug: 'pots_and_pans',
        },
        {
          icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dishes and utensils',
          }),
          slug: 'dishes_and_utensils',
        },
      ],
    },
    [EAmenityGroup.TEMPERATURE]: {
      title: intl.formatMessage({
        description: 'search/filters: temperature amenities header',
        defaultMessage: 'Temperature',
      }),
      items: [
        {
          icon: ICONS.AIR_CONDITIONING_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity air conditioner',
            defaultMessage: 'Air conditioning',
          }),
          slug: 'air_conditioning',
        },
        {
          icon: ICONS.HEATING_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity heating',
            defaultMessage: 'Heating',
          }),
          slug: 'heating',
        },
        {
          icon: ICONS.CEILING_FAN_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Ceiling fan',
          }),
          slug: 'ceiling_fan',
        },
        {
          icon: ICONS.INDOOR_FIREPLACE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Indoor fireplace',
          }),
          slug: 'indoor_fireplace',
        },
      ],
    },
    [EAmenityGroup.STAY_BATHROOM]: {
      title: intl.formatMessage({
        description: 'search/filters: bathroom amenities header',
        defaultMessage: 'Bathroom',
      }),
      items: [
        {
          icon: ICONS.INDOOR_SHOWER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity inside shower',
            defaultMessage: 'Indoor shower',
          }),
          slug: 'indoor_shower',
        },
        {
          icon: ICONS.OUTDOOR_SHOWER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity outside shower',
            defaultMessage: 'Outdoor shower',
          }),
          slug: 'outdoor_shower',
        },
        {
          icon: ICONS.FLUSH_TOILET_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity flush toilet',
            defaultMessage: 'Flush toilet',
          }),
          slug: 'flush_toilet',
        },
        {
          icon: ICONS.COMPOST_TOILET_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity compost toilet',
            defaultMessage: 'Compost toilet',
          }),
          slug: 'compost_toilet',
        },
        {
          icon: ICONS.BATH_TOWELS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity bath towels',
            defaultMessage: 'Bath towels',
          }),
          slug: 'bath_towels',
        },
        {
          icon: ICONS.TOILETRIES_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toiletries',
            defaultMessage: 'Toiletries',
          }),
          slug: 'toiletries',
        },
        {
          icon: ICONS.HOT_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot water',
            defaultMessage: 'Hot water',
          }),
          slug: 'hot_water',
        },
      ],
    },
    [EAmenityGroup.OUTDOOR_SPACE]: {
      title: intl.formatMessage({
        description: 'search/filters: outdoor space amenities header',
        defaultMessage: 'Outdoor space',
      }),
      items: [
        {
          icon: ICONS.FIRE_PIT_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity fire pit',
            defaultMessage: 'Fire pit',
          }),
          slug: 'fire_pit',
        },
        {
          icon: ICONS.BBQ_GRILL_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity grill',
            defaultMessage: 'Grill',
          }),
          slug: 'bbq_grill',
        },
        {
          icon: ICONS.HOT_TUB_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot tub',
            defaultMessage: 'Hot tub',
          }),
          slug: 'hot_tub',
        },
        {
          icon: ICONS.OUTDOOR_FURNITURE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity outdoor furniture',
            defaultMessage: 'Outdoor furniture',
          }),
          slug: 'outdoor_furniture',
        },
        {
          icon: ICONS.POOL_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pool',
            defaultMessage: 'Pool',
          }),
          slug: 'pool',
        },
        {
          icon: ICONS.PICNIC_TABLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity picnic table',
            defaultMessage: 'Picnic table',
          }),
          slug: 'picnic_table',
        },
        {
          icon: ICONS.PATIO_BALCONY_DECK_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity patio, deck, or balcony',
            defaultMessage: 'Patio, deck, or balcony',
          }),
          slug: 'patio_balcony_deck',
        },
      ],
    },
    [EAmenityGroup.SLEEP]: {
      title: intl.formatMessage({
        description: 'search/filters: sleep amenities header',
        defaultMessage: 'Sleep',
      }),
      items: [
        {
          icon: ICONS.BLANKETS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity blankets',
            defaultMessage: 'Blankets',
          }),
          slug: 'blankets',
        },
        {
          icon: ICONS.FRESH_LINENS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity linens',
            defaultMessage: 'Linens',
          }),
          slug: 'fresh_linens',
        },
        {
          icon: ICONS.PILLOWS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pillows',
            defaultMessage: 'Pillows',
          }),
          slug: 'pillows',
        },
      ],
    },
    [EAmenityGroup.OFFICE_AND_ENTERTAINMENT]: {
      title: intl.formatMessage({
        description: 'search/filters: office and entertainment amenities header',
        defaultMessage: 'Office and entertainment',
      }),
      items: [
        {
          icon: ICONS.TV_DVD_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity tv',
            defaultMessage: 'TV',
          }),
          slug: 'tv_dvd',
        },
        {
          icon: ICONS.WIFI_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity wi-fi',
            defaultMessage: 'Wi-fi',
          }),
          slug: 'wifi',
        },
        {
          icon: ICONS.WORKSPACE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity workspace',
            defaultMessage: 'Workspace',
          }),
          slug: 'workspace',
        },
      ],
    },
    [EAmenityGroup.SAFETY]: {
      title: intl.formatMessage({
        description: 'search/filters: safety amenities header',
        defaultMessage: 'Safety',
      }),
      items: [
        {
          icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity co detector',
            defaultMessage: 'CO detector',
          }),
          slug: 'carbon_monoxide_detector',
        },
        {
          icon: ICONS.SMOKE_DETECTOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity smoke detector',
            defaultMessage: 'Smoke detector',
          }),
          slug: 'smoke_detector',
        },
        {
          icon: ICONS.FIRE_EXTINGUISHER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity fire extinguisher',
            defaultMessage: 'Fire extinguisher',
          }),
          slug: 'fire_extinguisher',
        },
        {
          icon: ICONS.FIRST_AID_KIT_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity first-aid kit',
            defaultMessage: 'First-aid kit',
          }),
          slug: 'first_aid_kit',
        },
      ],
    },
    [EAmenityGroup.ACCESSIBILITY]: {
      title: intl.formatMessage({
        description: 'search/filters: accessibility amenities header',
        defaultMessage: 'Accessibility',
      }),
      items: [
        {
          icon: ICONS.ADA_ACCESSIBLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ada accessible',
            defaultMessage: 'ADA accessible',
          }),
          // aliased to ada_accessible in odc-search, this allows the nav search header
          // to be synced with this amenity for stays while still working for RVs
          slug: 'accessible',
        },
        {
          icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity wheelchair accessible',
            defaultMessage: 'Wheelchair accessible',
          }),
          slug: 'wheelchair_accessible',
        },
      ],
    },
  };

  const vehicleAmenities: Partial<Record<EAmenityGroup, IAmenityGroup>> = {
    [EAmenityGroup.COOKING]: {
      title: intl.formatMessage({
        description: 'search/filters: cooking amenities header',
        defaultMessage: 'Cooking',
      }),
      items: [
        {
          icon: ICONS.FRIDGE,
          label: intl.formatMessage({
            description: 'search/filters: amenity refrigerator',
            defaultMessage: 'Refrigerator',
          }),
          slug: 'refrigerator',
        },
        {
          icon: ICONS.MICROWAVE,
          label: intl.formatMessage({
            description: 'search/filters: amenity microwave',
            defaultMessage: 'Microwave',
          }),
          slug: 'microwave',
        },
        {
          icon: ICONS.OVEN,
          label: intl.formatMessage({
            description: 'search/filters: amenity oven',
            defaultMessage: 'Oven',
          }),
          slug: 'oven',
        },
        {
          icon: ICONS.STOVE,
          label: intl.formatMessage({
            description: 'search/filters: amenity stove',
            defaultMessage: 'Stove',
          }),
          slug: 'stove',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity stove help text',
            defaultMessage: 'Cook your favorite meals on this compact propane fueled stove',
          }),
        },
        {
          icon: ICONS.KITCHEN_SINK,
          label: intl.formatMessage({
            description: 'search/filters: amenity kitchen sink',
            defaultMessage: 'Kitchen sink',
          }),
          slug: 'kitchen_sink',
        },
        {
          icon: ICONS.TABLE,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dining table',
          }),
          slug: 'dining_table',
        },
        {
          icon: ICONS.POTABLE_WATER_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Potable water',
            description: 'search/filters: amenity potable water',
          }),
          slug: 'potable_water',
        },
        {
          icon: ICONS.COFFEE_MAKER_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Coffee maker',
            description: 'search/filters: amenity coffee maker',
          }),
          slug: 'coffee_maker',
        },
        {
          icon: ICONS.POTS_AND_PANS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Pots and pans',
            description: 'search/filters: amenity pots and pans',
          }),
          slug: 'pots_and_pans',
        },
        {
          icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Dishes and utensils',
            description: 'search/filters: amenity dishes and utensils',
          }),
          slug: 'dishes_and_utensils',
        },
        {
          icon: ICONS.COOKING_BASICS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Cooking basics',
            description: 'search/filters: amenity cooking basics',
          }),
          slug: 'cooking_basics',
        },
      ],
    },
    [EAmenityGroup.TEMPERATURE]: {
      title: intl.formatMessage({
        description: 'search/filters: temperature amenities header',
        defaultMessage: 'Temperature',
      }),
      items: [
        {
          icon: ICONS.AIR_CONDITIONER,
          label: intl.formatMessage({
            description: 'search/filters: amenity air conditioner',
            defaultMessage: 'Air conditioning',
          }),
          slug: 'air_conditioner',
        },
        {
          icon: ICONS.HEATER,
          label: intl.formatMessage({
            description: 'search/filters: amenity heater',
            defaultMessage: 'Heater',
          }),
          slug: 'heater',
        },
        {
          icon: ICONS.CEILING_FAN,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Ceiling fan',
          }),
          slug: 'ceiling_fan',
        },
      ],
    },
    [EAmenityGroup.BATHROOM]: {
      title: intl.formatMessage({
        description: 'search/filters: bathroom amenities header',
        defaultMessage: 'Bathroom',
      }),
      items: [
        {
          icon: ICONS.SHOWER_INSIDE,
          label: intl.formatMessage({
            description: 'search/filters: amenity inside shower',
            defaultMessage: 'Indoor shower',
          }),
          slug: 'inside_shower',
        },
        {
          icon: ICONS.SHOWER_OUTSIDE,
          label: intl.formatMessage({
            description: 'search/filters: amenity outside shower',
            defaultMessage: 'Outdoor shower',
          }),
          slug: 'outside_shower',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity outside shower help text',
            defaultMessage: 'Rinse with fresh water outside of the RV',
          }),
        },
        {
          icon: ICONS.TOILET,
          label: intl.formatMessage({
            description: 'search/filters: amenity toilet',
            defaultMessage: 'Toilet',
          }),
          slug: 'toilet',
        },
        {
          icon: ICONS.BATH_TOWELS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity bath towels',
            defaultMessage: 'Bath towels',
          }),
          slug: 'bath_towels',
        },
        {
          icon: ICONS.KITCHEN_SINK,
          label: intl.formatMessage({
            description: 'search/filters: amenity bathroom sink',
            defaultMessage: 'Bathroom sink',
          }),
          slug: 'bathroom_sink',
        },
        {
          icon: ICONS.TOILET_PAPER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toilet paper',
            defaultMessage: 'Toilet paper',
          }),
          slug: 'toilet_paper',
        },
        {
          icon: ICONS.TOILETRIES_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toiletries',
            defaultMessage: 'Toiletries',
          }),
          slug: 'toiletries',
        },
        {
          icon: ICONS.HOT_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot water',
            defaultMessage: 'Hot water',
          }),
          slug: 'hot_water',
        },
      ],
    },
    [EAmenityGroup.OTHER]: {
      title: intl.formatMessage({
        description: 'search/filters: other amenities header',
        defaultMessage: 'Other',
      }),
      items: [
        {
          icon: ICONS.GENERATOR,
          label: intl.formatMessage({
            description: 'search/filters: amenity generator',
            defaultMessage: 'Generator',
          }),
          slug: 'generator',
        },
        {
          icon: ICONS.INVERTER,
          label: intl.formatMessage({
            description: 'search/filters: amenity inverter',
            defaultMessage: 'Inverter',
          }),
          slug: 'inverter',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity inverter help text',
            defaultMessage: 'Power the RV batteries and charge your devices',
          }),
        },
        {
          icon: ICONS.FRESH_WATER,
          label: intl.formatMessage({
            description: 'search/filters: amenity water tank',
            defaultMessage: 'Water tank',
          }),
          slug: 'water_tank',
        },
        {
          icon: ICONS.LEVELING_JACK,
          label: intl.formatMessage({
            description: 'search/filters: amenity leveling jacks',
            defaultMessage: 'Leveling jacks',
          }),
          slug: 'leveling_jacks',
        },
        {
          icon: ICONS.WIFI,
          label: intl.formatMessage({
            description: 'search/filters: amenity wifi',
            defaultMessage: 'Wi-Fi',
          }),
          slug: 'wifi',
        },
        {
          icon: ICONS.AUDIO_INPUTS,
          label: intl.formatMessage({
            description: 'search/filters: amenity audio inputs',
            defaultMessage: 'Audio inputs',
          }),
          slug: 'audio_inputs',
        },
        {
          icon: ICONS.AWNING,
          label: intl.formatMessage({
            description: 'search/filters: amenity awning',
            defaultMessage: 'Awning',
          }),
          slug: 'awning',
        },
        {
          icon: ICONS.BIKE_RACK,
          label: intl.formatMessage({
            description: 'search/filters: amenity bike rack',
            defaultMessage: 'Bike rack',
          }),
          slug: 'bike_rack',
        },
        {
          icon: ICONS.SOLAR,
          label: intl.formatMessage({
            description: 'search/filters: amenity solar',
            defaultMessage: 'Solar panel',
          }),
          slug: 'solar',
        },
        {
          icon: ICONS.TV,
          label: intl.formatMessage({
            description: 'search/filters: amenity tv dvd',
            defaultMessage: 'TV / DVD',
          }),
          slug: 'tv_dvd',
        },
        {
          icon: ICONS.WASHER_DRYER,
          label: intl.formatMessage({
            description: 'search/filters: amenity washer dryer',
            defaultMessage: 'Washer / Dryer',
          }),
          slug: 'washer_dryer',
        },
        {
          bonfireIcon: 'Amenities.Waterfuel.Watertank',
          label: intl.formatMessage({
            description: 'search/filters: amenity water hookup',
            defaultMessage: 'Water hookup',
          }),
          slug: 'hookup_water',
        },
        {
          bonfireIcon: 'Amenities.Electricity.Power',
          label: intl.formatMessage({
            description: 'search/filters: amenity electric hookup',
            defaultMessage: 'Electric hookup',
          }),
          slug: 'hookup_electric',
        },
        {
          bonfireIcon: 'Amenities.Waterfuel.SewageTank',
          label: intl.formatMessage({
            description: 'search/filters: amenity sewer hookup',
            defaultMessage: 'Sewer hookup',
          }),
          slug: 'hookup_sewer',
        },
      ],
    },
  };

  if (isStay) {
    return stayAmenities;
  }
  return vehicleAmenities;
};

export const useCampsiteAmenitiesFiltersList = (): CampsiteAmenitiesFeaturesMap => {
  const intl = useIntl();
  const campsiteAmenities: CampsiteAmenitiesFeaturesMap = {
    [ECampsiteType.RV_SITE]: {
      [EAmenityGroup.CAMPGROUND_OCCUPANCY]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities occupancy header',
          defaultMessage: 'Occupancy',
        }),
        items: [],
      },
      [EAmenityGroup.CAMPGROUND_ON_SITE]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities on site header',
          defaultMessage: 'On site',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity picnic table',
              defaultMessage: 'Picnic table',
            }),
            slug: 'picnic_table',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity fire pit',
              defaultMessage: 'Fire pit',
            }),
            slug: 'fire_pit',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity firewood',
              defaultMessage: 'Firewood',
            }),
            slug: 'firewood',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity grill',
              defaultMessage: 'Grill',
            }),
            slug: 'grill',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity trash bins',
              defaultMessage: 'Trash bins',
            }),
            slug: 'trash_bins',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity ada accessible',
              defaultMessage: 'Ada accessible',
            }),
            slug: 'ada_accessible',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wheelchair accessible',
              defaultMessage: 'Wheelchair accessible',
            }),
            slug: 'wheelchair_accessible',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_HOOK_UPS]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities hook-ups header',
          defaultMessage: 'Hook-ups',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity full hookups',
              defaultMessage: 'Full hookups',
            }),
            slug: 'full_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 20 amp',
              defaultMessage: '20 amp',
            }),
            slug: '20_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 30 amp',
              defaultMessage: '30 amp',
            }),
            slug: '30_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 50 amp',
              defaultMessage: '50 amp',
            }),
            slug: '50_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 100 amp',
              defaultMessage: '100 amp',
            }),
            slug: '100_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 110 amp',
              defaultMessage: '110 amp',
            }),
            slug: '110_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity electricity hookups',
              defaultMessage: 'Electricity hookups',
            }),
            slug: 'electricity_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity water hookups',
              defaultMessage: 'Water hookups',
            }),
            slug: 'water_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity hot water',
              defaultMessage: 'Hot water',
            }),
            slug: 'hot_water',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity potable water',
              defaultMessage: 'Potable water',
            }),
            slug: 'potable_water',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity sewer hookups',
              defaultMessage: 'Sewer hookups',
            }),
            slug: 'sewer_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wifi',
              defaultMessage: 'Wifi',
            }),
            slug: 'wifi',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_PARKING]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities parking header',
          defaultMessage: 'Parking',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity grass terrain',
              defaultMessage: 'Grass terrain',
            }),
            slug: 'grass_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity field terrain',
              defaultMessage: 'Field terrain',
            }),
            slug: 'field_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity dirt terrain',
              defaultMessage: 'Dirt terrain',
            }),
            slug: 'dirt_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity gravel terrain',
              defaultMessage: 'Gravel terrain',
            }),
            slug: 'gravel_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity concrete terrain',
              defaultMessage: 'Concrete terrain',
            }),
            slug: 'concrete_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pull through',
              defaultMessage: 'Pull through',
            }),
            slug: 'pull_through',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity back in',
              defaultMessage: 'Back in',
            }),
            slug: 'back_in',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pull in',
              defaultMessage: 'Pull in',
            }),
            slug: 'pull_in',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity covered rv spot',
              defaultMessage: 'Covered rv spot',
            }),
            slug: 'covered_rv_spot',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity size area',
              defaultMessage: 'Size area',
            }),
            slug: 'size_area',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 4wd recommended',
              defaultMessage: '4wd recommended',
            }),
            slug: '4wd_recommended',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 2wd recommended',
              defaultMessage: '2wd recommended',
            }),
            slug: '2wd_recommended',
          },
        ],
      },
    },
    [ECampsiteType.TENT_SITE]: {
      [EAmenityGroup.CAMPGROUND_OCCUPANCY]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities occupancy header',
          defaultMessage: 'Occupancy',
        }),
        items: [],
      },
      [EAmenityGroup.CAMPGROUND_ON_SITE]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities on site header',
          defaultMessage: 'On site',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity picnic table',
              defaultMessage: 'Picnic table',
            }),
            slug: 'picnic_table',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity fire pit',
              defaultMessage: 'Fire pit',
            }),
            slug: 'fire_pit',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity firewood',
              defaultMessage: 'Firewood',
            }),
            slug: 'firewood',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity grill',
              defaultMessage: 'Grill',
            }),
            slug: 'grill',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity trash bins',
              defaultMessage: 'Trash bins',
            }),
            slug: 'trash_bins',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity ada accessible',
              defaultMessage: 'Ada accessible',
            }),
            slug: 'ada_accessible',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wheelchair accessible',
              defaultMessage: 'Wheelchair accessible',
            }),
            slug: 'wheelchair_accessible',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_HOOK_UPS]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities hook-ups header',
          defaultMessage: 'Hook-ups',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity full hookups',
              defaultMessage: 'Full hookups',
            }),
            slug: 'full_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 20 amp',
              defaultMessage: '20 amp',
            }),
            slug: '20_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 30 amp',
              defaultMessage: '30 amp',
            }),
            slug: '30_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 50 amp',
              defaultMessage: '50 amp',
            }),
            slug: '50_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 100 amp',
              defaultMessage: '100 amp',
            }),
            slug: '100_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 110 amp',
              defaultMessage: '110 amp',
            }),
            slug: '110_amp',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity electricity hookups',
              defaultMessage: 'Electricity hookups',
            }),
            slug: 'electricity_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity water hookups',
              defaultMessage: 'Water hookups',
            }),
            slug: 'water_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity hot water',
              defaultMessage: 'Hot water',
            }),
            slug: 'hot_water',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity potable water',
              defaultMessage: 'Potable water',
            }),
            slug: 'potable_water',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity sewer hookups',
              defaultMessage: 'Sewer hookups',
            }),
            slug: 'sewer_hookups',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wifi',
              defaultMessage: 'Wifi',
            }),
            slug: 'wifi',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_PARKING]: {
        title: intl.formatMessage({
          description: 'search/filters: RV site amenities parking header',
          defaultMessage: 'Parking',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity grass terrain',
              defaultMessage: 'Grass terrain',
            }),
            slug: 'grass_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity field terrain',
              defaultMessage: 'Field terrain',
            }),
            slug: 'field_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity dirt terrain',
              defaultMessage: 'Dirt terrain',
            }),
            slug: 'dirt_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity gravel terrain',
              defaultMessage: 'Gravel terrain',
            }),
            slug: 'gravel_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity concrete terrain',
              defaultMessage: 'Concrete terrain',
            }),
            slug: 'concrete_terrain',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pull through',
              defaultMessage: 'Pull through',
            }),
            slug: 'pull_through',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity back in',
              defaultMessage: 'Back in',
            }),
            slug: 'back_in',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pull in',
              defaultMessage: 'Pull in',
            }),
            slug: 'pull_in',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity covered rv spot',
              defaultMessage: 'Covered rv spot',
            }),
            slug: 'covered_rv_spot',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity size area',
              defaultMessage: 'Size area',
            }),
            slug: 'size_area',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 4wd recommended',
              defaultMessage: '4wd recommended',
            }),
            slug: '4wd_recommended',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity 2wd recommended',
              defaultMessage: '2wd recommended',
            }),
            slug: '2wd_recommended',
          },
        ],
      },
    },
    [ECampsiteType.LODGING_SITE]: {
      [EAmenityGroup.CAMPGROUND_SLEEP]: {
        title: intl.formatMessage({
          description: 'search/filters: sleep amenities header',
          defaultMessage: 'Sleep',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity blankets',
              defaultMessage: 'Blankets',
            }),
            slug: 'blankets',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity linens',
              defaultMessage: 'Linens',
            }),
            slug: 'fresh_linens',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pillows',
              defaultMessage: 'Pillows',
            }),
            slug: 'pillows',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_COOKING]: {
        title: intl.formatMessage({
          description: 'search/filters: cooking amenities header',
          defaultMessage: 'Cooking',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity potable water',
              defaultMessage: 'Potable water',
            }),
            slug: 'potable_water',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity refrigerator',
              defaultMessage: 'Refrigerator',
            }),
            slug: 'refrigerator',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity microwave',
              defaultMessage: 'Microwave',
            }),
            slug: 'microwave',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity oven',
              defaultMessage: 'Oven',
            }),
            slug: 'oven',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity stove',
              defaultMessage: 'Stove',
            }),
            slug: 'stove',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity kitchen sink',
              defaultMessage: 'Kitchen sink',
            }),
            slug: 'kitchen_sink',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity dining table',
              defaultMessage: 'Dining table',
            }),
            slug: 'dining_table',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity coffee maker',
              defaultMessage: 'Coffee maker',
            }),
            slug: 'coffee_maker',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity cooking basics',
              defaultMessage: 'Cooking basics',
            }),
            slug: 'cooking_basics',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pots and pans',
              defaultMessage: 'Pots and pans',
            }),
            slug: 'pots_and_pans',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity dining table',
              defaultMessage: 'Dishes and utensils',
            }),
            slug: 'dishes_and_utensils',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_BATHROOM]: {
        title: intl.formatMessage({
          description: 'search/filters: bathroom amenities header',
          defaultMessage: 'Bathroom',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity inside shower',
              defaultMessage: 'Indoor shower',
            }),
            slug: 'indoor_shower',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity outside shower',
              defaultMessage: 'Outdoor shower',
            }),
            slug: 'outdoor_shower',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity flush toilet',
              defaultMessage: 'Flush toilet',
            }),
            slug: 'flush_toilet',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity compost toilet',
              defaultMessage: 'Compost toilet',
            }),
            slug: 'compost_toilet',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity bath towels',
              defaultMessage: 'Bath towels',
            }),
            slug: 'bath_towels',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity toiletries',
              defaultMessage: 'Toiletries',
            }),
            slug: 'toiletries',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity hot water',
              defaultMessage: 'Hot water',
            }),
            slug: 'hot_water',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_OUTDOOR_SPACE]: {
        title: intl.formatMessage({
          description: 'search/filters: outdoor space amenities header',
          defaultMessage: 'Outdoor space',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity fire pit',
              defaultMessage: 'Fire pit',
            }),
            slug: 'fire_pit',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity grill',
              defaultMessage: 'Grill',
            }),
            slug: 'bbq_grill',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity hot tub',
              defaultMessage: 'Hot tub',
            }),
            slug: 'hot_tub',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity outdoor furniture',
              defaultMessage: 'Outdoor furniture',
            }),
            slug: 'outdoor_furniture',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity pool',
              defaultMessage: 'Pool',
            }),
            slug: 'pool',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity picnic table',
              defaultMessage: 'Picnic table',
            }),
            slug: 'picnic_table',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity patio, deck, or balcony',
              defaultMessage: 'Patio, deck, or balcony',
            }),
            slug: 'patio_balcony_deck',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_OFFICE_AND_ENTERTAINMENT]: {
        title: intl.formatMessage({
          description: 'search/filters: office and entertainment amenities header',
          defaultMessage: 'Office and entertainment',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity tv',
              defaultMessage: 'TV',
            }),
            slug: 'tv_dvd',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wi-fi',
              defaultMessage: 'Wi-fi',
            }),
            slug: 'wifi',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity workspace',
              defaultMessage: 'Workspace',
            }),
            slug: 'workspace',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_TEMPERATURE]: {
        title: intl.formatMessage({
          description: 'search/filters: temperature amenities header',
          defaultMessage: 'Temperature',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity air conditioner',
              defaultMessage: 'Air conditioning',
            }),
            slug: 'air_conditioning',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity heating',
              defaultMessage: 'Heating',
            }),
            slug: 'heating',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity ceiling fan',
              defaultMessage: 'Ceiling fan',
            }),
            slug: 'ceiling_fan',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity ceiling fan',
              defaultMessage: 'Indoor fireplace',
            }),
            slug: 'indoor_fireplace',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_SAFETY]: {
        title: intl.formatMessage({
          description: 'search/filters: safety amenities header',
          defaultMessage: 'Safety',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity co detector',
              defaultMessage: 'CO detector',
            }),
            slug: 'carbon_monoxide_detector',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity smoke detector',
              defaultMessage: 'Smoke detector',
            }),
            slug: 'smoke_detector',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity fire extinguisher',
              defaultMessage: 'Fire extinguisher',
            }),
            slug: 'fire_extinguisher',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity first-aid kit',
              defaultMessage: 'First-aid kit',
            }),
            slug: 'first_aid_kit',
          },
        ],
      },
      [EAmenityGroup.CAMPGROUND_ACCESSIBILITY]: {
        title: intl.formatMessage({
          description: 'search/filters: accessibility amenities header',
          defaultMessage: 'Accessibility',
        }),
        items: [
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity ada accessible',
              defaultMessage: 'ADA accessible',
            }),
            slug: 'ada_accessible',
          },
          {
            label: intl.formatMessage({
              description: 'search/filters: amenity wheelchair accessible',
              defaultMessage: 'Wheelchair accessible',
            }),
            slug: 'wheelchair_accessible',
          },
        ],
      },
    },
    [ECampsiteType.STATIONARY_CAMPER_SITE]: {},
  };

  // Mutate the object and include icons/bonfire icons
  Object.keys(campsiteAmenities).forEach(typeKey => {
    const campsiteAmenitiesPerType = campsiteAmenities[typeKey as ECampsiteType];

    Object.keys(campsiteAmenitiesPerType).forEach(groupKey => {
      campsiteAmenitiesPerType[groupKey as EAmenityGroup]?.items.forEach(item => {
        const icon = AMENITIES_FOR_CAMPSITES_ICONS[item.slug as AMENITIES_FOR_CAMPSITES_KEYS];
        item.icon = icon?.icon || undefined;
        item.bonfireIcon = icon?.bonfireIcon || undefined;
      });
    });
  });

  return campsiteAmenities;
};

export const useCampgroundsFeaturesFiltersList = () => {
  const intl = useIntl();
  const campgroundAmenities: Partial<Record<EAmenityGroup, IAmenityGroup>> = {
    [EAmenityGroup.CAMPGROUND_UTILITY]: {
      title: intl.formatMessage({
        description: 'search/filters: campground utility amenities header',
        defaultMessage: 'Utility',
      }),
      items: [
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity onsite showers',
            defaultMessage: 'Onsite showers',
          }),
          slug: 'onsite_showers',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity onsite bathrooms',
            defaultMessage: 'Onsite bathrooms',
          }),
          slug: 'onsite_bathrooms',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community laundry',
            defaultMessage: 'Community laundry',
          }),
          slug: 'community_laundry',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity propane fills',
            defaultMessage: 'Propane fills',
          }),
          slug: 'propane_fills',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity ev charging',
            defaultMessage: 'Ev charging',
          }),
          slug: 'ev_charging',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity trash bins',
            defaultMessage: 'Trash bins',
          }),
          slug: 'trash_bins',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity dump station',
            defaultMessage: 'Dump station',
          }),
          slug: 'dump_station',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity grey water discharge',
            defaultMessage: 'Grey water discharge',
          }),
          slug: 'grey_water_discharge',
        },
      ],
    },
    [EAmenityGroup.CAMPGROUND_FOOD_AND_BEVERAGE]: {
      title: intl.formatMessage({
        description: 'search/filters: campground food & beverage amenities header',
        defaultMessage: 'Food & Beverage',
      }),
      items: [
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity restaurants on site',
            defaultMessage: 'Restaurants on site',
          }),
          slug: 'restaurants_on_site',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity snack bar',
            defaultMessage: 'Snack bar',
          }),
          slug: 'snack_bar',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity clubhouse',
            defaultMessage: 'Clubhouse',
          }),
          slug: 'clubhouse',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community bbq',
            defaultMessage: 'Community bbq',
          }),
          slug: 'community_bbq',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity general store',
            defaultMessage: 'General store',
          }),
          slug: 'general_store',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community picnic tables',
            defaultMessage: 'Community picnic tables',
          }),
          slug: 'community_picnic_tables',
        },
      ],
    },
    [EAmenityGroup.CAMPGROUND_SERVICES]: {
      title: intl.formatMessage({
        description: 'search/filters: campground services amenities header',
        defaultMessage: 'Services',
      }),
      items: [
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity personal mailboxes',
            defaultMessage: 'Personal mailboxes',
          }),
          slug: 'personal_mailboxes',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity business center',
            defaultMessage: 'Business center',
          }),
          slug: 'business_center',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity library',
            defaultMessage: 'Library',
          }),
          slug: 'library',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity event center',
            defaultMessage: 'Event center',
          }),
          slug: 'event_center',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity rv storage',
            defaultMessage: 'Rv storage',
          }),
          slug: 'rv_storage',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity bike rentals',
            defaultMessage: 'Bike rentals',
          }),
          slug: 'bike_rentals',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity boat rentals',
            defaultMessage: 'Boat rentals',
          }),
          slug: 'boat_rentals',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity gated entrance',
            defaultMessage: 'Gated entrance',
          }),
          slug: 'gated_entrance',
        },
      ],
    },
    [EAmenityGroup.CAMPGROUND_ENTERTAINMENT]: {
      title: intl.formatMessage({
        description: 'search/filters: campground entertainment header',
        defaultMessage: 'Entertainment',
      }),
      items: [
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community fire pit',
            defaultMessage: 'Community fire pit',
          }),
          slug: 'community_fire_pit',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community swimming pool',
            defaultMessage: 'Community swimming pool',
          }),
          slug: 'community_swimming_pool',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity community hot tub',
            defaultMessage: 'Community hot tub',
          }),
          slug: 'community_hot_tub',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity playground',
            defaultMessage: 'Playground',
          }),
          slug: 'playground',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity recreation center',
            defaultMessage: 'Recreation center',
          }),
          slug: 'recreation_center',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity dog park',
            defaultMessage: 'Dog park',
          }),
          slug: 'dog_park',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity beach',
            defaultMessage: 'Beach',
          }),
          slug: 'beach',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity docks access',
            defaultMessage: 'Docks access',
          }),
          slug: 'docks_access',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity mini golf',
            defaultMessage: 'Mini golf',
          }),
          slug: 'mini_golf',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity golf course',
            defaultMessage: 'Golf course',
          }),
          slug: 'golf_course',
        },
        {
          label: intl.formatMessage({
            description: 'search/filters: amenity basketball court',
            defaultMessage: 'Basketball court',
          }),
          slug: 'basketball_court',
        },
      ],
    },
  };

  // Mutate the object and include icons/bonfire icons
  Object.keys(campgroundAmenities).forEach(key => {
    campgroundAmenities[key as EAmenityGroup]?.items.forEach(item => {
      const icon = AMENITIES_FOR_CAMPGROUND_ICONS[item.slug as AMENITIES_FOR_CAMPGROUNDS_KEYS];
      item.icon = icon?.icon || undefined;
      item.bonfireIcon = icon?.bonfireIcon || undefined;
    });
  });

  return campgroundAmenities;
};
