import React from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { getHasAppInstalled } from '@/redux/selectors/auth/user';

import SmartBanner from './SmartBanner';

const ALLOWED_ROUTES = [
  { pathname: '/locality', source: 'srp' },
  { pathname: '/rv-rental', source: 'listing-page' },
  { pathname: '/stay-rental', source: 'listing-page' },
  { pathname: '/campground-rental', source: 'listing-page' },
  { pathname: '/campsite-rental', source: 'listing-page' },
  { pathname: '/rv-search', source: 'rv-search' },
  { pathname: '/trips/[slug]', source: 'trip-details' },
];
const SmartBannerContainer: React.FC = () => {
  const router = useRouter();

  const hasAppInstalled = useSelector(getHasAppInstalled);

  const allowedRoute =
    router.pathname === '/'
      ? { pathname: '/', source: 'home-page' }
      : ALLOWED_ROUTES.find(({ pathname }) => router.pathname.includes(pathname));

  if (!allowedRoute) return null;

  return <SmartBanner source={allowedRoute.source} hasAppInstalled={hasAppInstalled} />;
};

export default SmartBannerContainer;
