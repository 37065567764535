import { IntlShape, useIntl } from 'react-intl';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { IOption } from '@/components/switchback/Select';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';

const SEARCH_LOCATION_POPULAR_DESTINATIONS: IAutocompleteOption[] = [
  {
    label: 'Great Smoky Mountains National Park, TN',
  },
  {
    label: 'Yosemite National Park, CA',
  },
  {
    label: 'Rocky Mountain National Park, CO',
  },
  {
    label: 'Zion National Park, UT',
  },
  {
    label: 'Grand Canyon National Park, AZ',
  },
];

const SEARCH_LOCATION_POPULAR_DESTINATIONS_CA: IAutocompleteOption[] = [
  {
    label: 'Banff National Park, Alberta',
  },
  {
    label: 'Jasper National Park, Alberta',
  },
  {
    label: 'Saguenay-St. Lawrence, Québec',
  },
  {
    label: 'Pacific Rim National Park, British Columbia',
  },
  {
    label: 'Mount Revelstoke National Park, British Columbia',
  },
];

const SEARCH_LOCATION_POPULAR_DESTINATIONS_NZ: IAutocompleteOption[] = [
  {
    label: 'Coromandel Peninsula',
  },
  {
    label: 'Tongariro National Park',
  },
  {
    label: 'Abel Tasman National Park',
  },
  {
    label: 'Egmont National Park',
  },
  {
    label: 'Nelson Lakes National Park',
  },
  {
    label: 'Hanmer Springs',
  },
  {
    label: 'Wilson Bay',
  },
];

export const useSearchLocationPopularDestinations = () => {
  const { locale } = useLocaleSettings();

  if (locale === 'en-ca' || locale === 'fr-ca') {
    return SEARCH_LOCATION_POPULAR_DESTINATIONS_CA;
  }

  if (locale === 'en-nz') {
    return SEARCH_LOCATION_POPULAR_DESTINATIONS_NZ;
  }

  return SEARCH_LOCATION_POPULAR_DESTINATIONS;
};

export enum SORT_OPTION {
  RECOMMENDED = 'recommended',
  PRICE_HIGH_TO_LOW = 'price-high-to-low',
  PRICE_LOW_TO_HIGH = 'price-low-to-high',
  NEW_LISTINGS = 'new-listings',
  AVERAGE_RATINGS = 'average-ratings',
  CLOSEST_FIRST = 'closest-first',
  TOP_RATED = 'top-rated',
}

export const useOptimizelySortOptions = () => {
  const intl = useIntl();

  return getOptimizelySortOptions(intl);
};

const getOptimizelySortOptions = (intl: IntlShape) => {
  const OPTIMIZELY_SORT_OPTIONS: Record<SORT_OPTION, IOption> = {
    [SORT_OPTION.RECOMMENDED]: {
      label: intl.formatMessage({
        defaultMessage: 'Recommended',
        description: 'Search page sort option: default sort',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Recommended',
        description: 'Search page sort option: default sort',
      }),
      value: '',
    },
    [SORT_OPTION.PRICE_HIGH_TO_LOW]: {
      label: intl.formatMessage({
        defaultMessage: 'Price: High to low',
        description: 'Search page sort option: price descending',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Price: High to low',
        description: 'Search page sort option: price descending',
      }),
      value: '-price',
    },
    [SORT_OPTION.PRICE_LOW_TO_HIGH]: {
      label: intl.formatMessage({
        defaultMessage: 'Price: Low to high',
        description: 'Search page sort option: price ascending',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Price: Low to high',
        description: 'Search page sort option: price ascending',
      }),
      value: 'price',
    },
    [SORT_OPTION.NEW_LISTINGS]: {
      label: intl.formatMessage({
        defaultMessage: 'New listings',
        description: 'Search page sort option: new listings',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'New listings',
        description: 'Search page sort option: new listings',
      }),
      value: 'new_listings',
    },
    [SORT_OPTION.AVERAGE_RATINGS]: {
      label: intl.formatMessage({
        defaultMessage: 'Average ratings',
        description: 'Search page sort option: average rating descending',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Average ratings',
        description: 'Search page sort option: average rating descending',
      }),
      value: '-rating',
    },
    [SORT_OPTION.CLOSEST_FIRST]: {
      label: intl.formatMessage({
        defaultMessage: 'Distance: Closest first',
        description: 'Search page sort option: closest first descending',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Closest first',
        description: 'Search page sort option: closest first short descending',
      }),
      value: 'distance',
    },
    [SORT_OPTION.TOP_RATED]: {
      label: intl.formatMessage({
        defaultMessage: 'Top rated',
        description: 'Search page sort option: top rated',
      }),
      shortLabel: intl.formatMessage({
        defaultMessage: 'Top rated',
        description: 'Search page sort option: top rated short',
      }),
      value: '-num_reviews,-rating',
    },
  };

  return OPTIMIZELY_SORT_OPTIONS;
};
