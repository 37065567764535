import { Button, EButtonColorVariant, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import IconApp from '@/assets/mobile/outdoorsy-app-icon.png';
import { CLOSE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';

interface IBanner {
  title: string;
  description: string;
  onClose: () => void;
  onClickDownload: () => void;
}

const Banner: React.FC<IBanner> = ({ title, onClose, onClickDownload, description }) => {
  const intl = useIntl();
  const downloadText = intl.formatMessage({ defaultMessage: 'Get' });

  return (
    <div className="flex items-center justify-between w-full p-4 text-white bg-green-800">
      <div className="flex items-center gap-2">
        <Link onClick={onClose} className="flex">
          <Icon name={CLOSE} width={18} height={18} className="text-white" />
        </Link>

        <Image src={IconApp} alt="outdoorsy-icon-app" width={48} height={48} />

        <div>
          <h3 className="tracking-wide text-600 highlight leading-5">{title}</h3>
          <Text variant={ETextStyleVariant.SmallRegular}>{description}</Text>
        </div>
      </div>

      <Button
        label={downloadText}
        onClick={onClickDownload}
        variant={EButtonColorVariant.Primary}
      />
    </div>
  );
};

export default Banner;
