import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import { IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';

import classes from '../DesktopUniversalSearch.module.css';
import GuestDetail from './GuestDetail';
import RequirementDetail from './RequirementDetail';

interface IDetailsContentProps {
  open: boolean;
  guests?: Partial<IHeaderSearchFilterGuests>;
  onChangeGuests?: (guests: IHeaderSearchFilterGuests) => void;
  onConfirmGuests?: (guests: IHeaderSearchFilterGuests) => void;
  onSubmit?: () => void;
  hidePetFriendly?: boolean;
  isGuestOccupancyAvailable?: boolean;
}

const DetailsContent: React.FC<IDetailsContentProps> = ({
  open,
  guests,
  onChangeGuests,
  onSubmit,
  hidePetFriendly = false,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const adultsValue = guests?.adults || 0;
  const childrenValue = guests?.children || 0;
  const infantsValue = guests?.infants || 0;
  const petFriendlyValue = guests?.pet_friendly || false;
  const accessibleValue = guests?.accessible || false;

  const handleChangeFilters = useCallback(
    (newValue: Partial<IHeaderSearchFilterGuests>) => {
      onChangeGuests?.({
        adults: adultsValue,
        children: childrenValue,
        pet_friendly: petFriendlyValue,
        ...(isGuestOccupancyAvailable && { infants: infantsValue }),
        accessible: accessibleValue,
        ...newValue,
      });
    },
    [
      onChangeGuests,
      adultsValue,
      childrenValue,
      petFriendlyValue,
      isGuestOccupancyAvailable,
      infantsValue,
      accessibleValue,
    ],
  );

  const handleChangeAdults = (value: number) => {
    handleChangeFilters({ adults: value });
  };
  const handleChangeChildren = (value: number) => {
    handleChangeFilters({ children: value });
  };
  const handleChangeInfants = (value: number) => {
    handleChangeFilters({ infants: value });
  };
  const handleChangePetFriendly = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.checked;
    handleChangeFilters({ pet_friendly: value });
  };
  const handleChangeAccessible = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.checked;
    handleChangeFilters({ accessible: value });
  };

  const handleReset = () => {
    onChangeGuests?.({
      adults: 0,
      children: 0,
      ...(isGuestOccupancyAvailable && { infants: 0 }),
      pet_friendly: false,
      accessible: false,
    });
  };

  const detailChanged =
    guests?.adults ||
    guests?.children ||
    guests?.accessible ||
    guests?.pet_friendly ||
    (isGuestOccupancyAvailable && guests?.infants);

  return (
    <div className={`p-0 md:p-4 ${open ? 'block' : 'hidden'} ${classes.homeDetailsWrapper}`}>
      <div className="md:flex">
        <GuestDetail
          adultsValue={adultsValue}
          childrenValue={childrenValue}
          infantsValue={infantsValue}
          onChangeAdults={handleChangeAdults}
          onChangeChildren={handleChangeChildren}
          onChangeInfants={handleChangeInfants}
          isGuestOccupancyAvailable={isGuestOccupancyAvailable}
        />
        <Divider className="my-3 md:hidden" />
        <RequirementDetail
          petFriendlyValue={petFriendlyValue}
          accessibleValue={accessibleValue}
          onChangePetFriendly={handleChangePetFriendly}
          onChangeAccessible={handleChangeAccessible}
          hidePetFriendly={hidePetFriendly}
          isGuestOccupancyAvailable={isGuestOccupancyAvailable}
        />
      </div>
      {!!detailChanged && (
        <>
          <Divider className="hidden mt-3 mb-2.5 md:block" />
          <div className="items-center justify-end hidden md:flex">
            <button
              type="button"
              className="flex items-center text-sm text-gray-800 underline border-none underline-offset-2 hover:no-underline semiHighlight"
              onClick={handleReset}>
              <FormattedMessage defaultMessage="Reset" description="Guests filter > reset button" />
            </button>
          </div>
        </>
      )}
      <Button
        className="mt-6 md:!hidden"
        variant={EButtonColorVariant.Primary}
        label={intl.formatMessage({ defaultMessage: 'Confirm' })}
        fullWidth
        onClick={onSubmit}
      />
    </div>
  );
};

export default DetailsContent;
