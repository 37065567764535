import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import searchApiRequest from '@/services/searchApiRequest';
import { IAction } from '@/utility/redux/action';

const SET_EDUCATION = 'education/SET_EDUCATION';
const FETCH_EDUCATION_SUCCESS = 'education/FETCH_EDUCATION_SUCCESS';
const FETCH_EDUCATION_FAILURE = 'education/FETCH_EDUCATION_FAILURE';

export enum EducationContentType {
  CAMPGROUND = 'campground',
  LISTING = 'listing',
  INSTRUCTION = 'instruction', // temporal type
  ALL = '',
}

export enum RentalType {
  TOWABLE_AND_DRIVABLE = 'towable-and-drivable',
  TOWABLE = 'towable',
}

export interface IEducation {
  id: string;
  title: string;
  description: string;
  content_type: EducationContentType;
  document?: string;
  embed_url?: string;
  feature?: string;
  image?: string;
  image_link?: string;
  rental_type?: RentalType;
  addBooking?: boolean;
}

interface IPayload {
  data: IEducation[];
  contentType: EducationContentType;
}

interface ISetEducationAction extends IAction {
  type: typeof SET_EDUCATION;
  payload: IPayload;
}

interface IFetchEducationSuccessAction extends IAction {
  type: typeof FETCH_EDUCATION_SUCCESS;
  payload: IPayload;
}

interface IFetchEducationFailureAction extends IAction {
  type: typeof FETCH_EDUCATION_FAILURE;
  payload: AxiosError;
}

type TAction = ISetEducationAction | IFetchEducationSuccessAction | IFetchEducationFailureAction;

const fetchEducationSuccess = (payload: IPayload): IFetchEducationSuccessAction => ({
  type: FETCH_EDUCATION_SUCCESS,
  payload,
});

const fetchEducationFailure = (payload: AxiosError): IFetchEducationFailureAction => ({
  type: FETCH_EDUCATION_FAILURE,
  payload,
});

export const getEducation =
  ({
    contentType = EducationContentType.ALL,
    rentalType,
  }: {
    contentType: EducationContentType;
    rentalType?: RentalType;
  }): ThunkAction<
    Promise<void | null>,
    TRootState,
    void,
    IFetchEducationSuccessAction | IFetchEducationFailureAction
  > =>
  async dispatch => {
    try {
      // TODO: replace the fake data with the getPageData service once the information is in the API

      const fakeInstructionsData: Record<string, IEducation[]> = {
        [EducationContentType.INSTRUCTION]: [
          {
            id: '1',
            content_type: EducationContentType.INSTRUCTION,
            title: 'Find the perfect RV',
            description: `
            <p>
              Choose your location, dates, and send the host a request to book.
            </p>
          `,
            addBooking: false,
          },
          {
            id: '2',
            content_type: EducationContentType.INSTRUCTION,
            title: 'Delivery or Pickup',
            description: `
          <p>
            Arrange a pick up time with the host or have it delivered to your driveway or destination.
          </p>
          <p>
            <b class="steplist_subtitle">
              Let us help
            </b>
            <p>
              Most hosts have the option to deliver and set up the RV right to your destination.
            </p>
          </p>
        `,
            addBooking: true,
          },
          {
            id: '3',
            content_type: EducationContentType.INSTRUCTION,
            title: 'Adventure awaits!',
            description: `
          <p>
            Enjoy the freedom of the open road nd the assurance of 24/7 roadside assistance.
          </p>
          <p>
            After your trip, return the RV to the host in the same condition you recieved it.
          </p>
          `,
            addBooking: false,
          },
        ],
      };

      let responseData;
      let data: IEducation[];
      const queryParams: Record<string, string> = { content_type: contentType };
      if (rentalType) queryParams.rental_type = rentalType;

      if (contentType === EducationContentType.INSTRUCTION) {
        responseData = await searchApiRequest<any>('content', {
          queryParams: {
            filter: 'metadata.page_type=how-it-works&filter=metadata.topic_name=how_od_works_campg',
          },
          rejectOnError: true,
        });
        data = responseData?.[0]?.metadata?.steps ?? fakeInstructionsData[contentType];
      } else {
        responseData = await searchApiRequest<any>('education', {
          queryParams,
          rejectOnError: true,
        });
        data = responseData ?? [];
      }

      dispatch(
        fetchEducationSuccess({
          data,
          contentType,
        }),
      );
    } catch (error) {
      dispatch(fetchEducationFailure(error));
    }
  };

type IState = Record<string, IEducation[]> & {
  error?: AxiosError | null;
};

export const initialState: IState = { error: null } as IState;

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_EDUCATION:
      return {
        ...state,
        [action.payload.contentType]: [...action.payload.data],
        error: state.error,
      } as IState;
    case FETCH_EDUCATION_SUCCESS:
      return {
        ...state,
        [action.payload.contentType]: [...action.payload.data],
        error: null,
      } as IState;
    case FETCH_EDUCATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      } as IState;
    default:
      return state;
  }
}
