import { createSelector } from 'reselect';

import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import { TFlexibleDateRange } from '@/services/analytics/search/types';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { IDeliveryQueryParams, ILocation } from '@/services/types/core/quotes';
import { getParamAsString } from '@/utility/queryParams';

export type TQueryParams = TRootState['queryParams'];

export const getQueryParams = (state: TRootState) => state.queryParams;

export const getFromAndTo = createSelector<
  TRootState,
  TQueryParams,
  { from?: string; to?: string }
>(
  state => state.queryParams,
  ({ [ESearchFilters.DATE_FROM]: fromSearch, [ESearchFilters.DATE_TO]: toSearch, from, to }) => {
    if (from || to) {
      return {
        from: getParamAsString(from),
        to: getParamAsString(to),
      };
    }

    return {
      from: getParamAsString(fromSearch),
      to: getParamAsString(toSearch),
    };
  },
);

function isFlexibleDate(x: string | null): x is TFlexibleDateRange {
  if (['1', '3', 'exact', null].includes(x)) return true;
  return false;
}

export const getFlexibleDateRangeSelected = createSelector<
  TRootState,
  TQueryParams,
  TFlexibleDateRange
>(
  state => state.queryParams,
  ({
    [ESearchFilters.DATE_FROM]: from,
    [ESearchFilters.DATE_TO]: to,
    [ESearchFilters.FLEXIBLE_DAYS]: flexible_days,
  }) => {
    return parseFlexibleDateRange(from, to, flexible_days);
  },
);

export const parseFlexibleDateRange = (
  from: string | string[] | undefined,
  to: string | string[] | undefined,
  flexible_days: string | string[] | undefined,
): TFlexibleDateRange => {
  let flexibleDateRange;
  const hasDates = from && to;
  if (!hasDates) {
    /**
     * If we dont have dates we default to null
     */
    flexibleDateRange = null;
  } else if (!flexible_days) {
    /**
     * We do have dates but the user didn't select the flexible days
     */
    flexibleDateRange = 'exact';
  } else {
    flexibleDateRange = getParamAsString(flexible_days);
  }

  return flexibleDateRange && isFlexibleDate(flexibleDateRange) ? flexibleDateRange : null;
};

export const getGuestsForOccupancy = createSelector<
  TRootState,
  TQueryParams,
  { adults?: number; children?: number; infants?: number }
>(
  state => state.queryParams,
  ({
    [ESearchFilters.SLEEPS_ADULTS]: adults,
    [ESearchFilters.SLEEPS_CHILDREN]: children,
    [ESearchFilters.SLEEPS_INFANTS]: infants,
  }) => {
    const guestsAdults = Number(adults);
    const guestsChildren = Number(children);
    const guestsInfants = Number(infants);

    if ((guestsChildren || guestsInfants) && !guestsAdults) {
      // always require at least 1 adult for occupancy with children/infants
      return {
        adults: 1,
        children: guestsChildren,
        infants: guestsInfants,
      };
    }

    // use user-values for other cases
    // or default adults to 2 (2 adults is typically included in the base rate price)
    return {
      adults: Number(adults) || 2,
      children: Number(children) || 0,
      infants: Number(infants) || 0,
    };
  },
);

export const getDeliveryFromQuery = createSelector<
  TRootState,
  TQueryParams,
  IDeliveryQueryParams | null
>(
  state => state.queryParams,
  ({
    [ESearchFilters.DELIVERY]: delivery,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationary,
    [ESearchFilters.DELIVERY_CENTER]: deliveryCenter,
    [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddress,
    [ESearchFilters.DELIVERY_QUERY]: deliveryQuery,
    [ESearchFilters.DELIVERY_DETAILS]: deliveryDetails,
  }) => {
    // not enough delivery data in query params
    if (!delivery || !deliveryCenter || !deliveryDetails || delivery !== 'true') {
      return null;
    }

    try {
      const parsedDeliveryCenter = JSON.parse(deliveryCenter as string);
      const parsedDeliveryDetails = JSON.parse(
        decodeURIComponent(deliveryDetails as string),
      ) as ILocation;

      // location details are invalid
      if (
        !Array.isArray(parsedDeliveryCenter) ||
        parsedDeliveryCenter.length !== 2 ||
        !parsedDeliveryDetails ||
        // !parsedDeliveryDetails?.city ||
        !parsedDeliveryDetails?.country ||
        !parsedDeliveryDetails?.state
      ) {
        return null;
      }

      const location = {
        lng: Number(parsedDeliveryCenter[0]),
        lat: Number(parsedDeliveryCenter[1]),
      };

      return {
        delivery: delivery === 'true',
        deliveryStationary: (deliveryStationary as EDeliveryOption) || EDeliveryOption.MOVING,
        deliveryCenter: location,
        deliveryAddress: (deliveryAddress as string) || (deliveryQuery as string) || '',
        deliveryDetails: parsedDeliveryDetails,
      };
    } catch {
      // Could not parse query params
      return null;
    }
  },
);
