export function guardedJsonParse<T = unknown>(
  guardFn: (o: unknown) => o is T,
  ...parseArgs: Parameters<JSON['parse']>
): T | null {
  try {
    const parsedValue = JSON.parse(...parseArgs);

    if (guardFn(parsedValue)) {
      return parsedValue;
    }

    return null;
  } catch (e) {
    return null;
  }
}
