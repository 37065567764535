import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import downloadIOSAppImage from '@/assets/footer/appstore.png';
import downloadAndroidAppImage from '@/assets/footer/googleplay.png';
import { triggerQualtrics } from '@/components/qualtrics/qualtric.service';
import Anchor from '@/components/switchback/Anchor/Anchor';
import { locales } from '@/config/locales';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { useSessionCurrency } from '@/hooks/useSessionCurrency';
import { updateUser } from '@/redux/modules/auth/userProfile';
import { triggerChat } from '@/utility/chat';
import { isCurrency } from '@/utility/currency';
import { isLocale } from '@/utility/i18n';
import { logger } from '@/utility/logger';

import styles from './Footer.module.css';
import FooterDropDown, { IOption as IFooterDropDownOption } from './FooterDropDown';
import FooterListItem from './FooterListItem';
import FacebookIcon from './icons/facebook.svg';
import InstagramIcon from './icons/instagram.svg';
import LinkedInIcon from './icons/linkedIn.svg';
import PinterestIcon from './icons/pinterest.svg';
import YouTubeIcon from './icons/youtube.svg';
import { currencies } from './utils/currencies';

type FooterProps = {
  extraPaddingBottom?: string;
};

const localeOptions = Object.values(locales).map(({ name, language, locale }) => ({
  label: `${name} (${language})`,
  value: locale,
}));

const currencyOptions = currencies.map(({ code }) => ({
  label: code,
  value: code,
}));

const Footer: React.FC<FooterProps> = ({ extraPaddingBottom }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // locale drop down functions / variables
  const { locale: selectedLocale } = useLocaleSettings();

  const updateLocale = async ({ value }: IFooterDropDownOption) => {
    if (typeof value === 'string' && isLocale(value)) {
      try {
        await dispatch(
          updateUser({
            locale: {
              language: value,
            },
          }),
        );
        if (process.env.NODE_ENV === 'production') {
          window.location.href =
            window.location.protocol +
            '//' +
            locales[value].domain +
            window.location.pathname +
            window.location.search;
        } else {
          window.location.reload();
        }
      } catch (error) {
        logger.warn('Could not change locale.');
      }
    }
  };

  // currency drop down functions / variables
  const [selectedCurrency, updateCurrency] = useSessionCurrency();

  const changeCurrency = async ({ value }: IFooterDropDownOption) => {
    if (typeof value === 'string' && isCurrency(value)) {
      updateCurrency(value);
    }
  };
  // Footer columns and link labels
  // Labels found underneath Outdoorsy, Inc copyright label
  const weAreHereForGood = intl.formatMessage({ defaultMessage: 'We are here for good ✨' });
  const linkLabelTerms = intl.formatMessage({ defaultMessage: 'Terms' });
  const linkLabelPrivacy = intl.formatMessage({ defaultMessage: 'Privacy' });
  const linkLabelSiteMap = intl.formatMessage({ defaultMessage: 'Sitemap' });
  // Download Outdoorsy app
  const downloadOutdoorsyAppTitle = intl.formatMessage({
    defaultMessage: 'Download Outdoorsy app',
  });
  const linkLabelIOSApp = intl.formatMessage({ defaultMessage: 'iOS app' });
  const linkLabelAndroidApp = intl.formatMessage({ defaultMessage: 'Android app' });
  // Outdoorsy Column
  const columnTitleOutdoorsy = intl.formatMessage({ defaultMessage: 'Outdoorsy' });
  const linkLabelWhereItAllBegan = intl.formatMessage({ defaultMessage: 'Where it all began' });
  const linkLabelAbout = intl.formatMessage({ defaultMessage: 'About' });
  const linkLabelCareers = intl.formatMessage({ defaultMessage: 'Careers' });
  const linkLabelStoriesAndNews = intl.formatMessage({ defaultMessage: 'Stories and News' });
  const linkLabelTravelJournal = intl.formatMessage({ defaultMessage: 'Travel journal' });
  // Guest Travel Column
  const columnTitleGuestTravel = intl.formatMessage({ defaultMessage: 'Guest travel' });
  const linkLabelDelivery = intl.formatMessage({ defaultMessage: 'Delivery' });
  const linkLabelNationalParkGuides = intl.formatMessage({
    defaultMessage: 'National Park guides',
  });
  const linkLabelEventGuides = intl.formatMessage({ defaultMessage: 'Event guides' });
  const linkLabelRoadTripGuides = intl.formatMessage({ defaultMessage: 'Road trip guides' });
  const linkLabelParkRVParksAndCampgrounds = intl.formatMessage({
    defaultMessage: 'RV parks & campgrounds',
  });
  const linkLabelGuideToAllRVTypes = intl.formatMessage({
    defaultMessage: 'Guide to all RV types',
  });
  // Hosting Column
  const columnTitleHosting = intl.formatMessage({ defaultMessage: 'Hosting' });
  const linkLabelListRV = intl.formatMessage({ defaultMessage: 'Become an RV host' });
  const linkLabelWheelbaseDemo = intl.formatMessage({ defaultMessage: 'Wheelbase Demo' });
  const linkLabelAffiliateProgram = intl.formatMessage({ defaultMessage: 'Affiliate program' });
  const linkLabelRVInsurance = intl.formatMessage({ defaultMessage: 'RV insurance' });
  const linkLabelHostIOSApp = intl.formatMessage({ defaultMessage: 'Host iOS app' });
  const linkLabelHostAndroidApp = intl.formatMessage({ defaultMessage: 'Host Android app' });
  // Support Column
  const columnTitleSupport = intl.formatMessage({ defaultMessage: 'Support' });
  const linkLabelHowItWorks = intl.formatMessage({ defaultMessage: 'How it works' });
  const linkLabelHelpCenter = intl.formatMessage({ defaultMessage: 'Help center' });

  return (
    <div id="footer" className={styles.footer} data-visible="true">
      <div
        style={{ margin: 'auto', maxWidth: '1280px' }}
        className="px-4 py-12"
        data-test-application-layout-footer>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <div className="flex flex-col mb-6">
              <FooterDropDown
                id="locale-trigger"
                onSelect={updateLocale}
                selectedValue={selectedLocale}
                options={localeOptions}
                testingId="locale-dropdown-trigger"
              />
              <FooterDropDown
                id="currency-trigger"
                onSelect={changeCurrency}
                selectedValue={selectedCurrency}
                options={currencyOptions}
                testingId="currency-dropdown-trigger"
              />
            </div>
            <div className="flex mt-6">
              <Anchor
                data-testid="footer-instagram-link"
                href="https://www.instagram.com/outdoorsy/"
                className="transition-all duration-200 hover:scale-110">
                <span className="sr-only">Instagram</span>
                <InstagramIcon className={styles.socialIcon} />
              </Anchor>
              <Anchor
                data-testid="footer-facebook-link"
                href="https://www.facebook.com/GoOutdoorsy/"
                className="ml-6 transition-all duration-200 hover:scale-110">
                <span className="sr-only">Facebook</span>
                <FacebookIcon className={styles.socialIcon} />
              </Anchor>
              <Anchor
                data-testid="footer-youtube-link"
                href="https://www.youtube.com/@OutdoorsyRV"
                className="ml-6 transition-all duration-200 hover:scale-110">
                <span className="sr-only">YouTube</span>
                <YouTubeIcon className={styles.socialIcon} />
              </Anchor>

              <Anchor
                data-testid="footer-pinterest-link"
                href="https://www.pinterest.com/GoOutdoorsy/"
                className="ml-6 transition-all duration-200 hover:scale-110">
                <span className="sr-only">Pinterest</span>
                <PinterestIcon className={styles.socialIcon} />
              </Anchor>
              <Anchor
                data-testid="footer-linkedin-link"
                href="https://www.linkedin.com/company/outdoorsy"
                className="ml-6 transition-all duration-200 hover:scale-110">
                <span className="sr-only">LinkedIn</span>
                <LinkedInIcon className={styles.socialIcon} />
              </Anchor>
            </div>

            <div className="mt-8 text-base text-white">
              <h3 className="text-base font-medium text-white leading-6">
                {downloadOutdoorsyAppTitle}
              </h3>

              <div className="flex mt-2 gap-2">
                <Anchor
                  data-testid="footer-renter-iOS-app-link"
                  target="_blank"
                  href="/apps/guest/ios">
                  <Image src={downloadIOSAppImage} alt={linkLabelIOSApp} width={120} height={35} />
                </Anchor>

                <Anchor
                  data-testid="footer-renter-android-app-link"
                  target="_blank"
                  href="/apps/guest/android">
                  <Image
                    src={downloadAndroidAppImage}
                    alt={linkLabelAndroidApp}
                    width={120}
                    height={35}
                  />
                </Anchor>
              </div>
            </div>
          </div>
          <div
            id="footer-links"
            className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8 xl:mt-0 xl:col-span-2">
            <div>
              <h3 className="text-lg font-medium text-canvas-100 leading-5">
                {columnTitleOutdoorsy}
              </h3>
              <ul>
                <FooterListItem dataTestId="footer-story-link" href="/blog/outdoorsy-founder-story">
                  {linkLabelWhereItAllBegan}
                </FooterListItem>
                <FooterListItem dataTestId="footer-about-link" href="/about">
                  {linkLabelAbout}
                </FooterListItem>
                <FooterListItem dataTestId="footer-jobs-link" href="/jobs">
                  {linkLabelCareers}
                </FooterListItem>
                <FooterListItem dataTestId="footer-press-link" href="/press">
                  {linkLabelStoriesAndNews}
                </FooterListItem>
                <FooterListItem dataTestId="footer-blog-link" href="/blog">
                  {linkLabelTravelJournal}
                </FooterListItem>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium text-canvas-100 leading-5">
                {columnTitleGuestTravel}
              </h3>
              <ul className="mt-4">
                <FooterListItem
                  dataTestId="footer-rv-delivery-link"
                  href="https://www.outdoorsy.com/blog/what-is-rv-rental-delivery">
                  {linkLabelDelivery}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-park-link"
                  href="https://www.outdoorsy.com/guide/parks">
                  {linkLabelNationalParkGuides}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-event-link"
                  href="https://www.outdoorsy.com/guide/events">
                  {linkLabelEventGuides}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-road-trips-link"
                  href="https://www.outdoorsy.com/guide/road-trips">
                  {linkLabelRoadTripGuides}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-rv-parks-link"
                  href="https://www.outdoorsy.com/rv-parks-and-campgrounds">
                  {linkLabelParkRVParksAndCampgrounds}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-rv-types-link"
                  href="https://www.outdoorsy.com/rv-makes-types">
                  {linkLabelGuideToAllRVTypes}
                </FooterListItem>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium text-canvas-100 leading-5">
                {columnTitleHosting}
              </h3>
              <ul className="mt-4">
                <FooterListItem dataTestId="footer-list-rv-link" href="/become-a-host/rv">
                  {linkLabelListRV}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-wheelbase-link"
                  href="https://www.wheelbasepro.com">
                  {linkLabelWheelbaseDemo}
                </FooterListItem>
                <FooterListItem dataTestId="footer-affiliate-link" href="/affiliate">
                  {linkLabelAffiliateProgram}
                </FooterListItem>
                <FooterListItem dataTestId="footer-rv-insurance-link" href="https://www.roamly.com">
                  {linkLabelRVInsurance}
                </FooterListItem>
                <FooterListItem dataTestId="footer-owner-iOS-app-link" href="/apps/host/ios">
                  {linkLabelHostIOSApp}
                </FooterListItem>
                <FooterListItem
                  dataTestId="footer-owner-android-app-link"
                  href="/apps/host/android">
                  {linkLabelHostAndroidApp}
                </FooterListItem>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium text-canvas-100 leading-5">
                {columnTitleSupport}
              </h3>
              <ul className="mt-4">
                <FooterListItem dataTestId="footer-how-it-works-link" href="/how-it-works">
                  {linkLabelHowItWorks}
                </FooterListItem>
                <FooterListItem dataTestId="footer-help-link" href="/help">
                  {linkLabelHelpCenter}
                </FooterListItem>
                <li className="mt-4 truncate border-black">
                  <button
                    data-testid="live-chat-btn"
                    className="text-base text-white leading-6 hover:underline underline-offset-2"
                    onClick={triggerChat}>
                    {intl.formatMessage({
                      defaultMessage: 'Chat, email or call',
                      description: 'Global Footer Item: Chat, email or call button',
                    })}
                  </button>
                </li>

                <li className="mt-4 truncate border-black">
                  <button
                    data-testid="live-chat-btn"
                    className="text-base text-white leading-6 hover:underline underline-offset-2"
                    onClick={triggerQualtrics}>
                    {intl.formatMessage({
                      defaultMessage: 'Give us feedback',
                      description: 'Global Footer Item: Give us feedback button',
                    })}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`bg-canvas-100 ${extraPaddingBottom}`}>
        <div
          style={{ margin: 'auto', maxWidth: '1280px' }}
          className="flex flex-col px-4 py-2 text-sm md:justify-between md:flex-row gap-4">
          <div className="grow">{weAreHereForGood}</div>
          <div className="flex gap-2">
            <Anchor
              data-testid="footer-terms-and-conditions-link"
              className="hover:underline underline-offset-2"
              href="/terms-and-conditions">
              {linkLabelTerms}
            </Anchor>{' '}
            |{' '}
            <Anchor
              data-testid="footer-privacy-policy-link"
              className="hover:underline underline-offset-2"
              href="/privacy-policy">
              {linkLabelPrivacy}
            </Anchor>{' '}
            |{' '}
            <Anchor
              data-testid="footer-sitemap-link"
              className="hover:underline underline-offset-2"
              href="/sitemap">
              {linkLabelSiteMap}
            </Anchor>
          </div>

          <div>© 2024 Outdoorsy, Inc. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
