import queryString from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { getSearchApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const SET_NEARBY_ODN_CAMPGROUND = 'NearbyODNCampground/SET_NEARBY_ODN_CAMPGROUND';
const FETCH_NEARBY_ODN_CAMPGROUND_SUCCESS =
  'NearbyODNCampground/FETCH_NEARBY_ODN_CAMPGROUND_SUCCESS';
const FETCH_NEARBY_ODN_CAMPGROUND_LOAD = 'NearbyODNCampground/FETCH_NEARBY_ODN_CAMPGROUND_LOAD';

interface ISetNearbyODNCampgroundAction extends IAction {
  type: typeof SET_NEARBY_ODN_CAMPGROUND;
  payload: ICampgroundData | null;
}

interface IFetchNearbyODNCampgroundSuccessAction extends IAction {
  type: typeof FETCH_NEARBY_ODN_CAMPGROUND_SUCCESS;
  payload: ICampgroundData | null;
}

interface IFetchNearbyODNCampgroundLoadAction extends IAction {
  type: typeof FETCH_NEARBY_ODN_CAMPGROUND_LOAD;
}

type TAction =
  | ISetNearbyODNCampgroundAction
  | IFetchNearbyODNCampgroundSuccessAction
  | IFetchNearbyODNCampgroundLoadAction;

const fetchNearbyODNCampgroundSuccess = (
  payload: ICampgroundData | null,
): IFetchNearbyODNCampgroundSuccessAction => ({
  type: FETCH_NEARBY_ODN_CAMPGROUND_SUCCESS,
  payload,
});

const fetchNearbyODNCampgroundsLoad = (): IFetchNearbyODNCampgroundLoadAction => ({
  type: FETCH_NEARBY_ODN_CAMPGROUND_LOAD,
});

interface IRawCampgroundData {
  data: ICampgroundData[];
}

export const getNearbyODNCampground =
  (
    lat = 0,
    lng = 0,
    distanceFromLocation = 0,
  ): ThunkAction<
    Promise<IFetchNearbyODNCampgroundSuccessAction>,
    TRootState,
    void,
    IFetchNearbyODNCampgroundSuccessAction | IFetchNearbyODNCampgroundLoadAction
  > =>
  async dispatch => {
    dispatch(fetchNearbyODNCampgroundsLoad());

    const searchApi = getSearchApi();
    const odnSearchFilters = {
      [ESearchFilters.IS_ODN]: true,
      [ESearchFilters.PAGE_LIMIT]: 1,
      [ESearchFilters.CAMPSITE_CATEGORY_TYPES]: 'rv_site',
      [ESearchFilters.NEAR]: `${lat},${lng}`,
      [ESearchFilters.RADIUS]: distanceFromLocation,
      [ESearchFilters.RAW_JSON]: true,
      [ESearchFilters.FULL_DESCRIPTION]: true,
    };

    const querystring = queryString.stringify(odnSearchFilters);
    const requestUrl = `${searchApi}/external-campgrounds?${querystring}`;
    let response: ICampgroundData | null = null;
    try {
      const rawResponse = (await apiRequest<IRawCampgroundData>({ url: requestUrl })) || [];
      if (rawResponse.data?.[0]) {
        response = rawResponse.data[0];
      }
      return dispatch(fetchNearbyODNCampgroundSuccess(response));
    } catch (err) {
      // fine for now
      return dispatch(fetchNearbyODNCampgroundSuccess(response));
    }
  };

interface IState {
  data: ICampgroundData | null;
  isFetched: boolean;
}

export const initialState: IState = {
  data: null,
  isFetched: false,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_NEARBY_ODN_CAMPGROUND:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_NEARBY_ODN_CAMPGROUND_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetched: true,
      };
    case FETCH_NEARBY_ODN_CAMPGROUND_LOAD:
      return {
        ...state,
        isFetched: false,
      };
    default:
      return state;
  }
}
