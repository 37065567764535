import React from 'react';

import Icon, { IconType } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';

import css from './Notice.module.css';

export enum NoticeType {
  info = 'INFO',
  warning = 'WARNING',
  critical = 'CRITICAL',
  notice = 'NOTICE',
}

interface IProps {
  icon?: IconType;
  variant?: NoticeType;
  filled?: boolean;
  size?: string | number;
}

interface IColorMap {
  background: string;
  text: string;
}

const typeColorMap: Record<NoticeType, IColorMap> = {
  INFO: {
    background: 'bg-primary-200',
    text: 'text-green-730',
  },
  WARNING: {
    background: 'bg-sand-30',
    text: 'text-red-800',
  },
  CRITICAL: {
    background: 'bg-red-200',
    text: 'text-red-800',
  },
  NOTICE: {
    background: 'bg-blue-100',
    text: 'text-green-730',
  },
};

const defaultColorMap: IColorMap = {
  background: 'bg-gray-100',
  text: 'text-gray-850',
};

const Notice: React.FC<IProps> = ({ children, icon, variant, filled = false, size = 16 }) => {
  const displayedVariantColorMap = variant ? typeColorMap[variant] : defaultColorMap;
  const iconColorClassName = displayedVariantColorMap.text;
  const containerClassNames =
    filled && `p-4 rounded p-4 rounded ${displayedVariantColorMap.background}`;

  return (
    <div
      className={`flex items-start ${displayedVariantColorMap.text} ${containerClassNames}`}
      role="alert">
      {icon && (
        <div className={`flex justify-center items-center ${css.icon}`}>
          <Icon name={icon} height={size} width={size} className={iconColorClassName} />
        </div>
      )}
      <Text type="inline" className="w-full ml-2 text-current">
        {children}
      </Text>
    </div>
  );
};

export default Notice;
