import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import Text from '@/components/switchback/Text/Text';

import GuestDetailItem from './GuestDetailItem';

interface IGuestDetailProps {
  adultsValue: number;
  childrenValue: number;
  infantsValue: number;
  onChangeAdults: (value: number) => void;
  onChangeChildren: (value: number) => void;
  onChangeInfants: (value: number) => void;
  isGuestOccupancyAvailable?: boolean;
}

const GuestDetail: React.FC<IGuestDetailProps> = ({
  adultsValue,
  childrenValue,
  infantsValue,
  onChangeAdults,
  onChangeChildren,
  onChangeInfants,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const labels = {
    adults: {
      label: intl.formatMessage({
        description: 'Guests filter > Adult QuantitySelector title',
        defaultMessage: 'Adults',
      }),
      disclaimer: intl.formatMessage({
        description: 'Guests filter > Adult QuantitySelector disclaimer',
        defaultMessage: 'Ages 13 and up',
      }),
    },
    children: {
      label: intl.formatMessage({
        description: 'Guests filter > Children QuantitySelector title',
        defaultMessage: 'Children',
      }),
      disclaimer: isGuestOccupancyAvailable
        ? intl.formatMessage({
            description: 'Guests filter > Children QuantitySelector disclaimer',
            defaultMessage: 'Ages 3-12',
          })
        : intl.formatMessage({
            description: 'Guests filter > Children QuantitySelector disclaimer',
            defaultMessage: 'Ages 12 and under',
          }),
    },
    infants: {
      label: intl.formatMessage({
        description: 'Guests filter > Infants QuantitySelector title',
        defaultMessage: 'Infants',
      }),
      disclaimer: intl.formatMessage({
        description: 'Guests filter > Infants QuantitySelector disclaimer',
        defaultMessage: 'Ages 2 or under',
      }),
    },
  };

  return (
    <div className="md:w-3/5">
      {!isGuestOccupancyAvailable && (
        <Text
          type="paragraph"
          className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
          <FormattedMessage defaultMessage="guests" />
        </Text>
      )}
      <GuestDetailItem
        label={labels.adults.label}
        guestsCount={adultsValue}
        onChange={onChangeAdults}
        disclaimer={labels.adults.disclaimer}
      />
      <div className="hidden w-full h-4 md:block" />
      <Divider className="mt-3 mb-4 md:hidden" />
      <GuestDetailItem
        label={labels.children.label}
        guestsCount={childrenValue}
        onChange={onChangeChildren}
        disclaimer={labels.children.disclaimer}
      />

      {isGuestOccupancyAvailable && (
        <>
          <div className="hidden w-full h-4 md:block" />
          <Divider className="mt-3 mb-4 md:hidden" />
          <GuestDetailItem
            label={labels.infants.label}
            guestsCount={infantsValue}
            onChange={onChangeInfants}
            disclaimer={labels.infants.disclaimer}
          />
        </>
      )}
    </div>
  );
};

export default GuestDetail;
