import { createSelector } from 'reselect';

import { campsiteTypeToFeaturesFilterMap, ECampsiteType } from '@/constants/amenitiesFilter';
import { ESearchFilters } from '@/constants/searchFilters';
import { ISearchFormFilters, TState as ISearchFormState } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';

type TSearchFormType = Partial<ISearchFormFilters>[ESearchFilters.FILTER_TYPE];
type TSearchFormTags = Partial<ISearchFormFilters>[ESearchFilters.FILTER_TAGS];
type TSearchFormMake = Partial<ISearchFormFilters>[ESearchFilters.FILTER_VEHICLE_MAKE];
type TSearchFormMakeModels = Partial<ISearchFormFilters>[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS];
type TSearchFormMinMakeYear = Partial<ISearchFormFilters>[ESearchFilters.YEAR_GREATER];
type TSearchFormMaxMakeYear = Partial<ISearchFormFilters>[ESearchFilters.YEAR_LESSER];
type TSearchFormCampsiteSubtypes =
  Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_SUPPORTED_SUBTYPES];
type TSearchFormCampsiteVehicleLength =
  Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_MAX_VEHICLE_LENGTH];
type TSearchFormCampsiteCategoryTypes =
  Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_CATEGORY_TYPES];
type TSearchFormProperty = Partial<ISearchFormFilters>[ESearchFilters.FILTER_PROPERTY];
export type TSearchFormAmenities = Partial<ISearchFormFilters>[ESearchFilters.FILTER_FEATURE];
export type TSearchFormStayParking =
  Partial<ISearchFormFilters>[ESearchFilters.STAY_PARKING_ONSITE];
export type TSearchFormActivities = Partial<ISearchFormFilters>[ESearchFilters.FILTER_FEATURE];
export type TSearchFormScenery = Partial<ISearchFormFilters>[ESearchFilters.FILTER_FEATURE];
interface ISearchFormPrice {
  [ESearchFilters.PRICE_MIN]: Partial<ISearchFormFilters>[ESearchFilters.PRICE_MIN];
  [ESearchFilters.PRICE_MAX]: Partial<ISearchFormFilters>[ESearchFilters.PRICE_MAX];
}
export interface ISearchFormStayParking {
  [ESearchFilters.STAY_PARKING_ONSITE]: Partial<ISearchFormFilters>[ESearchFilters.STAY_PARKING_ONSITE];
}
export interface ISearchFormAccommodations {
  [ESearchFilters.SEATBELTS]: Partial<ISearchFormFilters>[ESearchFilters.SEATBELTS];
  [ESearchFilters.SLEEPS]: Partial<ISearchFormFilters>[ESearchFilters.SLEEPS];
  [ESearchFilters.BATHROOMS]: Partial<ISearchFormFilters>[ESearchFilters.BATHROOMS];
  [ESearchFilters.BEDROOMS]: Partial<ISearchFormFilters>[ESearchFilters.BEDROOMS];
  [ESearchFilters.BEDS]: Partial<ISearchFormFilters>[ESearchFilters.BEDS];
  [ESearchFilters.CAMPSITE_BATHROOMS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BATHROOMS];
  [ESearchFilters.CAMPSITE_BEDROOMS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BEDROOMS];
  [ESearchFilters.CAMPSITE_BEDS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BEDS];
}

interface ISearchDelivery {
  [ESearchFilters.DELIVERY]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY];
  [ESearchFilters.DELIVERY_ADDRESS]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_ADDRESS];
  [ESearchFilters.DELIVERY_RADIUS]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_RADIUS];
  [ESearchFilters.DELIVERY_STATIONARY]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_STATIONARY];
}

type TSearchFormInstantBook = Partial<ISearchFormFilters>[ESearchFilters.INSTANT_BOOK];

export const getSearchFormTags = createSelector<TRootState, ISearchFormState, TSearchFormTags>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_TAGS],
);

export const getSearchFormTypeFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormType
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_TYPE],
);

export const getSearchFormMakeFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMake
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_VEHICLE_MAKE],
);

export const getSearchFormMakeModelsFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMakeModels
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS],
);

export const getSearchFormMinMakeYear = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMinMakeYear
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.YEAR_GREATER],
);

export const getSearchFormMaxMakeYear = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMaxMakeYear
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.YEAR_LESSER],
);

export const getSearchFormCampsiteCategoryTypesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormCampsiteCategoryTypes
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.CAMPSITE_CATEGORY_TYPES],
);

export const getSearchFormCampsiteVehicleLengthFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormCampsiteVehicleLength
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.CAMPSITE_MAX_VEHICLE_LENGTH],
);

export const getSearchFormCampsiteSubtypesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormCampsiteSubtypes
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.CAMPSITE_SUPPORTED_SUBTYPES],
);

export const getSearchFormPropertyFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormProperty
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_PROPERTY],
);

export const getSearchFormPriceFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchFormPrice
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.PRICE_MIN]: searchForm.filters[ESearchFilters.PRICE_MIN],
    [ESearchFilters.PRICE_MAX]: searchForm.filters[ESearchFilters.PRICE_MAX],
  }),
);

export const getSearchFormAccommodationsFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchFormAccommodations
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.SEATBELTS]: searchForm.filters[ESearchFilters.SEATBELTS],
    [ESearchFilters.SLEEPS]: searchForm.filters[ESearchFilters.SLEEPS],
    [ESearchFilters.BATHROOMS]: searchForm.filters[ESearchFilters.BATHROOMS],
    [ESearchFilters.BEDROOMS]: searchForm.filters[ESearchFilters.BEDROOMS],
    [ESearchFilters.BEDS]: searchForm.filters[ESearchFilters.BEDS],
    [ESearchFilters.CAMPSITE_BATHROOMS]: searchForm.filters[ESearchFilters.CAMPSITE_BATHROOMS],
    [ESearchFilters.CAMPSITE_BEDROOMS]: searchForm.filters[ESearchFilters.CAMPSITE_BEDROOMS],
    [ESearchFilters.CAMPSITE_BEDS]: searchForm.filters[ESearchFilters.CAMPSITE_BEDS],
  }),
);

export const getSearchFormAmenitiesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormAmenities
>(
  state => state.searchForm,
  searchForm => {
    return searchForm.filters[ESearchFilters.FILTER_FEATURE];
  },
);

export const getSearchFormCampgroundsAmenitiesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormAmenities
>(
  state => state.searchForm,
  searchForm => {
    return searchForm.filters[ESearchFilters.CAMPGROUND_FEATURES];
  },
);

export const getSearchFormCampsitesAmenitiesFilter = createSelector<
  TRootState,
  ISearchFormState,
  Record<ECampsiteType, TSearchFormAmenities>
>(
  state => state.searchForm,
  searchForm => {
    return {
      [ECampsiteType.RV_SITE]:
        searchForm.filters[campsiteTypeToFeaturesFilterMap[ECampsiteType.RV_SITE]],
      [ECampsiteType.TENT_SITE]:
        searchForm.filters[campsiteTypeToFeaturesFilterMap[ECampsiteType.TENT_SITE]],
      [ECampsiteType.LODGING_SITE]:
        searchForm.filters[campsiteTypeToFeaturesFilterMap[ECampsiteType.LODGING_SITE]],
      [ECampsiteType.STATIONARY_CAMPER_SITE]:
        searchForm.filters[campsiteTypeToFeaturesFilterMap[ECampsiteType.STATIONARY_CAMPER_SITE]],
    };
  },
);

export const getSearchFormStayParkingFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchFormStayParking
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.STAY_PARKING_ONSITE]: searchForm.filters[ESearchFilters.STAY_PARKING_ONSITE],
  }),
);

export const getSearchFormDeliveryFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchDelivery
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.DELIVERY]: searchForm.filters[ESearchFilters.DELIVERY],
    [ESearchFilters.DELIVERY_ADDRESS]: searchForm.filters[ESearchFilters.DELIVERY_ADDRESS],
    [ESearchFilters.DELIVERY_STATIONARY]: searchForm.filters[ESearchFilters.DELIVERY_STATIONARY],
    [ESearchFilters.DELIVERY_RADIUS]: searchForm.filters[ESearchFilters.DELIVERY_RADIUS],
  }),
);

export const getSearchFormInstantBookFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormInstantBook
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.INSTANT_BOOK],
);
