import React from 'react';
import { useIntl } from 'react-intl';

import globalCss from '../DesktopUniversalSearch/DesktopUniversalSearch.module.css';
import { SEARCH_TAB } from './DesktopUniversalSearch';
import classes from './LocationSearchInput.module.css';

interface ILocationSearchInputProps {
  getInputProps: () => Record<string, never>;
  className?: string;
  isTowables: boolean;
  selectedTab: SEARCH_TAB | null;
  isCategoryEnabled?: boolean;
  isStayFilterEnabled?: boolean;
}

export interface IOption {
  label: string;
  groupName: string;
  value?: any;
  url?: string;
}

export interface IGroupOption {
  options: IOption[];
  key: number;
  index: number;
  group: string;
}

const LocationSearchInput: React.FC<ILocationSearchInputProps> = ({
  getInputProps,
  className = '',
  isTowables,
  selectedTab,
  isCategoryEnabled,
  isStayFilterEnabled,
}) => {
  const intl = useIntl();
  const locationPlaceholder = intl.formatMessage({
    defaultMessage: 'Location',
    description: 'Global Header > Placeholder text for header search field',
  });
  const homeLocationPlaceholder = intl.formatMessage({
    defaultMessage: 'Type to search',
    description: 'Global Header > Placeholder text for header search field',
  });
  const noValuePlaceholder = intl.formatMessage({
    defaultMessage: 'Where will your adventure start?',
  });

  const { 'aria-controls': ariaControls, value } = getInputProps();

  return (
    <div
      className={`${className} ${
        isTowables || selectedTab === SEARCH_TAB.LOCATION
          ? isStayFilterEnabled
            ? 'rounded-tr-2xl'
            : 'rounded-none'
          : 'lg:rounded-bl-[14px]'
      } ${isTowables ? classes.towableLocationTab : classes.locationTab} ${
        !isCategoryEnabled ? 'rounded-tl-[14px] rounded-tr-[14px] lg:rounded-tr-none' : ''
      } ${isStayFilterEnabled ? 'rounded-t-2xl' : ''}`}>
      <div className={classes.homeInputContainer} data-focus={!!ariaControls}>
        <span className={globalCss.homeLabel}>{locationPlaceholder}</span>
        <input
          className={classes.homeInput}
          placeholder={homeLocationPlaceholder}
          {...getInputProps()}
        />
        {!value && <span>{noValuePlaceholder}</span>}
      </div>
    </div>
  );
};

export default LocationSearchInput;
