import { getReferrer } from '@/utility/getReferrer';
import { isSSR } from '@/utility/isSSR';

enum ECampgroundPage {
  STATE_CAMPROUND = 'state_campground',
  CITY_CAMPGROUND = 'city_campground',
  UNCLAIMED_CAMPGROUND = 'unclaimed_campground',
  CLAIMED_CAMGROUND = 'claimed_campground',
}

enum ECampgroundType {
  CAMPGROUND_DIRECTORY = 'campground-directory',
  CAMPGROUND = 'campground',
}

enum ELocalityType {
  STATE = 'state',
  LOCALITY = 'locality',
}

export type TPageCategories =
  | 'home'
  | 'blog'
  | 'trips'
  | 'other'
  | 'search'
  | 'profile'
  | 'listing'
  | 'messages'
  | 'locality'
  | 'checkout'
  | 'renter_dash'
  | 'error'
  // virtual sub categories of /locality
  | 'claimed_campground'
  | 'unclaimed_campground'
  | 'state_campground'
  | 'city_campground';

export type TPageViewMetaData = {
  previousPageUrl: string;
  pageCategory: TPageCategories;
  previousCategory: TPageCategories;
};

export const pageCategoryMap: { [key: string]: TPageCategories } = {
  '/': 'home',
  '/blog': 'blog',
  '/trips': 'trips',
  '/rv-search': 'search',
  '/checkout': 'checkout',
  '/messages': 'messages',
  '/renter-dash': 'renter_dash',
  '/locality': 'locality',
  '/rv-rental': 'listing',
  '/stay-rental': 'listing',
  '/campground-rental': 'listing',
  '/campsite-rental': 'listing',
  '/dashboard/profile': 'profile',
  '/pro': 'profile',
  '/_error': 'error',
  // virtual sub categories of /locality
  '/claimed_campground': 'claimed_campground',
  '/unclaimed_campground': 'unclaimed_campground',
  '/state_campground': 'state_campground',
  '/city_campground': 'city_campground',
};

export const parsePageUrl = (url: string, pageType?: string) => {
  const splitPath = url?.split('/');
  let parsedUrl = `/${splitPath?.[0]}${splitPath?.[1]}`;
  const slug = splitPath?.[2];

  if (parsedUrl === '/trips' && slug) {
    parsedUrl = '/renter-dash';
  }

  if (pageType === 'locality') {
    parsedUrl = '/locality';
  }
  if (pageType === 'claimed_campground') {
    parsedUrl = '/claimed_campground';
  }
  if (pageType === 'unclaimed_campground') {
    parsedUrl = '/unclaimed_campground';
  }
  if (pageType === 'state_campground') {
    parsedUrl = '/state_campground';
  }
  if (pageType === 'city_campground') {
    parsedUrl = '/city_campground';
  }

  return parsedUrl;
};

export const parsePageMetaData = (storedPageMetaData: Storage, pageType?: string) => ({
  previousPageUrl: storedPageMetaData.previousPageUrl || getReferrer(),
  pageCategory: pageCategoryMap[parsePageUrl(storedPageMetaData.pageCategory, pageType)] || 'other',
  previousCategory: pageCategoryMap[parsePageUrl(storedPageMetaData.previousCategory)] || 'other',
});

/**
 * Using Storage since window.document.referrer doesn't work well this pages that have multiple steps
 * like the checkout page.
 */

export const storePageMetaData = (pageCategoryOverride?: string) => {
  if (isSSR()) return;
  const storage = window.sessionStorage;
  if (!storage) return;
  // PREVIOUS VALUES
  const previousPageUrl = storage.getItem('currentPageUrl') || '';
  const pageCategory = storage.getItem('pageCategory') || '';

  storage.setItem('previousPageUrl', previousPageUrl);
  storage.setItem('previousCategory', pageCategory);

  storage.setItem('currentPageUrl', window.location.href);
  storage.setItem(
    'pageCategory',
    pageCategoryOverride ? `/${pageCategoryOverride}` : window.location.pathname,
  );

  return storage;
};
/**
 * Returns an page type override for campground localities based on the passed in metadata (from seeker).
 */
export const getCampgroundPageType = (metadata: Record<string, any>): string | undefined => {
  // state campgrounds page
  if (
    metadata?.page_type === ECampgroundType.CAMPGROUND_DIRECTORY &&
    metadata?.locality?.type === ELocalityType.STATE
  ) {
    return ECampgroundPage.STATE_CAMPROUND;
  }
  // city campgrounds page
  if (
    metadata?.page_type === ECampgroundType.CAMPGROUND_DIRECTORY &&
    metadata?.locality?.type === ELocalityType.LOCALITY
  ) {
    return ECampgroundPage.CITY_CAMPGROUND;
  }
  // unclaimed campground page
  if (
    metadata?.locality_type === ECampgroundType.CAMPGROUND &&
    metadata?.claimed_campground_slug === undefined
  ) {
    return ECampgroundPage.UNCLAIMED_CAMPGROUND;
  }
  // claimed campground page
  if (
    metadata?.locality_type === ECampgroundType.CAMPGROUND &&
    metadata?.claimed_campground_slug !== undefined
  ) {
    return ECampgroundPage.CLAIMED_CAMGROUND;
  }
};
