import React from 'react';

import CustomLink from '@/components/switchback/Link/Link';
import { DISCLAIMER_LINKS } from '@/constants/checkout';

const PrivacyLink: React.FC = ({ children }) => (
  <CustomLink target="_blank" href={DISCLAIMER_LINKS.PRIVACY}>
    {children}
  </CustomLink>
);

export default PrivacyLink;
