import { AxiosResponse } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { TLocale } from '@/config/locales';
import { EBookingStatus, EGuestUserHeader } from '@/constants/checkout';
import { CheckoutSteps } from '@/graphql/types-and-hooks';
import { TRootState } from '@/redux/rootReducer';
import apiRequest, { GENERIC_ERROR_MESSAGE } from '@/services/apiRequest';
import { IBookingBundles, IBookingBundlesError } from '@/services/types/booking/bundles';
import { IBooking, IUserNoticeEvent } from '@/services/types/booking/details';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IBookingService } from '@/services/types/booking/services';
import { ICheckoutSteps } from '@/services/types/core/checkoutSteps';
import { IItem, IOccupancy } from '@/services/types/core/quotes';
import { buildPatchBookingData, getRawBookingPayment } from '@/utility/booking';
import { getCoreApi } from '@/utility/getCoreApi';
import { camelKeysToSnake } from '@/utility/objects';
import { IAction } from '@/utility/redux/action';
import { setSessionCookie } from '@/utility/session.login';

import { getBookingOrQuoteAddons } from '../selectors/bill/addons';
import { getBookingData } from '../selectors/checkout/booking';
import { getAddons } from '../selectors/listing/addons';
import { IGetAddonsTotalResponseAction, SET_TOTAL_ADDONS_RESPONSE } from './addons';
import { getAvailableServices } from './availableServices';

const BOOKING_START_PENDING = 'checkout/BOOKING_START_PENDING';
const BOOKING_START_RESPONSE = 'checkout/BOOKING_START_RESPONSE';
export const BOOKING_START_FAILURE = 'checkout/BOOKING_START_FAILURE';

const GET_BOOKING_PENDING = 'checkout/GET_BOOKING_PENDING';
const GET_BOOKING_RESPONSE = 'checkout/GET_BOOKING_RESPONSE';
const GET_BOOKING_FAILURE = 'checkout/GET_BOOKING_FAILURE';

const UPDATE_BOOKING_PENDING = 'checkout/UPDATE_BOOKING_PENDING';
const UPDATE_BOOKING_RESPONSE = 'checkout/UPDATE_BOOKING_RESPONSE';
const UPDATE_BOOKING_FAILURE = 'checkout/UPDATE_BOOKING_FAILURE';

const UPDATE_BOOKING_BUNDLE_PENDING = 'checkout/UPDATE_BOOKING_BUNDLE_PENDING';
const UPDATE_BOOKING_BUNDLE_RESPONSE = 'checkout/UPDATE_BOOKING_BUNDLE_RESPONSE';
const UPDATE_BOOKING_BUNDLE_FAILURE = 'checkout/UPDATE_BOOKING_BUNDLE_FAILURE';

const GET_CHECKOUT_STEPS_PENDING = 'checkout/GET_CHECKOUT_STEPS_PENDING';
const GET_CHECKOUT_STEPS_RESPONSE = 'checkout/GET_CHECKOUT_STEPS_RESPONSE';
const GET_CHECKOUT_STEPS_FAILURE = 'checkout/GET_CHECKOUT_STEPS_FAILURE';

const UPDATE_CHECKOUT_STEPS_PENDING = 'checkout/UPDATE_CHECKOUT_STEPS_PENDING';
const UPDATE_CHECKOUT_STEPS_RESPONSE = 'checkout/UPDATE_CHECKOUT_STEPS_RESPONSE';
const UPDATE_CHECKOUT_STEPS_FAILURE = 'checkout/UPDATE_CHECKOUT_STEPS_FAILURE';

const UPDATE_STATUS_PENDING = 'checkout/UPDATE_STATUS_PENDING';
const UPDATE_STATUS_RESPONSE = 'checkout/UPDATE_STATUS_RESPONSE';
const UPDATE_STATUS_FAILURE = 'checkout/UPDATE_STATUS_FAILURE';

const UPDATE_BOOKING_ADDONS_PENDING = 'checkout/UPDATE_BOOKING_ADDONS_PENDING';
const UPDATE_BOOKING_ADDONS_RESPONSE = 'checkout/UPDATE_BOOKING_ADDONS_RESPONSE';
const UPDATE_BOOKING_ADDONS_FAILURE = 'checkout/UPDATE_BOOKING_ADDONS_FAILURE';

const RESET_CHECKOUT_STORE = 'checkout/RESET_CHECKOUT_STORE';

const ADD_BOOKING_SERVICE_PENDING = 'checkout/ADD_BOOKING_SERVICE_PENDING';
const ADD_BOOKING_SERVICE_RESPONSE = 'checkout/ADD_BOOKING_SERVICE_RESPONSE';
const ADD_BOOKING_SERVICE_FAILURE = 'checkout/ADD_BOOKING_SERVICE_FAILURE';

const REMOVE_BOOKING_SERVICE_PENDING = 'checkout/REMOVE_BOOKING_SERVICE_PENDING';
const REMOVE_BOOKING_SERVICE_RESPONSE = 'checkout/REMOVE_BOOKING_SERVICE_RESPONSE';
const REMOVE_BOOKING_SERVICE_FAILURE = 'checkout/REMOVE_BOOKING_SERVICE_FAILURE';

export enum EOwnerQuestionsFields {
  PICKUP = 'from_time',
  DROPOFF = 'to_time',
}

export interface ICharge {
  card_id?: number;
  card_token?: string;
  full?: boolean;
  method: string;
  payment_intent_id?: string;
}

interface IPayPalChargeInfoDetails extends paypal.TokenizePayload {
  deviceData: string;
}
interface IPayPalChargeInfo {
  token: string;
  payer_id: string;
  details: IPayPalChargeInfoDetails;
}
interface IBookingStatus {
  status: EBookingStatus;
  rental_charge?: ICharge;
  security_deposit_charge?: ICharge;
  as_credits?: boolean;
  decline_reason?: string;
  save_card_token?: string;
  to?: string;
  from?: string;
  cancel_reason?: string;
  comment?: string;
  details?: string;
  no_send_email?: boolean;
  refund_amount?: number;
  refund_type?: string;
  paypal_charge_info?: IPayPalChargeInfo;
}
export interface IBookingError {
  statusCode?: number;
  code?: string;
  error: string;
}
interface IBookingAndPayment {
  booking: IBooking;
  payment?: IBookingPayment;
}
interface IBookingStartAction extends IAction {
  type: typeof BOOKING_START_PENDING;
}

interface IBookingStartResponseAction extends IAction {
  type: typeof BOOKING_START_RESPONSE;
  payload: IBookingAndPayment;
}

interface IBookingStartFailAction extends IAction {
  type: typeof BOOKING_START_FAILURE;
  payload: IBookingError;
  error: true;
}

export interface IBookingStartSetErrorAction extends IAction {
  type: typeof BOOKING_START_FAILURE;
  payload?: IBookingError;
  error?: boolean;
}

type TBookingStartAction =
  | IBookingStartAction
  | IBookingStartResponseAction
  | IBookingStartFailAction;

interface IGetBookingPendingAction extends IAction {
  type: typeof GET_BOOKING_PENDING;
}

interface IGetBookingResponseAction extends IAction {
  type: typeof GET_BOOKING_RESPONSE;
  payload: IBookingAndPayment;
}

interface IGetBookingFailAction extends IAction {
  type: typeof GET_BOOKING_FAILURE;
  payload: IBookingError;
  error: true;
}

type TGetBookingAction =
  | IGetBookingPendingAction
  | IGetBookingResponseAction
  | IGetBookingFailAction;

interface IAddBookingServicePendingAction extends IAction {
  type: typeof ADD_BOOKING_SERVICE_PENDING;
}

interface IAddBookingServiceResponseAction extends IAction {
  type: typeof ADD_BOOKING_SERVICE_RESPONSE;
}

interface IAddBookingServiceFailureAction extends IAction {
  type: typeof ADD_BOOKING_SERVICE_FAILURE;
  payload: IBookingError;
  error: true;
}

type TAddBookingServiceAction =
  | IAddBookingServicePendingAction
  | IAddBookingServiceResponseAction
  | IAddBookingServiceFailureAction;

interface IRemoveBookingServicePendingAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICE_PENDING;
}

interface IRemoveBookingServiceResponseAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICE_RESPONSE;
}

interface IRemoveBookingServiceFailureAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICE_FAILURE;
  payload: IBookingError;
  error: true;
}

type TRemoveBookingServiceAction =
  | IRemoveBookingServicePendingAction
  | IRemoveBookingServiceResponseAction
  | IRemoveBookingServiceFailureAction;

interface IUpdateBookingPendingAction extends IAction {
  type: typeof UPDATE_BOOKING_PENDING;
}

interface IUpdateBookingResponseAction extends IAction {
  type: typeof UPDATE_BOOKING_RESPONSE;
  payload: IBookingAndPayment;
}

interface IUpdateBookingFailAction extends IAction {
  type: typeof UPDATE_BOOKING_FAILURE;
  payload: IBookingError;
  error: true;
}

type TUpdateBookingAction =
  | IUpdateBookingPendingAction
  | IUpdateBookingResponseAction
  | IUpdateBookingFailAction;

interface IUpdateBookingBundlePendingAction extends IAction {
  type: typeof UPDATE_BOOKING_BUNDLE_PENDING;
}

interface IUpdateBookingBundleResponseAction extends IAction {
  type: typeof UPDATE_BOOKING_BUNDLE_RESPONSE;
  payload: IBookingBundles;
}

interface IUpdateBookingBundleFailAction extends IAction {
  type: typeof UPDATE_BOOKING_BUNDLE_FAILURE;
  payload: IBookingBundlesError;
  error: true;
}

type TUpdateBookingBundleAction =
  | IUpdateBookingBundlePendingAction
  | IUpdateBookingBundleResponseAction
  | IUpdateBookingBundleFailAction;

interface IGetCheckoutStepsPendingAction extends IAction {
  type: typeof GET_CHECKOUT_STEPS_PENDING;
}

interface IGetCheckoutStepsResponseAction extends IAction {
  type: typeof GET_CHECKOUT_STEPS_RESPONSE;
  payload: ICheckoutSteps;
}

interface IGetCheckoutStepsFailAction extends IAction {
  type: typeof GET_CHECKOUT_STEPS_FAILURE;
  payload: IBookingError;
  error: true;
}

type TGetCheckoutStepsAction =
  | IGetCheckoutStepsPendingAction
  | IGetCheckoutStepsResponseAction
  | IGetCheckoutStepsFailAction;

interface IUpdateCheckoutStepsPendingAction extends IAction {
  type: typeof UPDATE_CHECKOUT_STEPS_PENDING;
}

interface IUpdateCheckoutStepsResponseAction extends IAction {
  type: typeof UPDATE_CHECKOUT_STEPS_RESPONSE;
  payload: ICheckoutSteps;
}

interface IUpdateCheckoutStepsFailAction extends IAction {
  type: typeof UPDATE_CHECKOUT_STEPS_FAILURE;
  payload: IBookingError;
  error: true;
}

type TUpdateCheckoutStepsAction =
  | IUpdateCheckoutStepsPendingAction
  | IUpdateCheckoutStepsResponseAction
  | IUpdateCheckoutStepsFailAction;

interface IUpdateStatusPendingAction extends IAction {
  type: typeof UPDATE_STATUS_PENDING;
}

interface IUpdateStatusResponseAction extends IAction {
  type: typeof UPDATE_STATUS_RESPONSE;
  payload: IBookingAndPayment;
}

interface IUpdateStatusFailAction extends IAction {
  type: typeof UPDATE_STATUS_FAILURE;
  payload: IBookingError;
  error: true;
}

type TUpdateStatusAction =
  | IUpdateStatusPendingAction
  | IUpdateStatusResponseAction
  | IUpdateStatusFailAction;

interface IUpdateBookingAddonsPendingAction extends IAction {
  type: typeof UPDATE_BOOKING_ADDONS_PENDING;
}

interface IUpdateBookingAddonsResponseAction extends IAction {
  type: typeof UPDATE_BOOKING_ADDONS_RESPONSE;
}

interface IUpdateBookingAddonsFailAction extends IAction {
  type: typeof UPDATE_BOOKING_ADDONS_FAILURE;
  payload: IBookingError;
  error: true;
}

type TUpdateBookingItemAction =
  | IUpdateBookingAddonsPendingAction
  | IUpdateBookingAddonsResponseAction
  | IUpdateBookingAddonsFailAction;

interface IResetCheckoutStoreAction extends IAction {
  type: typeof RESET_CHECKOUT_STORE;
}

type TAction =
  | TBookingStartAction
  | TGetBookingAction
  | TUpdateBookingAction
  | TUpdateBookingBundleAction
  | TGetCheckoutStepsAction
  | TUpdateCheckoutStepsAction
  | TUpdateStatusAction
  | IResetCheckoutStoreAction
  | TUpdateBookingItemAction;

type TBookingStartFunction = (bookingInfo: {
  status?: EBookingStatus;
  from: string;
  to: string;
  rentalId: number;
  details?: {
    hasPets?: boolean;
    isFestivalTravel?: boolean;
    source?: string;
  };
  quoteId: string;
  renterInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
    locale: TLocale;
    disableSms?: boolean;
    birthdate?: string;
    signupSource?: string;
  };
  discountCode?: string;
  freeCancellationPeriod: number | null;
  userNoticeEvents?: IUserNoticeEvent[];
  travelToStates: string[] | null;
  tripCreditsDisabled?: boolean;
  occupancy?: IOccupancy;
}) => ThunkAction<Promise<IBooking>, TRootState, void, TBookingStartAction>;

type TGetBookingFunction = (
  bookingId: number,
) => ThunkAction<Promise<IBooking>, TRootState, void, TGetBookingAction>;

type TAddBookingServiceFunction = (
  bookingId: number,
  serviceId: number,
  serviceData?: { [key: string]: any },
) => ThunkAction<Promise<IBookingService>, TRootState, void, TAddBookingServiceAction>;

type TRemoveBookingServiceFunction = (
  bookingId: number,
  serviceId: number,
) => ThunkAction<Promise<IBookingService>, TRootState, void, TRemoveBookingServiceAction>;

type TUpdateBookingFunction = (
  bookingId: number,
  booking: Partial<IBooking>,
) => ThunkAction<Promise<IBooking>, TRootState, void, TUpdateBookingAction>;

type TApplyCouponFunction = (
  bookingId: number,
  coupon: string,
) => ThunkAction<Promise<IBooking>, TRootState, void, IUpdateBookingResponseAction>;

type TRemoveRafCodeFunction = (
  bookingId: number,
) => ThunkAction<Promise<IBooking>, TRootState, void, IUpdateBookingResponseAction>;

type TUpdateBookingBundleFunction = (
  bookingId: number,
  bundleId: string,
) => ThunkAction<Promise<IBookingBundles>, TRootState, void, TUpdateBookingBundleAction>;

type TGetCheckoutStepsFunction = (
  bookingId: number,
) => ThunkAction<Promise<ICheckoutSteps>, TRootState, void, TGetCheckoutStepsAction>;

type TUpdateCheckoutStepsFunction = (
  bookingId: number,
  checkoutSteps: ICheckoutSteps | CheckoutSteps,
) => ThunkAction<Promise<ICheckoutSteps>, TRootState, void, TUpdateCheckoutStepsAction>;

type TUpdateStatusFunction = (
  bookingId: number,
  status: IBookingStatus,
) => ThunkAction<Promise<IBooking>, TRootState, void, TUpdateStatusAction>;

type TUpdateBookingAddonsFunction = () => ThunkAction<
  Promise<IBooking | undefined>,
  TRootState,
  void,
  TUpdateBookingItemAction | IGetAddonsTotalResponseAction
>;

const createBookingError = (response: AxiosResponse): IBookingError => ({
  error: GENERIC_ERROR_MESSAGE,
  statusCode: response.status,
  ...response.data,
});

const createBookingBundleError = (response: AxiosResponse): IBookingBundlesError => ({
  error: GENERIC_ERROR_MESSAGE,
  statusCode: response.status,
  ...response.data,
});

export const resetCheckoutStore = (): IResetCheckoutStoreAction => ({
  type: RESET_CHECKOUT_STORE,
});

export const startBooking: TBookingStartFunction =
  ({
    status = EBookingStatus.NEGOTIATING,
    from,
    to,
    rentalId,
    details,
    quoteId,
    renterInfo,
    discountCode,
    freeCancellationPeriod,
    userNoticeEvents,
    travelToStates,
    tripCreditsDisabled = true,
    occupancy,
  }) =>
  async dispatch => {
    const data = {
      status,
      from,
      to,
      rental_id: rentalId,
      details: details ? camelKeysToSnake(details) : null,
      quote_id: quoteId,
      renter: camelKeysToSnake(renterInfo),
      discount_code: discountCode,
      ...(freeCancellationPeriod && { free_cancellation_period: freeCancellationPeriod }),
      ...(!!userNoticeEvents?.length && {
        user_notices_acknowledged: userNoticeEvents
          .filter(event => event.guest_need_to_acknowledge)
          .map(event => event.id),
      }),
      ...(travelToStates && { travel_to_states: travelToStates }),
      trip_credits_disabled: tripCreditsDisabled,
      ...(occupancy && { occupancy }),
    };

    dispatch<IBookingStartAction>({ type: BOOKING_START_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<IBooking>({ url: `${getCoreApi()}/bookings`, method: 'POST', data }, true, true)
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }

          const { [EGuestUserHeader.USER_ID]: guestUserId, [EGuestUserHeader.TOKEN]: guestToken } =
            response.headers;
          if (guestUserId && guestToken) {
            setSessionCookie(
              {
                user_id: guestUserId,
                token: guestToken,
              },
              dispatch,
            );
          }
          // TODO: the POST response does not return `addons`, so for now we
          // must fetch a fresh booking to ensure addons are included
          const booking = dispatch(getBooking(response.data.id));
          return booking;
        })
        .then(booking => {
          dispatch<IBookingStartResponseAction>({
            type: BOOKING_START_RESPONSE,
            payload: { booking },
          });
          return resolve(booking);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IBookingStartFailAction>({
            type: BOOKING_START_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const getBooking: TGetBookingFunction = bookingId => async dispatch => {
  dispatch<IGetBookingPendingAction>({ type: GET_BOOKING_PENDING });

  return new Promise((resolve, reject) =>
    apiRequest<IBooking>({ url: `${getCoreApi()}/bookings/${bookingId}` }, true, true)
      .then(response => {
        if (!response.data) {
          throw new Error('404');
        }

        return {
          booking: response.data,
          payment: getRawBookingPayment(response.headers),
        };
      })
      .then(({ booking, payment }) => {
        dispatch<IGetBookingResponseAction>({
          type: GET_BOOKING_RESPONSE,
          payload: {
            booking,
            payment,
          },
        });
        dispatch(getAvailableServices());
        return resolve(booking);
      })
      .catch(response => {
        const error = createBookingError(response);
        dispatch<IGetBookingFailAction>({
          type: GET_BOOKING_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

/**
 * add booking service
 */
export const addBookingService: TAddBookingServiceFunction =
  (bookingId: number, serviceId: number, serviceData?: { [key: string]: any }) =>
  async dispatch => {
    dispatch<IAddBookingServicePendingAction>({ type: ADD_BOOKING_SERVICE_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<IBookingService>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/services/${serviceId}`,
          method: 'POST',
          data: { skip_required_fields_validation: true, data: serviceData || {} },
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }
          return response.data;
        })
        .then(bookingService => {
          dispatch<IAddBookingServiceResponseAction>({
            type: ADD_BOOKING_SERVICE_RESPONSE,
            payload: bookingService,
          });
          return resolve(bookingService);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IAddBookingServiceFailureAction>({
            type: ADD_BOOKING_SERVICE_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

/**
 * remove booking service
 */
export const removeBookingService: TRemoveBookingServiceFunction =
  (bookingId, serviceId) => async dispatch => {
    dispatch<IRemoveBookingServicePendingAction>({ type: REMOVE_BOOKING_SERVICE_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<IBookingService>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/services/${serviceId}`,
          method: 'DELETE',
        },
        true,
        true,
      )
        .then(response => {
          dispatch<IRemoveBookingServiceResponseAction>({
            type: REMOVE_BOOKING_SERVICE_RESPONSE,
            payload: response.data,
          });
          return resolve(response.data);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IRemoveBookingServiceFailureAction>({
            type: REMOVE_BOOKING_SERVICE_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const updateBooking: TUpdateBookingFunction =
  (bookingId, booking) => async (dispatch, getState) => {
    dispatch<IUpdateBookingPendingAction>({ type: UPDATE_BOOKING_PENDING });
    const bookingData = getState().checkout.booking;

    return new Promise((resolve, reject) =>
      apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}`,
          method: 'PATCH',
          data: buildPatchBookingData(bookingData, booking),
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }

          return {
            booking: response.data,
          };
        })
        .then(({ booking }) => {
          dispatch<IUpdateBookingResponseAction>({
            type: UPDATE_BOOKING_RESPONSE,
            payload: { booking },
          });
          return resolve(booking);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IUpdateBookingFailAction>({
            type: UPDATE_BOOKING_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const applyCoupon: TApplyCouponFunction =
  (bookingId, coupon) => async (dispatch, getState) => {
    return apiRequest<IBooking>(
      {
        url: `${getCoreApi()}/bookings/${bookingId}/apply-coupon/${coupon}`,
        method: 'PATCH',
        data: buildPatchBookingData(getState().checkout.booking, {
          discount_code: coupon,
        }),
      },
      true,
    ).then(booking => {
      dispatch<IUpdateBookingResponseAction>({
        type: UPDATE_BOOKING_RESPONSE,
        payload: { booking },
      });

      return booking;
    });
  };

export const removeRafCode: TRemoveRafCodeFunction = bookingId => async dispatch => {
  return apiRequest<IBooking>(
    {
      url: `${getCoreApi()}/bookings/${bookingId}/remove-raf-waiver`,
      method: 'POST',
    },
    true,
  ).then(booking => {
    dispatch<IUpdateBookingResponseAction>({
      type: UPDATE_BOOKING_RESPONSE,
      payload: { booking },
    });

    return booking;
  });
};

export const updateBookingBundle: TUpdateBookingBundleFunction =
  (bookingId, bundleId) => async dispatch => {
    dispatch<IUpdateBookingBundlePendingAction>({ type: UPDATE_BOOKING_BUNDLE_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<IBookingBundles>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/bundles`,
          method: 'POST',
          data: { bundle_id: bundleId },
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }

          return response.data;
        })
        .then(bundles => {
          dispatch<IUpdateBookingBundleResponseAction>({
            type: UPDATE_BOOKING_BUNDLE_RESPONSE,
            payload: bundles,
          });
          dispatch(getBooking(bookingId));
          return resolve(bundles);
        })
        .catch(response => {
          const error = createBookingBundleError(response);
          dispatch<IUpdateBookingBundleFailAction>({
            type: UPDATE_BOOKING_BUNDLE_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const getCheckoutSteps: TGetCheckoutStepsFunction = bookingId => async dispatch => {
  dispatch<IGetCheckoutStepsPendingAction>({ type: GET_CHECKOUT_STEPS_PENDING });

  return new Promise((resolve, reject) =>
    apiRequest<ICheckoutSteps>({ url: `${getCoreApi()}/checkout_steps/${bookingId}` }, true, true)
      .then(response => {
        if (!response.data) {
          throw new Error('404');
        }
        return response.data;
      })
      .then(checkoutSteps => {
        dispatch<IGetCheckoutStepsResponseAction>({
          type: GET_CHECKOUT_STEPS_RESPONSE,
          payload: checkoutSteps,
        });
        return resolve(checkoutSteps);
      })
      .catch(response => {
        const error = createBookingError(response);
        dispatch<IGetCheckoutStepsFailAction>({
          type: GET_CHECKOUT_STEPS_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

export const updateCheckoutSteps: TUpdateCheckoutStepsFunction =
  (bookingId, checkoutSteps) => async dispatch => {
    dispatch<IUpdateCheckoutStepsPendingAction>({ type: UPDATE_CHECKOUT_STEPS_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<ICheckoutSteps>(
        {
          url: `${getCoreApi()}/checkout_steps/${bookingId}`,
          method: 'PATCH',
          data: checkoutSteps,
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }
          return response.data;
        })
        .then(checkoutSteps => {
          dispatch<IUpdateCheckoutStepsResponseAction>({
            type: UPDATE_CHECKOUT_STEPS_RESPONSE,
            payload: checkoutSteps,
          });
          return resolve(checkoutSteps);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IUpdateCheckoutStepsFailAction>({
            type: UPDATE_CHECKOUT_STEPS_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const updateStatus: TUpdateStatusFunction =
  (bookingId, status) => async (dispatch, getState) => {
    dispatch<IUpdateStatusPendingAction>({ type: UPDATE_STATUS_PENDING });

    const rootState = getState();
    const userNoticeEvents = rootState.checkout.booking?.user_notice_events;

    return new Promise((resolve, reject) =>
      apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/status`,
          method: 'PATCH',
          data: {
            ...status,
            ...(!!userNoticeEvents?.length && {
              user_notices_acknowledged: userNoticeEvents
                .filter(event => event.guest_need_to_acknowledge)
                .map(event => event.id),
            }),
          },
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }
          return response.data;
        })
        .then(booking => {
          dispatch<IUpdateStatusResponseAction>({
            type: UPDATE_STATUS_RESPONSE,
            payload: { booking },
          });
          resolve(booking);
        })
        .catch(response => {
          const error = createBookingError(response);
          dispatch<IUpdateStatusFailAction>({
            type: UPDATE_STATUS_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const updateBookingAddons: TUpdateBookingAddonsFunction =
  () => async (dispatch, getState) => {
    dispatch<IUpdateBookingAddonsPendingAction>({ type: UPDATE_BOOKING_ADDONS_PENDING });

    const state = getState();
    const bookingData = getBookingData(state);

    if (!bookingData) return;

    const addons = getAddons(state);
    const bookingAddons = getBookingOrQuoteAddons(state);

    const updatedAddons = addons?.map(addon => {
      // If the addon is already in the booking, use the booking item ID to modify it's count
      const bookingItemId = bookingAddons?.find(item => item.id === addon.id)?.itemId;
      // Otherwise, use the listing item ID to add it to the booking
      const listingItemId = addon.id;

      return {
        count: addon.count,
        id: bookingItemId || listingItemId,
      };
    });

    // If there are addons that exist on the booking but not in the local store, remove them
    const removedAddons = bookingAddons
      ?.filter(addon => !addons?.find(item => item.id === addon.id))
      .map(addon => ({
        id: addon.itemId,
        count: 0,
      }));

    return new Promise((resolve, reject) =>
      apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/bookings/${bookingData.id}`,
          method: 'PATCH',
          data: buildPatchBookingData(bookingData, {
            addons: [...(updatedAddons || []), ...(removedAddons || [])],
          }),
        },
        true,
        true,
      )
        .then(response => {
          if (!response.data) {
            throw new Error('404');
          }
          // TODO: The PATCH response returns early, so doesn't include our
          // new addons, therefore we must fetch a fresh booking
          const booking = dispatch(getBooking(response.data.id));
          return booking;
        })
        .then(booking => {
          const totalValue = booking?.addons?.reduce(
            (acc: number, curr: IItem) => acc + curr.total,
            0,
          );

          dispatch<IGetAddonsTotalResponseAction>({
            type: SET_TOTAL_ADDONS_RESPONSE,
            payload: totalValue,
          });

          dispatch<IUpdateBookingAddonsResponseAction>({
            type: UPDATE_BOOKING_ADDONS_RESPONSE,
          });

          resolve(booking);
        })

        .catch(response => {
          const error = createBookingError(response);
          dispatch<IUpdateBookingAddonsFailAction>({
            type: UPDATE_BOOKING_ADDONS_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

interface IState {
  bundles: IBookingBundles | null;
  bundleError?: IBookingBundlesError | undefined;
  addonsError?: IBookingError;
  booking: IBooking | null;
  checkoutSteps: ICheckoutSteps | null;
  // Payment info from the headers
  payment?: IBookingPayment;
  error?: IBookingError | undefined;
  isLoading: boolean;
}

export const initialState: IState = {
  bundles: null,
  booking: null,
  checkoutSteps: null,
  isLoading: false,
};

export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case BOOKING_START_PENDING:
    case GET_BOOKING_PENDING:
    case UPDATE_BOOKING_PENDING:
    case UPDATE_BOOKING_BUNDLE_PENDING:
    case GET_CHECKOUT_STEPS_PENDING:
    case UPDATE_CHECKOUT_STEPS_PENDING:
    case UPDATE_STATUS_PENDING:
    case UPDATE_BOOKING_ADDONS_PENDING:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case BOOKING_START_RESPONSE:
    case GET_BOOKING_RESPONSE:
    case UPDATE_BOOKING_RESPONSE:
    case UPDATE_STATUS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        booking: action.payload.booking,
        ...(action.payload.payment && {
          payment: action.payload.payment,
        }),
      };

    case UPDATE_BOOKING_BUNDLE_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        bundles: action.payload,
      };

    case BOOKING_START_FAILURE:
    case GET_BOOKING_FAILURE:
    case UPDATE_BOOKING_FAILURE:
    case GET_CHECKOUT_STEPS_FAILURE:
    case UPDATE_CHECKOUT_STEPS_FAILURE:
    case UPDATE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case UPDATE_BOOKING_ADDONS_FAILURE:
      return {
        ...state,
        addonsError: action.payload,
        isLoading: false,
      };
    case UPDATE_BOOKING_BUNDLE_FAILURE:
      return {
        ...state,
        bundleError: action.payload,
        isLoading: false,
      };

    case GET_CHECKOUT_STEPS_RESPONSE:
    case UPDATE_CHECKOUT_STEPS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        checkoutSteps: action.payload,
      };

    case RESET_CHECKOUT_STORE:
      return initialState;

    default:
      return state;
  }
}
