import { createSelector } from 'reselect';

import { TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { IUserNoticeEvent } from '@/services/types/booking/details';
import { IBookingService } from '@/services/types/booking/services';
import { IWaiverSummary } from '@/services/types/core/quotes';
import { IAddOnItem } from '@/services/types/quote/IAddons';

export const getQuoteDelivery = createSelector<
  TRootState,
  TRootState['quote'],
  TRootState['quote']['delivery']
>(
  state => state.quote,
  quote => quote.delivery,
);

export const getQuoteData = createSelector<
  TRootState,
  TRootState['quote'],
  TRootState['quote']['data']
>(
  state => state.quote,
  quote => quote.data,
);

export const getQuoteError = createSelector<TRootState, TRootState['quote'], string | undefined>(
  state => state.quote,
  quote => quote.errorMessage,
);

export const getQuoteIsLoading = createSelector<
  TRootState,
  TRootState['quote'],
  boolean | undefined
>(
  state => state.quote,
  quote => quote.isLoadingQuote,
);

interface IQuoteBooking {
  rentalId: number;
  quoteId: string;
  to: string;
  from: string;
  discountCode?: string;
  waiverSummary?: IWaiverSummary;
  userNoticeEvents?: IUserNoticeEvent[];
}

export const getQuoteBooking = createSelector<
  TRootState,
  TRootState['quote']['data'],
  IQuoteBooking | null
>(getQuoteData, data => {
  if (!data) return null;
  const {
    id: quoteId,
    to,
    from,
    discount_code: discountCode,
    rental_summary: { id: rentalId } = {},
    waiver_summary: waiverSummary,
    user_notice_events: userNoticeEvents,
    occupancy,
  } = data;

  if (!rentalId) return null;

  return { to, from, rentalId, quoteId, discountCode, waiverSummary, userNoticeEvents, occupancy };
});

export const getQuoteId = createSelector<TRootState, TRootState['quote'], string | undefined>(
  state => state.quote,
  quote => quote.data?.id,
);

export const getQuoteCurrency = createSelector<
  TRootState,
  TRootState['quote']['data'],
  TCurrency | undefined
>(getQuoteData, data => data?.presentment_currency);

export const getQuoteAddons = createSelector<
  TRootState,
  TRootState['quote']['data'],
  IAddOnItem[] | undefined
>(getQuoteData, data =>
  data?.addons?.map(item => ({
    count: item.count,
    id: item.id || item.parent_item_id,
    name: item.name,
    daily: item.daily,
    price: item.price,
    total: item.total,
  })),
);

export const getQuoteIsInstantBook = createSelector<TRootState, TRootState['quote'], boolean>(
  state => state.quote,
  quote => Boolean(quote.data?.instant_book),
);

export const getQuoteInitialDuration = createSelector<
  TRootState,
  TRootState['quote'],
  number | undefined
>(
  state => state.quote,
  quote => quote.initialDuration,
);

export const getQuoteServices = createSelector<
  TRootState,
  TRootState['quote'],
  IBookingService[] | undefined
>(
  state => state.quote,
  quote => quote.data?.services,
);
