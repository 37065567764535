import {
  ETextStyleVariant as EBonfireTextStyleVariant,
  Icon as BonfireIcon,
  Text as BonfireText,
} from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Checkbox, { CheckboxAlignment } from '@/components/switchback/Checkbox/Checkbox';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useWindowSize from '@/hooks/useWindowSize';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { trackEvent } from '@/services/track-event';

import { IHeaderMenu } from './menu';
import classes from './UniversalSearchContainer.module.css';

interface IUniversalSearchContainerProps {
  openedTab?: IHeaderMenu;
  openTab: (tab: IHeaderMenu, parentRef: RefObject<HTMLUListElement>) => void;
  secondaryMenu?: boolean;
  isStayMenu?: boolean;
  isShowInstantBook?: boolean;
  menu: IHeaderMenu[];
  className?: string;
}

const UniversalSearchContainer: React.FC<IUniversalSearchContainerProps> = ({
  openedTab,
  openTab,
  secondaryMenu,
  isStayMenu,
  isShowInstantBook,
  menu,
  className,
}) => {
  const { isMobile } = useBreakpoint();
  const windowSize = useWindowSize();
  const tabListEl = useRef<HTMLUListElement>(null);
  const activeRef = useRef<HTMLButtonElement>(null);
  const [styles, setStyles] = useState<Record<string, number | string>>({ display: 'none' });
  const [isInstantBookChecked, setIsInstantBookChecked] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setStyles({
      top: secondaryMenu ? activeRef.current?.offsetTop || 0 : 'auto',
      left: activeRef.current?.offsetLeft || 0,
      width: activeRef.current?.offsetWidth || 0,
    });
  }, [openedTab, secondaryMenu, windowSize]);

  // TODO: if we are asked to add more filters here we should move this to it's own component
  //       and surface this up the chain so it's not embedded here
  const handleChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    setIsInstantBookChecked(checked);
    trackEvent({
      event: 'search/filter/instantbook',
      action: 'update',
      before: !checked,
      after: checked,
    });
    dispatch(
      setFormFilter({
        filters: {
          [ESearchFilters.INSTANT_BOOK]: checked,
        },
        filterMethod: EFilterMethod.CHECKBOX,
      }),
    );
  };

  return (
    <div
      className={clsx(
        {
          [classes.container as string]: !secondaryMenu,
          [classes.secondary as string]: secondaryMenu,
          [classes.stayMenu as string]: isStayMenu,
          [classes.filters_container as string]: isShowInstantBook,
        },
        className,
      )}>
      <ul className={classes.tab_list} role="tablist" ref={tabListEl}>
        {menu.map((menu, index) => (
          <li key={menu.tab}>
            <button
              className={`${classes.header_tab}`}
              data-opened={openedTab?.tab === menu.tab}
              data-toggle="tab"
              role="tablist"
              tabIndex={index}
              ref={openedTab?.tab === menu.tab ? activeRef : undefined}
              onClick={() => openTab(menu, tabListEl)}>
              {(openedTab?.tab === menu.tab || isMobile || isStayMenu) && !secondaryMenu && (
                <Icon
                  className={`mb-1 md:mr-2 md:mb-0 ${
                    openedTab?.tab === menu.tab ? 'text-gray-900' : 'text-gray-300'
                  }`}
                  name={menu.icon}
                  size={IconSize.normal}
                  width={isStayMenu ? 24 : 20}
                  height={isStayMenu ? 24 : 20}
                />
              )}
              {menu.title}
            </button>
          </li>
        ))}
        <span className={classes.border_bottom} style={styles} />
      </ul>
      {isShowInstantBook && (
        <div className={classes.filters}>
          <Checkbox
            name={ESearchFilters.INSTANT_BOOK}
            value={ESearchFilters.INSTANT_BOOK}
            checked={isInstantBookChecked}
            onChange={handleChange}
            align={CheckboxAlignment.center}
            contentClassName="flex-auto w-full"
            className="flex-row text-xl">
            <div className="flex items-center gap-1">
              <BonfireIcon name="General.InstantBook" className="text-yellowstone" />
              <BonfireText variant={EBonfireTextStyleVariant.MediumBold} className="!mb-0">
                {intl.formatMessage({
                  defaultMessage: 'Instant Book only',
                  description: 'Book instantly USM',
                })}
              </BonfireText>
            </div>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default UniversalSearchContainer;
