import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { IAddonState } from '@/redux/modules/addons';
import { TRootState } from '@/redux/rootReducer';
import { IRentalItem } from '@/services/types/core/rentalItems';
import { formatCurrency } from '@/utility/currency';
import { getIntl } from '@/utility/i18n';

type TRentalItemsData = TRootState['rentalItems']['data'];

export interface IAddOn {
  id?: number;
  description?: string;
  name: string;
  price: string;
  priceInfo?: string;
  priceRules?: string;
  rawPrice?: number;
  quantityAvailable?: number;
  currency?: string;
  quantitySelected?: number;
  daily?: boolean;
  imageUrl?: string;
  position?: number;
}

const messages = defineMessages({
  minMax: {
    id: 'addons-min-max',
    defaultMessage: `{type, select,
      minAndMax {{min} min to {max} max per trip}
      max {Up to {max} per trip}
      min {Min {min} per trip}
      other {}
    }`,
  },
  priceFixed: {
    id: 'addons-price-fixed',
    defaultMessage: `{price}`,
  },
  pricePercent: {
    id: 'addons-price-percent',
    defaultMessage: `{percent}% of {percentType, select,
      rental_amount {rental price}
      subtotal {subtotal}
      other {}
    }`,
  },
  free: {
    id: 'addons-free',
    defaultMessage: 'Free',
  },
});

const mapDataToPriceRulesType = (item: IRentalItem) => {
  if (item.minimum_price && item.maximum_price) {
    return 'minAndMax';
  }

  if (item.minimum_price) {
    return 'min';
  }

  return 'max';
};

const mapDataToPriceRules = (item: IRentalItem) => {
  const intl = getIntl();

  const currency = item.presentment_currency || item.settlement_currency;

  return intl.formatMessage(messages.minMax, {
    type: mapDataToPriceRulesType(item),
    min:
      item.minimum_price &&
      formatCurrency({ priceInCents: item.minimum_price, currency, digits: 2 }),
    max:
      item.maximum_price &&
      formatCurrency({ priceInCents: item.maximum_price, currency, digits: 2 }),
  });
};

const mapDataToIncrementValue = (item: IRentalItem) => {
  const intl = getIntl();

  if (item.daily) {
    return intl.formatMessage({
      defaultMessage: 'day',
    });
  }

  if (item.available > 0) {
    return intl.formatMessage({
      defaultMessage: 'each',
    });
  }

  return '';
};

const mapDataToPrice = (item: IRentalItem) => {
  const intl = getIntl();

  const currency = item.presentment_currency || item.settlement_currency;

  if (item.percent) {
    return intl.formatMessage(messages.pricePercent, {
      price: formatCurrency({ priceInCents: item.price, currency, digits: 2 }),
      percent: item.percent,
      percentType: item.percent_type,
    });
  }

  return intl.formatMessage(messages.priceFixed, {
    price: formatCurrency({ priceInCents: item.price, currency, digits: 2 }),
    percent: item.percent,
    percentType: item.percent_type,
  });
};

type TRequiredOrOptional = 'required' | 'optional';

const mapDataToAddOns = (
  data: IRentalItem[],
  requiredOrOptional?: TRequiredOrOptional,
): IAddOn[] => {
  const intl = getIntl();
  const shouldReturnRequired =
    requiredOrOptional === undefined || requiredOrOptional === 'required';
  const shouldReturnOptional =
    requiredOrOptional === undefined || requiredOrOptional === 'optional';

  return data
    .filter(
      item => (item.required && shouldReturnRequired) || (!item.required && shouldReturnOptional),
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(item => {
      const price =
        item.price || item.percent ? mapDataToPrice(item) : intl.formatMessage(messages.free);

      const increment = mapDataToIncrementValue(item);

      const priceInfo =
        (item.price || item.percent) && increment
          ? intl.formatMessage(
              {
                id: 'addons-price-increment',
                defaultMessage: `{increment, select,
                  each {each}
                  day {per day}
                  other {}
                }`,
              },
              { increment },
            )
          : '';

      const priceRules =
        item.minimum_price || item.maximum_price ? mapDataToPriceRules(item) : undefined;

      return {
        id: item.id,
        name: item.name,
        description: item.description,
        priceInfo,
        price,
        priceRules,
        rawPrice: item.price,
        quantityAvailable: item.available,
        currency: item.presentment_currency || item.settlement_currency,
        imageUrl: item.image_url,
        position: item.position,
        daily: item.daily,
      };
    });
};

export const getOptionalAddOns = createSelector<
  TRootState,
  TRentalItemsData,
  ReturnType<typeof mapDataToAddOns>
>(
  state => state.rentalItems.data,
  data => mapDataToAddOns(data, 'optional'),
);

export const getRequiredAddOns = createSelector<
  TRootState,
  TRentalItemsData,
  ReturnType<typeof mapDataToAddOns>
>(
  state => state.rentalItems.data,
  data => mapDataToAddOns(data, 'required'),
);

export const getAddons = createSelector<TRootState, IAddonState, IAddonState['addons']>(
  state => state.addons,
  addons => addons.addons,
);

export const getAddonsWithQuantitySelected = createSelector<
  TRootState,
  ReturnType<typeof getOptionalAddOns>,
  ReturnType<typeof getAddons>,
  IAddOn[]
>(getOptionalAddOns, getAddons, (optionalAddons, addons) =>
  optionalAddons.map(item => ({
    ...item,
    quantitySelected: addons?.find(addon => addon.id === item.id)?.count,
  })),
);

export const getAddonsIsLoading = createSelector<TRootState, IAddonState, IAddonState['isLoading']>(
  state => state.addons,
  addons => addons.isLoading,
);

export const getAddonsError = createSelector<TRootState, IAddonState, IAddonState['error']>(
  state => state.addons,
  addons => addons.error,
);

export const getHasAddons = createSelector<
  TRootState,
  ReturnType<typeof getOptionalAddOns>,
  boolean
>(getOptionalAddOns, items => !!items?.length);
