import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MAP_PIN } from '@/components/switchback/Icon/assets';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';
import Text from '@/components/switchback/Text/Text';
import { useBreakpoint } from '@/hooks/useBreakpoint';

const LocationNotice: React.FC = () => {
  const { isMobile } = useBreakpoint();
  return (
    <div className="px-4 pt-5 md:pb-0 md:px-5">
      <Notice icon={isMobile ? undefined : MAP_PIN} variant={NoticeType.warning} filled={true}>
        <Text type="paragraph" className="text-sm semiHighlight">
          <FormattedMessage defaultMessage="The easiest way to RV: delivery with full set-up. Enter a destination address to see rigs you can stay in, without having to drive or tow." />
        </Text>
      </Notice>
    </div>
  );
};

export default LocationNotice;
