import { TIconName } from '@outdoorsyco/bonfire';

import { ICONS } from '@/components/switchback/Icon/Icon.constants';

import { THookupFilters } from './moreFilter';

export enum EVehicleHookupsCampgrounds {
  ELECTRICITY = 'electricity_hookups',
  SEWER = 'sewer_hookups',
  WATER = 'water_hookups',
}

export const vehicleHookupsToCampgroundVehicleHookupsMap: Record<
  THookupFilters,
  EVehicleHookupsCampgrounds
> = {
  hookup_electric: EVehicleHookupsCampgrounds.ELECTRICITY,
  hookup_sewer: EVehicleHookupsCampgrounds.SEWER,
  hookup_water: EVehicleHookupsCampgrounds.WATER,
};

export type AMENITIES_KEYS =
  | 'air_conditioner'
  | 'heater'
  | 'ceiling_fan'
  | 'refrigerator'
  | 'kitchen_sink'
  | 'microwave'
  | 'stove'
  | 'dining_table'
  | 'oven'
  | 'inside_shower'
  | 'toilet'
  | 'outside_shower'
  | 'generator'
  | 'backup_camera'
  | 'tv_dvd'
  | 'leveling_jacks'
  | 'wifi'
  | 'tow_hitch'
  | 'awning'
  | 'satellite'
  | 'bike_rack'
  | 'washer_dryer'
  | 'audio_inputs'
  | 'inverter'
  | 'radio'
  | 'extra_storage'
  | 'solar'
  | 'cd_player'
  | 'handicap_accessible'
  | 'beds_full'
  | 'beds_king'
  | 'beds_queen'
  | 'beds_twin'
  | 'beds_dinette_conversion'
  | 'beds_bunk'
  | 'beds_fold_out_sofa'
  | 'beds_other'
  | 'bath_towels'
  | 'bathroom_sink'
  | 'toilet_paper'
  | 'toiletries'
  | 'hot_water'
  | 'potable_water'
  | 'coffee_maker'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'cooking_basics'
  | 'hookup_water'
  | 'hookup_electric'
  | 'hookup_sewer';

export type AMENITIES_FOR_STAY_KEYS =
  | 'air_conditioning'
  | 'bath_towels'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'compost_toilet'
  | 'hot_water'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'toiletries'
  | 'tv_dvd'
  | 'flush_toilet'
  | 'toilet_paper'
  | 'dryer'
  | 'pillows'
  | 'blankets'
  | 'fresh_linens'
  | 'washer'
  | 'coffee_maker'
  | 'dishes_and_utensils'
  | 'potable_water'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'workspace'
  | 'outdoor_furniture'
  | 'patio_balcony_deck'
  | 'pool'
  | 'bbq_grill'
  | 'fire_pit'
  | 'hot_tub'
  | 'picnic_table'
  | 'first_aid_kit'
  | 'carbon_monoxide_detector'
  | 'fire_extinguisher'
  | 'smoke_detector'
  | 'air_conditioning'
  | 'heating'
  | 'indoor_fireplace'
  | 'portable_fan'
  | 'ceiling_fan'
  | 'kitchen_sink'
  | 'microwave'
  | 'oven'
  | 'refrigerator'
  | 'stove'
  | 'dining_table'
  | 'wifi';

export type AMENITIES_FOR_RV_CAMPSITES_KEYS =
  | 'sleeps'
  | 'potable_water'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'hot_water'
  | 'wifi'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | 'allows_slideouts'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'covered_rv_spot'
  | 'max_vehicle_length'
  | 'allows_rv'
  | 'pull_through'
  | 'back_in'
  | 'pull_in'
  | '20_amp'
  | '30_amp'
  | '50_amp'
  | '100_amp'
  | '110_amp'
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'sewer_hookups'
  | 'allows_generators'
  | 'dump_station'
  | 'grey_water_discharge'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type AMENITIES_FOR_LODGING_CAMPSITES_KEYS =
  | 'bedrooms'
  | 'beds'
  | 'sleeps'
  | 'blankets'
  | 'linens'
  | 'pillows'
  | 'potable_water'
  | 'refrigerator'
  | 'microwave'
  | 'oven'
  | 'stove'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'bath_towels'
  | 'toiletries'
  | 'hot_water'
  | 'wifi'
  | 'workspace'
  | 'air_conditioning'
  | 'heating'
  | 'ceiling_fan'
  | 'indoor_fireplace'
  | 'co_detector'
  | 'smoke_detector'
  | 'fire_extinguisher'
  | 'first_aid_kit'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'kitchen_sink'
  | 'dining_table'
  | 'coffee_maker'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'tv'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'max_vehicle_length'
  | 'allows_rv'
  | 'pull_through'
  | 'back_in'
  | 'pull_in'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type AMENITIES_FOR_TENT_CAMPSITES_KEYS =
  | 'sleeps'
  | 'potable_water'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'hot_water'
  | 'wifi'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'tv'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_KEYS =
  // RV site amenities
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'covered_rv_spot'
  // RV amenities
  | 'awning'
  | 'extra_storage'
  | 'audio_inputs'
  | 'radio'
  | 'satellite'
  | 'cd_player'
  // lodging site amenities
  | 'ada_accessible'
  | 'air_conditioning'
  | 'bath_towels'
  | 'bbq_grill'
  | 'blankets'
  | 'carbon_monoxide_detector'
  | 'ceiling_fan'
  | 'coffee_maker'
  | 'compost_toilet'
  | 'cooking_basics'
  | 'dining_table'
  | 'dishes_and_utensils'
  | 'dryer'
  | 'fire_extinguisher'
  | 'fire_pit'
  | 'first_aid_kit'
  | 'flush_toilet'
  | 'fresh_linens'
  | 'heating'
  | 'hot_water'
  | 'indoor_fireplace'
  | 'indoor_shower'
  | 'kitchen_sink'
  | 'microwave'
  | 'outdoor_furniture'
  | 'outdoor_shower'
  | 'oven'
  | 'picnic_table'
  | 'pillows'
  | 'portable_fan'
  | 'potable_water'
  | 'pots_and_pans'
  | 'refrigerator'
  | 'smoke_detector'
  | 'stove'
  | 'toilet_paper'
  | 'toiletries'
  | 'tv_dvd'
  | 'washer'
  | 'wheelchair_accessible'
  | 'wifi'
  | 'workspace';

export type AMENITIES_FOR_CAMPSITES_KEYS =
  | AMENITIES_FOR_STAY_KEYS
  | AMENITIES_FOR_RV_CAMPSITES_KEYS
  | AMENITIES_FOR_LODGING_CAMPSITES_KEYS
  | AMENITIES_FOR_TENT_CAMPSITES_KEYS;

export type AMENITIES_FOR_CAMPGROUNDS_KEYS =
  | 'potable_water'
  | 'hot_water'
  | 'wifi'
  | 'air_conditioning'
  | 'heating'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'tv'
  | 'allows_pets'
  | 'onsite_showers'
  | 'onsite_bathrooms'
  | 'general_store'
  | 'firewood'
  | 'trash_bins'
  | 'allows_campfires'
  | 'allows_rv'
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'sewer_hookups'
  | 'allows_generators'
  | 'dump_station'
  | 'grey_water_discharge'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_fire_pit'
  | 'community_bbq'
  | 'community_picnic_tables'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type TAmenitiesIcons =
  | {
      icon?: null;
      bonfireIcon: TIconName;
      unavailableIcon?: null;
    }
  | {
      icon: ICONS;
      bonfireIcon?: null;
      unavailableIcon: ICONS | null;
    };

export const AMENITIES_FOR_STAY_ICONS: Record<AMENITIES_FOR_STAY_KEYS, TAmenitiesIcons> = {
  air_conditioning: {
    icon: ICONS.AIR_CONDITIONING_FOR_STAY,
    unavailableIcon: ICONS.AIR_CONDITIONING_FOR_STAY_UNAVAILABLE,
  },
  bath_towels: {
    icon: ICONS.BATH_TOWELS_FOR_STAY,
    unavailableIcon: ICONS.BATH_TOWELS_FOR_STAY_UNAVAILABLE,
  },
  ada_accessible: {
    icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
    unavailableIcon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY_UNAVAILABLE,
  },
  wheelchair_accessible: {
    icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
    unavailableIcon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY_UNAVAILABLE,
  },
  compost_toilet: {
    icon: ICONS.COMPOST_TOILET_FOR_STAY,
    unavailableIcon: ICONS.COMPOST_TOILET_FOR_STAY_UNAVAILABLE,
  },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  indoor_shower: {
    icon: ICONS.INDOOR_SHOWER_FOR_STAY,
    unavailableIcon: ICONS.INDOOR_SHOWER_FOR_STAY_UNAVAILABLE,
  },
  outdoor_shower: {
    icon: ICONS.OUTDOOR_SHOWER_FOR_STAY,
    unavailableIcon: ICONS.OUTDOOR_SHOWER_FOR_STAY_UNAVAILABLE,
  },
  toiletries: {
    icon: ICONS.TOILETRIES_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  flush_toilet: {
    icon: ICONS.FLUSH_TOILET_FOR_STAY,
    unavailableIcon: ICONS.FLUSH_TOILET_FOR_STAY_UNAVAILABLE,
  },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILET_PAPER_FOR_STAY_UNAVAILABLE,
  },
  tv_dvd: {
    icon: ICONS.TV_DVD_FOR_STAY,
    unavailableIcon: ICONS.TV_DVD_FOR_STAY_UNAVAILABLE,
  },
  dryer: {
    icon: ICONS.DRYER_FOR_STAY,
    unavailableIcon: ICONS.DRYER_FOR_STAY_UNAVAILABLE,
  },
  pillows: {
    icon: ICONS.PILLOWS_FOR_STAY,
    unavailableIcon: ICONS.PILLOWS_FOR_STAY_UNAVAILABLE,
  },
  blankets: {
    icon: ICONS.BLANKETS_FOR_STAY,
    unavailableIcon: ICONS.BLANKETS_FOR_STAY_UNAVAILABLE,
  },
  fresh_linens: {
    icon: ICONS.FRESH_LINENS_FOR_STAY,
    unavailableIcon: ICONS.FRESH_LINENS_FOR_STAY_UNAVAILABLE,
  },
  washer: {
    icon: ICONS.WASHER_FOR_STAY,
    unavailableIcon: ICONS.WASHER_FOR_STAY_UNAVAILABLE,
  },
  coffee_maker: {
    icon: ICONS.COFFEE_MAKER_FOR_STAY,
    unavailableIcon: ICONS.COFFEE_MAKER_FOR_STAY_UNAVAILABLE,
  },
  dishes_and_utensils: {
    icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
    unavailableIcon: ICONS.DISHES_AND_UTENSILS_FOR_STAY_UNAVAILABLE,
  },
  potable_water: {
    icon: ICONS.POTABLE_WATER_FOR_STAY,
    unavailableIcon: ICONS.POTABLE_WATER_FOR_STAY_UNAVAILABLE,
  },
  cooking_basics: {
    icon: ICONS.COOKING_BASICS_FOR_STAY,
    unavailableIcon: ICONS.COOKING_BASICS_FOR_STAY_UNAVAILABLE,
  },
  pots_and_pans: {
    icon: ICONS.POTS_AND_PANS_FOR_STAY,
    unavailableIcon: ICONS.POTS_AND_PANS_FOR_STAY_UNAVAILABLE,
  },
  workspace: {
    icon: ICONS.WORKSPACE_FOR_STAY,
    unavailableIcon: ICONS.WORKSPACE_FOR_STAY_UNAVAILABLE,
  },
  outdoor_furniture: {
    icon: ICONS.OUTDOOR_FURNITURE_FOR_STAY,
    unavailableIcon: ICONS.OUTDOOR_FURNITURE_FOR_STAY_UNAVAILABLE,
  },
  patio_balcony_deck: {
    icon: ICONS.PATIO_BALCONY_DECK_FOR_STAY,
    unavailableIcon: ICONS.PATIO_BALCONY_DECK_FOR_STAY_UNAVAILABLE,
  },
  pool: {
    icon: ICONS.POOL_FOR_STAY,
    unavailableIcon: ICONS.POOL_FOR_STAY_UNAVAILABLE,
  },
  bbq_grill: {
    icon: ICONS.BBQ_GRILL_FOR_STAY,
    unavailableIcon: ICONS.BBQ_GRILL_FOR_STAY_UNAVAILABLE,
  },
  fire_pit: {
    icon: ICONS.FIRE_PIT_FOR_STAY,
    unavailableIcon: ICONS.FIRE_PIT_FOR_STAY_UNAVAILABLE,
  },
  hot_tub: {
    icon: ICONS.HOT_TUB_FOR_STAY,
    unavailableIcon: ICONS.HOT_TUB_FOR_STAY_UNAVAILABLE,
  },
  dining_table: {
    icon: ICONS.DINING_TABLE_FOR_STAY,
    unavailableIcon: ICONS.DINING_TABLE_FOR_STAY_UNAVAILABLE,
  },
  picnic_table: {
    icon: ICONS.PICNIC_TABLE_FOR_STAY,
    unavailableIcon: ICONS.PICNIC_TABLE_FOR_STAY_UNAVAILABLE,
  },
  first_aid_kit: {
    icon: ICONS.FIRST_AID_KIT_FOR_STAY,
    unavailableIcon: ICONS.FIRST_AID_KIT_FOR_STAY_UNAVAILABLE,
  },
  carbon_monoxide_detector: {
    icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  fire_extinguisher: {
    icon: ICONS.FIRE_EXTINGUISHER_FOR_STAY,
    unavailableIcon: ICONS.FIRE_EXTINGUISHER_FOR_STAY_UNAVAILABLE,
  },
  smoke_detector: {
    icon: ICONS.SMOKE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.SMOKE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  wifi: {
    icon: ICONS.WIFI_FOR_STAY,
    unavailableIcon: ICONS.WIFI_FOR_STAY_UNAVAILABLE,
  },
  heating: {
    icon: ICONS.HEATING_FOR_STAY,
    unavailableIcon: ICONS.HEATING_FOR_STAY_UNAVAILABLE,
  },
  indoor_fireplace: {
    icon: ICONS.INDOOR_FIREPLACE_FOR_STAY,
    unavailableIcon: ICONS.INDOOR_FIREPLACE_FOR_STAY_UNAVAILABLE,
  },
  portable_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  ceiling_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  kitchen_sink: {
    icon: ICONS.KITCHEN_SINK_FOR_STAY,
    unavailableIcon: ICONS.KITCHEN_SINK_FOR_STAY_UNAVAILABLE,
  },
  microwave: {
    icon: ICONS.MICROWAVE_FOR_STAY,
    unavailableIcon: ICONS.MICROWAVE_FOR_STAY_UNAVAILABLE,
  },
  oven: {
    icon: ICONS.OVEN_FOR_STAY,
    unavailableIcon: ICONS.OVEN_FOR_STAY_UNAVAILABLE,
  },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  stove: {
    icon: ICONS.STOVE_FOR_STAY,
    unavailableIcon: ICONS.STOVE_FOR_STAY_UNAVAILABLE,
  },
};

export const AMENITIES_FOR_RV_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_RV_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  sleeps: { bonfireIcon: 'Amenities.Booking.Sleeps' },
  potable_water: { bonfireIcon: 'Amenities.Kitchen.PotableWater' },
  bathrooms: { bonfireIcon: 'Amenities.Bathroom.Bathrooms' },
  indoor_shower: { bonfireIcon: 'Amenities.Shower.Inside.ShowerInside' },
  outdoor_shower: { bonfireIcon: 'Amenities.Shower.Outside.ShowerOutside' },
  flush_toilet: { bonfireIcon: 'Amenities.Toilet.Flush' },
  compost_toilet: { bonfireIcon: 'Amenities.Toilet.Compost' },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { bonfireIcon: 'Amenities.Wifi.Wifi' },
  ada_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  wheelchair_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  grill: { bonfireIcon: 'Amenities.Outdoor.Grill' },
  hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  outdoor_furniture: { bonfireIcon: 'Amenities.Outdoor.OutdoorFurniture' },
  pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  picnic_table: { bonfireIcon: 'Amenities.Outdoor.PicnicTable' },
  patio_deck_or_balcony: { bonfireIcon: 'Amenities.Outdoor.Patio' },
  cooking_basics: { bonfireIcon: 'Amenities.Kitchen.CookingBasics' },
  pots_and_pans: { bonfireIcon: 'Amenities.Kitchen.PotsAndPans' },
  dishes_and_utensils: { bonfireIcon: 'Amenities.Kitchen.DishesAndUtensils' },
  allows_pets: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  size_area: { bonfireIcon: 'Amenities.SizeArea' },
  firewood: { bonfireIcon: 'Amenities.Firewood' },
  trash_bins: { bonfireIcon: 'Amenities.Dumpster' },
  grass_terrain: { bonfireIcon: 'Amenities.Terrain' },
  field_terrain: { bonfireIcon: 'Amenities.Terrain' },
  dirt_terrain: { bonfireIcon: 'Amenities.Terrain' },
  gravel_terrain: { bonfireIcon: 'Amenities.Terrain' },
  concrete_terrain: { bonfireIcon: 'Amenities.Terrain' },
  allows_campfires: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  '4wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  '2wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  covered_rv_spot: { bonfireIcon: 'Roamly.Shelter' },
  max_vehicle_length: { bonfireIcon: 'General.Length' },
  allows_rv: { bonfireIcon: 'Vehicles.Vehicles.ClassC.Simple' },
  pull_through: { bonfireIcon: 'Amenities.Parking.Parking' },
  back_in: { bonfireIcon: 'Amenities.Parking.Parking' },
  pull_in: { bonfireIcon: 'Amenities.Parking.Parking' },
  '20_amp': { bonfireIcon: 'Amenities.Electricity.Power' },
  '30_amp': { bonfireIcon: 'Amenities.Electricity.Power' },
  '50_amp': { bonfireIcon: 'Amenities.Electricity.Power' },
  '100_amp': { bonfireIcon: 'Amenities.Electricity.Power' },
  '110_amp': { bonfireIcon: 'Amenities.Electricity.Power' },
  full_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  electricity_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  water_hookups: { bonfireIcon: 'Amenities.Waterfuel.FreshWater' },
  sewer_hookups: { bonfireIcon: 'Amenities.Waterfuel.SewageTank' },
  allows_generators: { bonfireIcon: 'Amenities.Electricity.Generator' },
  dump_station: { bonfireIcon: 'Amenities.DumpStation' },
  grey_water_discharge: { bonfireIcon: 'Amenities.Waterfuel.Watertank' },
  docks_access: { bonfireIcon: 'Amenities.DockAccess' },
  ev_charging: { bonfireIcon: 'Amenities.Electricity.Power' },
  gated_entrance: { bonfireIcon: 'Amenities.Outdoor.Fence' },
  community_fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  basketball_court: { bonfireIcon: 'Amenities.Basketball' },
  beach: { bonfireIcon: 'Terrain.Terrain.Beach' },
  golf_course: { bonfireIcon: 'Amenities.Golf' },
  dog_park: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  event_center: { bonfireIcon: 'Amenities.EventCenter' },
  clubhouse: { bonfireIcon: 'Amenities.Clubhouse' },
  business_center: { bonfireIcon: 'General.Briefcase' },
  community_swimming_pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  community_hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  community_laundry: { bonfireIcon: 'Amenities.Home.WasherDryer' },
  library: { bonfireIcon: 'General.Book.Book' },
  mini_golf: { bonfireIcon: 'Amenities.Minigolf' },
  personal_mailboxes: { bonfireIcon: 'General.Mail' },
  playground: { bonfireIcon: 'Amenities.Playground' },
  propane_fills: { bonfireIcon: 'Amenities.Propane' },
  recreation_center: { bonfireIcon: 'Amenities.GameRoom' },
  restaurants_on_site: { bonfireIcon: 'Amenities.Kitchen.Silverware' },
  rv_storage: { bonfireIcon: 'Amenities.RvStorage' },
  snack_bar: { bonfireIcon: 'Amenities.SnackBar' },
  bike_rentals: { bonfireIcon: 'Activities.Activities.Biking' },
  boat_rentals: { bonfireIcon: 'Activities.Activities.Boating' },
  allows_slideouts: {
    bonfireIcon: 'Amenities.Slideout',
  },
};

export const AMENITIES_FOR_LODGING_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_LODGING_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  bedrooms: { bonfireIcon: 'Amenities.Booking.Bedrooms' },
  beds: { bonfireIcon: 'Amenities.Booking.Sleeps' },
  sleeps: { bonfireIcon: 'Amenities.Booking.Sleeps' },
  blankets: { bonfireIcon: 'Amenities.Home.Blankets' },
  linens: { bonfireIcon: 'Amenities.Home.Linens' },
  pillows: { bonfireIcon: 'Amenities.Pillow' },
  potable_water: { bonfireIcon: 'Amenities.Kitchen.PotableWater' },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  microwave: { bonfireIcon: 'Amenities.Microwave.Microwave' },
  oven: { bonfireIcon: 'Amenities.Oven.Oven' },
  stove: { bonfireIcon: 'Amenities.Stove.Stove' },
  bathrooms: { bonfireIcon: 'Amenities.Bathroom.Bathrooms' },
  indoor_shower: { bonfireIcon: 'Amenities.Shower.Inside.ShowerInside' },
  outdoor_shower: { bonfireIcon: 'Amenities.Shower.Outside.ShowerOutside' },
  flush_toilet: { bonfireIcon: 'Amenities.Toilet.Flush' },
  compost_toilet: { bonfireIcon: 'Amenities.Toilet.Compost' },
  bath_towels: { bonfireIcon: 'Amenities.Bathroom.BathTowels' },
  toiletries: { bonfireIcon: 'Amenities.Bathroom.Toiletries' },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { bonfireIcon: 'Amenities.Wifi.Wifi' },
  workspace: { bonfireIcon: 'Amenities.Home.Workspace' },
  air_conditioning: { bonfireIcon: 'Amenities.AirConditioner.AirConditioner' },
  heating: { bonfireIcon: 'Amenities.Heater.Available' },
  ceiling_fan: { bonfireIcon: 'Amenities.CeilingFan.CeilingFan' },
  indoor_fireplace: { bonfireIcon: 'Amenities.Temperature.IndoorFireplace' },
  co_detector: { bonfireIcon: 'Amenities.Safety.CarbonMonoxideDetector' },
  smoke_detector: { bonfireIcon: 'Amenities.Safety.SmokeDetector' },
  fire_extinguisher: { bonfireIcon: 'Amenities.Safety.FireExtinguisher' },
  first_aid_kit: { bonfireIcon: 'Amenities.Safety.FirstAidKit' },
  ada_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  wheelchair_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  grill: { bonfireIcon: 'Amenities.Outdoor.Grill' },
  hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  outdoor_furniture: { bonfireIcon: 'Amenities.Outdoor.OutdoorFurniture' },
  pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  picnic_table: { bonfireIcon: 'Amenities.Outdoor.PicnicTable' },
  patio_deck_or_balcony: { bonfireIcon: 'Amenities.Outdoor.Patio' },
  kitchen_sink: { bonfireIcon: 'Amenities.Sink.KitchenSink' },
  dining_table: { bonfireIcon: 'Amenities.Outdoor.DiningTable' },
  coffee_maker: { bonfireIcon: 'Amenities.Kitchen.CoffeeMaker' },
  cooking_basics: { bonfireIcon: 'Amenities.Kitchen.CookingBasics' },
  pots_and_pans: { bonfireIcon: 'Amenities.Kitchen.PotsAndPans' },
  dishes_and_utensils: { bonfireIcon: 'Amenities.Kitchen.DishesAndUtensils' },
  tv: { bonfireIcon: 'Amenities.Tv.Tv' },
  allows_pets: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  size_area: { bonfireIcon: 'Amenities.SizeArea' },
  firewood: { bonfireIcon: 'Amenities.Firewood' },
  trash_bins: { bonfireIcon: 'Amenities.Dumpster' },
  grass_terrain: { bonfireIcon: 'Amenities.Terrain' },
  field_terrain: { bonfireIcon: 'Amenities.Terrain' },
  dirt_terrain: { bonfireIcon: 'Amenities.Terrain' },
  gravel_terrain: { bonfireIcon: 'Amenities.Terrain' },
  concrete_terrain: { bonfireIcon: 'Amenities.Terrain' },
  allows_campfires: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  '4wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  '2wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  max_vehicle_length: { bonfireIcon: 'General.Length' },
  allows_rv: { bonfireIcon: 'Vehicles.Vehicles.ClassC.Simple' },
  pull_through: { bonfireIcon: 'Amenities.Parking.Parking' },
  back_in: { bonfireIcon: 'Amenities.Parking.Parking' },
  pull_in: { bonfireIcon: 'Amenities.Parking.Parking' },
  docks_access: { bonfireIcon: 'Amenities.DockAccess' },
  ev_charging: { bonfireIcon: 'Amenities.Electricity.Power' },
  gated_entrance: { bonfireIcon: 'Amenities.Outdoor.Fence' },
  community_fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  basketball_court: { bonfireIcon: 'Amenities.Basketball' },
  beach: { bonfireIcon: 'Terrain.Terrain.Beach' },
  golf_course: { bonfireIcon: 'Amenities.Golf' },
  dog_park: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  event_center: { bonfireIcon: 'Amenities.EventCenter' },
  clubhouse: { bonfireIcon: 'Amenities.Clubhouse' },
  business_center: { bonfireIcon: 'General.Briefcase' },
  community_swimming_pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  community_hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  community_laundry: { bonfireIcon: 'Amenities.Home.WasherDryer' },
  library: { bonfireIcon: 'General.Book.Book' },
  mini_golf: { bonfireIcon: 'Amenities.Minigolf' },
  personal_mailboxes: { bonfireIcon: 'General.Mail' },
  playground: { bonfireIcon: 'Amenities.Playground' },
  propane_fills: { bonfireIcon: 'Amenities.Propane' },
  recreation_center: { bonfireIcon: 'Amenities.GameRoom' },
  restaurants_on_site: { bonfireIcon: 'Amenities.Kitchen.Silverware' },
  rv_storage: { bonfireIcon: 'Amenities.RvStorage' },
  snack_bar: { bonfireIcon: 'Amenities.SnackBar' },
  bike_rentals: { bonfireIcon: 'Activities.Activities.Biking' },
  boat_rentals: { bonfireIcon: 'Activities.Activities.Boating' },
};

export const AMENITIES_FOR_TENT_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_TENT_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  sleeps: { bonfireIcon: 'Amenities.Booking.Sleeps' },
  potable_water: { bonfireIcon: 'Amenities.Kitchen.PotableWater' },
  bathrooms: { bonfireIcon: 'Amenities.Bathroom.Bathrooms' },
  indoor_shower: { bonfireIcon: 'Amenities.Shower.Inside.ShowerInside' },
  outdoor_shower: { bonfireIcon: 'Amenities.Shower.Outside.ShowerOutside' },
  flush_toilet: { bonfireIcon: 'Amenities.Toilet.Flush' },
  compost_toilet: { bonfireIcon: 'Amenities.Toilet.Compost' },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { bonfireIcon: 'Amenities.Wifi.Wifi' },
  ada_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  wheelchair_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  grill: { bonfireIcon: 'Amenities.Outdoor.Grill' },
  hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  outdoor_furniture: { bonfireIcon: 'Amenities.Outdoor.OutdoorFurniture' },
  pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  picnic_table: { bonfireIcon: 'Amenities.Outdoor.PicnicTable' },
  patio_deck_or_balcony: { bonfireIcon: 'Amenities.Outdoor.Patio' },
  cooking_basics: { bonfireIcon: 'Amenities.Kitchen.CookingBasics' },
  pots_and_pans: { bonfireIcon: 'Amenities.Kitchen.PotsAndPans' },
  dishes_and_utensils: { bonfireIcon: 'Amenities.Kitchen.DishesAndUtensils' },
  tv: { bonfireIcon: 'Amenities.Tv.Tv' },
  allows_pets: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  size_area: { bonfireIcon: 'Amenities.SizeArea' },
  firewood: { bonfireIcon: 'Amenities.Firewood' },
  trash_bins: { bonfireIcon: 'Amenities.Dumpster' },
  grass_terrain: { bonfireIcon: 'Amenities.Terrain' },
  field_terrain: { bonfireIcon: 'Amenities.Terrain' },
  dirt_terrain: { bonfireIcon: 'Amenities.Terrain' },
  gravel_terrain: { bonfireIcon: 'Amenities.Terrain' },
  concrete_terrain: { bonfireIcon: 'Amenities.Terrain' },
  allows_campfires: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  '4wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  '2wd_recommended': { bonfireIcon: 'Roamly.WheelTireMono' },
  docks_access: { bonfireIcon: 'Amenities.DockAccess' },
  ev_charging: { bonfireIcon: 'Amenities.Electricity.Power' },
  gated_entrance: { bonfireIcon: 'Amenities.Outdoor.Fence' },
  community_fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  basketball_court: { bonfireIcon: 'Amenities.Basketball' },
  beach: { bonfireIcon: 'Terrain.Terrain.Beach' },
  golf_course: { bonfireIcon: 'Amenities.Golf' },
  dog_park: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  event_center: { bonfireIcon: 'Amenities.EventCenter' },
  clubhouse: { bonfireIcon: 'Amenities.Clubhouse' },
  business_center: { bonfireIcon: 'General.Briefcase' },
  community_swimming_pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  community_hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  community_laundry: { bonfireIcon: 'Amenities.Home.WasherDryer' },
  library: { bonfireIcon: 'General.Book.Book' },
  mini_golf: { bonfireIcon: 'Amenities.Minigolf' },
  personal_mailboxes: { bonfireIcon: 'General.Mail' },
  playground: { bonfireIcon: 'Amenities.Playground' },
  propane_fills: { bonfireIcon: 'Amenities.Propane' },
  recreation_center: { bonfireIcon: 'Amenities.GameRoom' },
  restaurants_on_site: { bonfireIcon: 'Amenities.Kitchen.Silverware' },
  rv_storage: { bonfireIcon: 'Amenities.RvStorage' },
  snack_bar: { bonfireIcon: 'Amenities.SnackBar' },
  bike_rentals: { bonfireIcon: 'Activities.Activities.Biking' },
  boat_rentals: { bonfireIcon: 'Activities.Activities.Boating' },
};
export const AMENITIES_FOR_CAMPSITES_ICONS: Record<AMENITIES_FOR_CAMPSITES_KEYS, TAmenitiesIcons> =
  {
    ...AMENITIES_FOR_RV_CAMPSITES_ICONS,
    ...AMENITIES_FOR_LODGING_CAMPSITES_ICONS,
    ...AMENITIES_FOR_TENT_CAMPSITES_ICONS,
    ...AMENITIES_FOR_STAY_ICONS,
  };
export const AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  ada_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  air_conditioning: { bonfireIcon: 'Amenities.AirConditioner.AirConditioner' },
  bath_towels: { bonfireIcon: 'Amenities.Bathroom.BathTowels' },
  bbq_grill: {
    icon: ICONS.BBQ_GRILL_FOR_STAY,
    unavailableIcon: ICONS.BBQ_GRILL_FOR_STAY_UNAVAILABLE,
  },
  blankets: { bonfireIcon: 'Amenities.Home.Blankets' },
  carbon_monoxide_detector: {
    icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  ceiling_fan: { bonfireIcon: 'Amenities.CeilingFan.CeilingFan' },
  coffee_maker: { bonfireIcon: 'Amenities.Kitchen.CoffeeMaker' },
  compost_toilet: { bonfireIcon: 'Amenities.Toilet.Compost' },
  cooking_basics: { bonfireIcon: 'Amenities.Kitchen.CookingBasics' },
  dining_table: { bonfireIcon: 'Amenities.Outdoor.DiningTable' },
  dishes_and_utensils: { bonfireIcon: 'Amenities.Kitchen.DishesAndUtensils' },
  dryer: {
    icon: ICONS.DRYER_FOR_STAY,
    unavailableIcon: ICONS.DRYER_FOR_STAY_UNAVAILABLE,
  },
  fire_extinguisher: { bonfireIcon: 'Amenities.Safety.FireExtinguisher' },
  fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  first_aid_kit: { bonfireIcon: 'Amenities.Safety.FirstAidKit' },
  flush_toilet: { bonfireIcon: 'Amenities.Toilet.Flush' },
  fresh_linens: {
    icon: ICONS.FRESH_LINENS_FOR_STAY,
    unavailableIcon: ICONS.FRESH_LINENS_FOR_STAY_UNAVAILABLE,
  },
  heating: { bonfireIcon: 'Amenities.Heater.Available' },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  indoor_fireplace: { bonfireIcon: 'Amenities.Temperature.IndoorFireplace' },
  indoor_shower: { bonfireIcon: 'Amenities.Shower.Inside.ShowerInside' },
  kitchen_sink: { bonfireIcon: 'Amenities.Sink.KitchenSink' },
  microwave: { bonfireIcon: 'Amenities.Microwave.Microwave' },
  outdoor_furniture: { bonfireIcon: 'Amenities.Outdoor.OutdoorFurniture' },
  outdoor_shower: { bonfireIcon: 'Amenities.Shower.Outside.ShowerOutside' },
  oven: { bonfireIcon: 'Amenities.Oven.Oven' },
  picnic_table: { bonfireIcon: 'Amenities.Outdoor.PicnicTable' },
  pillows: { bonfireIcon: 'Amenities.Pillow' },
  portable_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  potable_water: { bonfireIcon: 'Amenities.Kitchen.PotableWater' },
  pots_and_pans: { bonfireIcon: 'Amenities.Kitchen.PotsAndPans' },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  smoke_detector: { bonfireIcon: 'Amenities.Safety.SmokeDetector' },
  stove: { bonfireIcon: 'Amenities.Stove.Stove' },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILET_PAPER_FOR_STAY_UNAVAILABLE,
  },
  toiletries: { bonfireIcon: 'Amenities.Bathroom.Toiletries' },
  tv_dvd: {
    icon: ICONS.TV_DVD_FOR_STAY,
    unavailableIcon: ICONS.TV_DVD_FOR_STAY_UNAVAILABLE,
  },
  washer: {
    icon: ICONS.WASHER_FOR_STAY,
    unavailableIcon: ICONS.WASHER_FOR_STAY_UNAVAILABLE,
  },
  wheelchair_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  wifi: { bonfireIcon: 'Amenities.Wifi.Wifi' },
  workspace: { bonfireIcon: 'Amenities.Home.Workspace' },
  full_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  electricity_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  water_hookups: { bonfireIcon: 'Amenities.Waterfuel.FreshWater' },
  grass_terrain: { bonfireIcon: 'Amenities.Terrain' },
  field_terrain: { bonfireIcon: 'Amenities.Terrain' },
  dirt_terrain: { bonfireIcon: 'Amenities.Terrain' },
  gravel_terrain: { bonfireIcon: 'Amenities.Terrain' },
  concrete_terrain: { bonfireIcon: 'Amenities.Terrain' },
  covered_rv_spot: { bonfireIcon: 'Roamly.Shelter' },
  audio_inputs: {
    icon: ICONS.AUDIO_INPUTS,
    unavailableIcon: ICONS.AUDIO_INPUTS_UNAVAILABLE,
  },
  radio: {
    icon: ICONS.RADIO,
    unavailableIcon: ICONS.RADIO_UNAVAILABLE,
  },
  satellite: {
    icon: ICONS.SATELLITE,
    unavailableIcon: ICONS.SATELLITE_UNAVAILABLE,
  },
  cd_player: {
    icon: ICONS.CD_PLAYER,
    unavailableIcon: ICONS.CD_PLAYER_UNAVAILABLE,
  },
  awning: {
    icon: ICONS.AWNING,
    unavailableIcon: ICONS.AWNING_UNAVAILABLE,
  },
  extra_storage: {
    icon: ICONS.EXTRA_STORAGE,
    unavailableIcon: ICONS.EXTRA_STORAGE_UNAVAILABLE,
  },
};

export const AMENITIES_FOR_CAMPGROUND_ICONS: Record<
  AMENITIES_FOR_CAMPGROUNDS_KEYS,
  TAmenitiesIcons
> = {
  potable_water: { bonfireIcon: 'Amenities.Kitchen.PotableWater' },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { bonfireIcon: 'Amenities.Wifi.Wifi' },
  air_conditioning: { bonfireIcon: 'Amenities.AirConditioner.AirConditioner' },
  heating: { bonfireIcon: 'Amenities.Heater.Available' },
  ada_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  wheelchair_accessible: { bonfireIcon: 'Amenities.Ada.Ada' },
  tv: { bonfireIcon: 'Amenities.Tv.Tv' },
  allows_pets: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  onsite_showers: { bonfireIcon: 'Amenities.Shower.Outside.ShowerOutside' },
  onsite_bathrooms: { bonfireIcon: 'Amenities.Bathroom.Bathrooms' },
  general_store: { bonfireIcon: 'Accomodation.AccomodationType.House' },
  firewood: { bonfireIcon: 'Amenities.Firewood' },
  trash_bins: { bonfireIcon: 'Amenities.Dumpster' },
  allows_campfires: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  allows_rv: { bonfireIcon: 'Vehicles.Vehicles.ClassC.Simple' },
  full_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  electricity_hookups: { bonfireIcon: 'Amenities.ElectricOutlet' },
  water_hookups: { bonfireIcon: 'Amenities.Waterfuel.FreshWater' },
  sewer_hookups: { bonfireIcon: 'Amenities.Waterfuel.SewageTank' },
  allows_generators: { bonfireIcon: 'Amenities.Electricity.Generator' },
  dump_station: { bonfireIcon: 'Amenities.DumpStation' },
  grey_water_discharge: { bonfireIcon: 'Amenities.Waterfuel.Watertank' },
  docks_access: { bonfireIcon: 'Amenities.DockAccess' },
  ev_charging: { bonfireIcon: 'Amenities.Electricity.Power' },
  gated_entrance: { bonfireIcon: 'Amenities.Outdoor.Fence' },
  basketball_court: { bonfireIcon: 'Amenities.Basketball' },
  beach: { bonfireIcon: 'Terrain.Terrain.Beach' },
  golf_course: { bonfireIcon: 'Amenities.Golf' },
  dog_park: { bonfireIcon: 'Amenities.Pets.PetFriendly' },
  event_center: { bonfireIcon: 'Amenities.EventCenter' },
  clubhouse: { bonfireIcon: 'Amenities.Clubhouse' },
  business_center: { bonfireIcon: 'General.Briefcase' },
  community_fire_pit: { bonfireIcon: 'Amenities.Outdoor.Firepit' },
  community_bbq: { bonfireIcon: 'Amenities.Outdoor.Grill' },
  community_picnic_tables: { bonfireIcon: 'Amenities.Outdoor.PicnicTable' },
  community_swimming_pool: { bonfireIcon: 'Amenities.Outdoor.Pool' },
  community_hot_tub: { bonfireIcon: 'Amenities.Outdoor.HotTub' },
  community_laundry: { bonfireIcon: 'Amenities.Home.WasherDryer' },
  library: { bonfireIcon: 'General.Book.Book' },
  mini_golf: { bonfireIcon: 'Amenities.Minigolf' },
  personal_mailboxes: { bonfireIcon: 'General.Mail' },
  playground: { bonfireIcon: 'Amenities.Playground' },
  propane_fills: { bonfireIcon: 'Amenities.Propane' },
  recreation_center: { bonfireIcon: 'Amenities.GameRoom' },
  restaurants_on_site: { bonfireIcon: 'Amenities.Kitchen.Silverware' },
  rv_storage: { bonfireIcon: 'Amenities.RvStorage' },
  snack_bar: { bonfireIcon: 'Amenities.SnackBar' },
  bike_rentals: { bonfireIcon: 'Activities.Activities.Biking' },
  boat_rentals: { bonfireIcon: 'Activities.Activities.Boating' },
};

export const AMENITIES_ICONS: Record<AMENITIES_KEYS, TAmenitiesIcons> = {
  air_conditioner: {
    icon: ICONS.AIR_CONDITIONER,
    unavailableIcon: ICONS.AIR_CONDITIONER_UNAVAILABLE,
  },
  bath_towels: {
    icon: ICONS.BATH_TOWELS_FOR_STAY,
    unavailableIcon: ICONS.BATH_TOWELS_FOR_STAY_UNAVAILABLE,
  },
  bathroom_sink: {
    icon: ICONS.KITCHEN_SINK,
    unavailableIcon: ICONS.KITCHEN_SINK_UNAVAILABLE,
  },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  toiletries: {
    icon: ICONS.TOILETRIES_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  potable_water: {
    icon: ICONS.POTABLE_WATER_FOR_STAY,
    unavailableIcon: ICONS.POTABLE_WATER_FOR_STAY_UNAVAILABLE,
  },
  coffee_maker: {
    icon: ICONS.COFFEE_MAKER_FOR_STAY,
    unavailableIcon: ICONS.COFFEE_MAKER_FOR_STAY_UNAVAILABLE,
  },
  pots_and_pans: {
    icon: ICONS.POTS_AND_PANS_FOR_STAY,
    unavailableIcon: ICONS.POTS_AND_PANS_FOR_STAY_UNAVAILABLE,
  },
  dishes_and_utensils: {
    icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
    unavailableIcon: ICONS.DISHES_AND_UTENSILS_FOR_STAY_UNAVAILABLE,
  },
  cooking_basics: {
    icon: ICONS.COOKING_BASICS_FOR_STAY,
    unavailableIcon: ICONS.COOKING_BASICS_FOR_STAY_UNAVAILABLE,
  },
  hookup_water: {
    bonfireIcon: 'Amenities.Waterfuel.Watertank',
  },
  hookup_electric: {
    bonfireIcon: 'Amenities.Electricity.Power',
  },
  hookup_sewer: {
    bonfireIcon: 'Amenities.Waterfuel.SewageTank',
  },
  heater: {
    icon: ICONS.HEATER,
    unavailableIcon: ICONS.HEATER_UNAVAILABLE,
  },
  ceiling_fan: {
    icon: ICONS.CEILING_FAN,
    unavailableIcon: ICONS.CEILING_FAN_UNAVAILABLE,
  },
  refrigerator: {
    icon: ICONS.FRIDGE,
    unavailableIcon: ICONS.FRIDGE_UNAVAILABLE,
  },
  kitchen_sink: {
    icon: ICONS.KITCHEN_SINK,
    unavailableIcon: ICONS.KITCHEN_SINK_UNAVAILABLE,
  },
  microwave: {
    icon: ICONS.MICROWAVE,
    unavailableIcon: ICONS.MICROWAVE_UNAVAILABLE,
  },
  stove: {
    icon: ICONS.STOVE,
    unavailableIcon: ICONS.STOVE_UNAVAILABLE,
  },
  dining_table: {
    icon: ICONS.TABLE,
    unavailableIcon: ICONS.TABLE_UNAVAILABLE,
  },
  oven: {
    icon: ICONS.OVEN,
    unavailableIcon: ICONS.OVEN_UNAVAILABLE,
  },
  inside_shower: {
    icon: ICONS.SHOWER_INSIDE,
    unavailableIcon: ICONS.SHOWER_INSIDE_UNAVAILABLE,
  },
  toilet: {
    icon: ICONS.TOILET,
    unavailableIcon: ICONS.TOILET_UNAVAILABLE,
  },
  outside_shower: {
    icon: ICONS.SHOWER_OUTSIDE,
    unavailableIcon: ICONS.SHOWER_OUTSIDE_UNAVAILABLE,
  },
  generator: {
    icon: ICONS.GENERATOR,
    unavailableIcon: ICONS.GENERATOR_UNAVAILABLE,
  },
  backup_camera: {
    icon: ICONS.BACKUP_CAMERA,
    unavailableIcon: ICONS.BACKUP_CAMERA_UNAVAILABLE,
  },
  tv_dvd: {
    icon: ICONS.TV,
    unavailableIcon: ICONS.TV_UNAVAILABLE,
  },
  leveling_jacks: {
    icon: ICONS.LEVELING_JACK,
    unavailableIcon: ICONS.LEVELING_JACK_UNAVAILABLE,
  },
  wifi: {
    icon: ICONS.WIFI,
    unavailableIcon: ICONS.WIFI_UNAVAILABLE,
  },
  tow_hitch: {
    icon: ICONS.TOW_HITCH,
    unavailableIcon: ICONS.TOW_HITCH_UNAVAILABLE,
  },
  awning: {
    icon: ICONS.AWNING,
    unavailableIcon: ICONS.AWNING_UNAVAILABLE,
  },
  satellite: {
    icon: ICONS.SATELLITE,
    unavailableIcon: ICONS.SATELLITE_UNAVAILABLE,
  },
  bike_rack: {
    icon: ICONS.BIKE_RACK,
    unavailableIcon: ICONS.BIKE_RACK_UNAVAILABLE,
  },
  washer_dryer: {
    icon: ICONS.WASHER_DRYER,
    unavailableIcon: ICONS.WASHER_DRYER_UNAVAILABLE,
  },
  audio_inputs: {
    icon: ICONS.AUDIO_INPUTS,
    unavailableIcon: ICONS.AUDIO_INPUTS_UNAVAILABLE,
  },
  inverter: {
    icon: ICONS.INVERTER,
    unavailableIcon: ICONS.INVERTER_UNAVAILABLE,
  },
  radio: {
    icon: ICONS.RADIO,
    unavailableIcon: ICONS.RADIO_UNAVAILABLE,
  },
  extra_storage: {
    icon: ICONS.EXTRA_STORAGE,
    unavailableIcon: ICONS.EXTRA_STORAGE_UNAVAILABLE,
  },
  solar: {
    icon: ICONS.SOLAR,
    unavailableIcon: ICONS.SOLAR_UNAVAILABLE,
  },
  cd_player: {
    icon: ICONS.CD_PLAYER,
    unavailableIcon: ICONS.CD_PLAYER_UNAVAILABLE,
  },
  handicap_accessible: {
    icon: ICONS.ACCESSIBILITY,
    unavailableIcon: ICONS.ACCESSIBILITY_UNAVAILABLE,
  },
  beds_bunk: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_dinette_conversion: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_fold_out_sofa: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_full: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_king: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_other: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_queen: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_twin: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
};
