import { createSelector } from 'reselect';

import { TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { authUserSelector } from '@/redux/selectors/auth/verifications';
import { getUserCurrency } from '@/redux/selectors/currency';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IOwner as IRentalOwner } from '@/services/types/search/rentals/id';
import { getProcessedBookingPayment } from '@/utility/booking';
import {
  isPeaceOfMindInsuranceBundle,
  isStationaryDeliveryInsuranceBundle,
} from '@/utility/insuranceBundle';

import { extractDateChangeData } from './utils';

export const bookingDetailsSelector = (state: TRootState) => state.booking.details.data;
export const loadBookingDetailsSelector = (state: TRootState) => state.booking.details.isFetching;

export const bookingServicesSelector = (state: TRootState) => state.booking.services.data;

export const bookingOwnerSelector = (state: TRootState) => state.booking.owner.data;

export const bookingGuestsSelector = (state: TRootState) => state.booking.passenger.data;

enum EPriceType {
  DAY = 'day',
  NIGHT = 'night',
}
export const selectBookingPriceType = (state: TRootState): EPriceType => {
  const useDay = state.booking.details.data?.use_day_pricing;
  return useDay ? EPriceType.DAY : EPriceType.NIGHT;
};

export const canModifyGuestsAndDriversSelector = (state: TRootState) =>
  isRenterSelector(state) &&
  ['negotiating', 'approved', 'imminent', 'handed_off'].includes(
    state.booking.details.data?.status || '',
  );
export const canModifyGuestsOccupancySelector = (state: TRootState) =>
  isRenterSelector(state) &&
  ['approved', 'imminent'].includes(state.booking.details.data?.status || '');

export const canVerifyMyIdSelector = (state: TRootState) =>
  ['negotiating', 'approved', 'imminent', 'handed_off'].includes(
    state.booking.details.data?.status || '',
  );

export const isTripStarted = (state: TRootState) =>
  !['draft', 'negotiating', 'approved', 'imminent'].includes(
    state.booking.details.data?.status || '',
  );

export const isBookingHandedOff = (state: TRootState) =>
  ['handed_off'].includes(state.booking.details.data?.status || '');

export const showTripConfirmationModal = (state: TRootState) =>
  ['negotiating', 'approved', 'imminent'].includes(state.booking.details.data?.status || '');

export const insuranceBundlesSelector = (state: TRootState) => state.booking.insuranceBundles.data;

export const isPeaceOfMindSelectedSelector = (state: TRootState) => {
  return isPeaceOfMindInsuranceBundle(
    state.booking?.details?.data?.insurance_bundle || state.quote?.data?.insurance_bundle,
  );
};

export const isStationaryDelieveryBundleSelectedSelector = (state: TRootState) => {
  return isStationaryDeliveryInsuranceBundle(
    state.booking?.details?.data?.insurance_bundle || state.quote?.data?.insurance_bundle,
  );
};

export const bookingBundleSelector = (state: TRootState) => state.booking.bookingBundle;

export const bookingDocumentsSelector = (state: TRootState) =>
  state.booking.details.data?.documents;

export const bookingApprovedSelector = (state: TRootState) =>
  state.booking.details.data?.owner_approved && state.booking.details.data?.renter_approved;

export const eSignFileSelector = (state: TRootState) => state.booking?.file?.data;

export const isRenterSelector = (state: TRootState) =>
  state.booking.details.data?.renter_id === state.auth?.user?.id;

export const renterDateChangeSelector = (
  state: TRootState,
  campgroundDateChangeIsAvailable: boolean,
) => {
  const booking = state.booking.details.data;
  const currentUser = authUserSelector(state);
  return extractDateChangeData(booking, currentUser, campgroundDateChangeIsAvailable);
};

export const canCancelBookingSelector = (state: TRootState) =>
  ['approved', 'imminent'].includes(state.booking?.details.data?.status || '');

export const canWithdrawBookingSelector = (state: TRootState) =>
  ['negotiating', 'conversation'].includes(state.booking.details.data?.status || '');

export const withdrawalReasonsSelector = (state: TRootState) =>
  state.booking?.withdrawalReasons.data;

export const addBookingServiceSelector = (state: TRootState) => state.booking?.addService;

export const removeBookingServiceSelector = (state: TRootState) => state.booking?.removeService;

export const fetchBookingWeatherSelector = (state: TRootState) => state.booking?.weather;

export const bookingIdSelector = (state: TRootState) => state.booking.details.data?.id;

export const addPassengerStatusSelector = (state: TRootState) => state.booking.addPassenger;

export const removePassengerStatusSelector = (state: TRootState) => state.booking.removePassenger;

export const bookingDateSelector = (state: TRootState) => {
  return [state.booking.details.data?.from, state.booking.details.data?.to];
};

export const currentBookingGuestSelector = (state: TRootState) => {
  const user = authUserSelector(state);
  const passengers = bookingGuestsSelector(state);
  const foundPassenger = passengers?.find(passenger => passenger.id === user?.id);
  return foundPassenger;
};

export const userPassengerVerificationStatus = (state: TRootState) => {
  const passenger = currentBookingGuestSelector(state);
  return passenger?.checks?.find(c => c.method === 'persona')?.status;
};

export const canFileClaimSelector = (state: TRootState) =>
  state.booking.details.data?.can_file_claim;
export const bookingUnrequiredItemsSelector = (state: TRootState) =>
  (state.booking.details.data?.addons || []).filter(item => !item.required && !item.rental_amount);

export const cancellationDetailSelector = (state: TRootState) =>
  state.booking.cancellationDetail.data;

export const bookingOwnerSummarySelector = (state: TRootState) =>
  state.booking.details.data?.owner_summary;

export const bookingConversionIdSelector = (state: TRootState) =>
  state.booking.details?.data?.conversation_id;

export const bookingPaymentConfigSelector = createSelector<
  TRootState,
  IBookingPayment | undefined,
  IRentalOwner | undefined,
  IBookingPayment
>(
  state => state.booking.details.meta,
  state => state.listing.data?.owner,
  getProcessedBookingPayment,
);

export const bookingIsBeingUpdatedSelector = (state: TRootState) =>
  state.booking.updateStatus.isFetching;

export const bookingUpdateFailedSelector = (state: TRootState) => state.booking.updateStatus.error;

export const getBookingCurrency = createSelector<
  TRootState,
  ReturnType<typeof bookingDetailsSelector>,
  ReturnType<typeof getUserCurrency>,
  TCurrency
>(
  bookingDetailsSelector,
  getUserCurrency,
  (booking, userCurrency) =>
    booking?.presentment_currency || booking?.locale.base_currency || userCurrency,
);

export const getRawBookingTotal = (state: TRootState) =>
  state.booking.details.data?.total_after_credits ?? state.booking.details.data?.total ?? null;
