// First day of the week
// 0 = Sunday
// 1 = Monday
// 6 = Saturday

const locales = {
  'de-de': {
    base_currency: 'EUR',
    country: 'DE',
    distance_unit: 'kilometers',
    domain: 'www.outdoorsy.de',
    language: 'Deutsch',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'de-de',
    name: 'Germany',
    weight_unit: 'kg',
    label: 'Germany (Deutsch)',
    first_day_of_the_week: 1,
    homepage_key: 'www-outdoorsy-de',
  },
  'en-au': {
    base_currency: 'AUD',
    country: 'AU',
    distance_unit: 'kilometers',
    domain: 'www.outdoorsy.com.au',
    language: 'English',
    length_unit: 'meters',
    liquid_unit: 'gallons',
    locale: 'en-au',
    name: 'Australia',
    weight_unit: 'lbs',
    label: 'Australia (English)',
    first_day_of_the_week: 1,
    homepage_key: 'www-outdoorsy-com-au',
  },
  'en-ca': {
    base_currency: 'CAD',
    country: 'CA',
    distance_unit: 'kilometers',
    domain: 'ca.outdoorsy.com',
    language: 'English',
    length_unit: 'meters',
    liquid_unit: 'gallons',
    locale: 'en-ca',
    name: 'Canada',
    weight_unit: 'kg',
    label: 'Canada (English)',
    first_day_of_the_week: 0,
    homepage_key: 'ca-outdoorsy-com',
  },
  'en-gb': {
    base_currency: 'GBP',
    country: 'GB',
    distance_unit: 'miles',
    domain: 'www.outdoorsy.co.uk',
    language: 'English',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'en-gb',
    name: 'United Kingdom',
    weight_unit: 'lbs',
    label: 'United Kingdom (English)',
    first_day_of_the_week: 1,
    homepage_key: 'www-outdoorsy-uk',
  },
  'en-nz': {
    base_currency: 'NZD',
    country: 'NZ',
    distance_unit: 'kilometers',
    domain: 'nz.outdoorsy.com',
    language: 'English',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'en-nz',
    name: 'New Zealand',
    weight_unit: 'lbs',
    label: 'New Zealand (English)',
    first_day_of_the_week: 1,
    homepage_key: 'nz-outdoorsy-com',
  },
  'en-us': {
    base_currency: 'USD',
    country: 'US',
    distance_unit: 'miles',
    domain: 'www.outdoorsy.com',
    language: 'English',
    length_unit: 'feet',
    liquid_unit: 'gallons',
    locale: 'en-us',
    name: 'United States',
    weight_unit: 'lbs',
    label: 'United States (English)',
    first_day_of_the_week: 0,
    homepage_key: 'www-outdoorsy-com',
  },
  'es-es': {
    base_currency: 'EUR',
    country: 'ES',
    distance_unit: 'kilometers',
    domain: 'es.outdoorsy.com',
    language: 'Español',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'es-es',
    name: 'Spain',
    weight_unit: 'kg',
    label: 'Spain (Español)',
    first_day_of_the_week: 1,
    homepage_key: 'es-outdoorsy-com',
  },
  'fr-ca': {
    base_currency: 'CAD',
    country: 'CA',
    distance_unit: 'kilometers',
    domain: 'ca-fr.outdoorsy.com',
    language: 'Français',
    length_unit: 'meters',
    liquid_unit: 'gallons',
    locale: 'fr-ca',
    name: 'Canada',
    weight_unit: 'kg',
    label: 'Canada (Français)',
    first_day_of_the_week: 0,
    homepage_key: 'fr-ca-outdoorsy-com',
  },
  'fr-fr': {
    base_currency: 'EUR',
    country: 'FR',
    distance_unit: 'kilometers',
    domain: 'www.outdoorsy.fr',
    language: 'Français',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'fr-fr',
    name: 'France',
    weight_unit: 'kg',
    label: 'France (Français)',
    first_day_of_the_week: 1,
    homepage_key: 'www-outdoorsy-fr',
  },
  'it-it': {
    base_currency: 'EUR',
    country: 'IT',
    distance_unit: 'kilometers',
    domain: 'www.outdoorsy.it',
    language: 'Italiano',
    length_unit: 'meters',
    liquid_unit: 'liters',
    locale: 'it-it',
    name: 'Italy',
    weight_unit: 'kg',
    label: 'Italy (Italiano)',
    first_day_of_the_week: 1,
    homepage_key: 'www-outdoorsy-it',
  },
};

const defaultLocale = locales['en-us'];

const localePerDomain = Object.values(locales).reduce(
  (prev, data) => ({ ...prev, [data.domain]: data }),
  {},
);

const domains = Object.values(locales).reduce((prev, data) => [...prev, data.domain], []);

module.exports = {
  defaultLocale,
  localePerDomain,
  locales,
  domains,
};
