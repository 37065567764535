import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import Transition from 'react-transition-group/Transition';

const easeOutBackSofter = 'cubic-bezier(0.34, 1.2, 0.64, 1)';

export interface IStateStyles {
  entering?: CSSProperties;
  entered?: CSSProperties;
  exiting?: CSSProperties;
  exited?: CSSProperties;
}

interface IModalAnimation {
  in?: boolean;
  duration?: number;
  style?: CSSProperties;
  styles?: IStateStyles;
  defaultStyle?: CSSProperties;
  children: React.ReactNode;
  timeout?:
    | number
    | Partial<{
        appear: number;
        enter: number;
        exit: number;
      }>;
}

const styleDefault = {
  opacity: 0,
};

const stateStyles: IStateStyles = {
  entered: {
    opacity: 1,
  },
};

const ModalAnimation: React.ForwardRefRenderFunction<
  Transition<HTMLElement | undefined>,
  IModalAnimation
> = (
  {
    in: inProp,
    duration = 250,
    timeout = 250,
    children,
    style: styleProp,
    defaultStyle = styleDefault,
    styles = stateStyles,
    ...rest
  },
  ref,
) => {
  const style = {
    ...styleProp,
    ...(React.isValidElement(children) ? children.props.style : {}),
  };
  return (
    <Transition timeout={timeout} in={inProp} appear={true} {...rest} ref={ref}>
      {(state: keyof IStateStyles, childProps: any) => {
        return React.cloneElement(children as React.ReactElement, {
          style: {
            ...defaultStyle,
            transitionDuration: `${duration}ms`,
            transitionTimingFunction: easeOutBackSofter,
            transitionProperty: 'transform, opacity',
            willChange: 'transform, opacity',
            ...styles[state],
            ...style,
          },
          ...childProps,
        });
      }}
    </Transition>
  );
};

export default React.forwardRef(ModalAnimation);
