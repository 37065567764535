import React from 'react';
import { useIntl } from 'react-intl';

import { useLocaleDestinations } from '@/hooks/useLocaleDestinations';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { trackNavClickedEvent } from '@/services/analytics/header';

const GlobalHeaderNavDestinations: React.FC = () => {
  const intl = useIntl();
  const { locale } = useLocaleSettings();

  const rvDestinations = useLocaleDestinations();

  const rvSortedDestinations = rvDestinations.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  const handleOnClick = (link: string) => {
    trackNavClickedEvent({
      clickedLink: link,
      isInDropdown: true,
      dropdownHeader: 'Destinations',
    });
  };

  const DestinationsSectionGrid = (props: {
    destinations: Array<{ href: string; label: string }>;
    destinationTitlePrefix: string;
  }) => (
    <ul
      role="menu"
      className="grid gap-y-6 gap-x-4 lg:gap-x-6"
      style={{ gridTemplateColumns: 'auto auto' }}>
      {props.destinations.map(item => (
        <li key={item.label}>
          <a
            href={item.href}
            title={`${props.destinationTitlePrefix} ${item.label}`}
            onClick={() => handleOnClick(item.label)}
            className="py-1 text-gray-800 autoType300 before-focus-style">
            <span aria-hidden>{item.label}</span>
          </a>
        </li>
      ))}
    </ul>
  );

  if (locale === 'en-us') {
    const seeAllLinks = [
      {
        href: '/rv-rental',
        label: intl.formatMessage({ defaultMessage: 'See all cities' }),
        testid: 'all-cities-link',
      },
      {
        href: '/guide/parks',
        label: intl.formatMessage({ defaultMessage: 'See all National & state parks' }),
        testid: 'all-parks-link',
      },
    ];

    const DestinationsSection = (props: { title: string; children: JSX.Element }) => (
      <div className="flex flex-col pb-6 mb-6 border-b gap-y-6 border-gray-310">
        <span className="font-bold text-gray-800 autoType300">{props.title}</span>
        {props.children}
      </div>
    );

    return (
      <div className="lg:w-72" data-testid="destinations-dropdown">
        <DestinationsSection
          title={intl.formatMessage({ defaultMessage: 'Featured RV destinations' })}>
          <DestinationsSectionGrid
            destinations={rvSortedDestinations}
            destinationTitlePrefix="RV Rental"
          />
        </DestinationsSection>

        <div className="grid gap-y-3 md:gap-y-6">
          {seeAllLinks.map((item, index) => (
            <a
              key={index}
              href={item.href}
              onClick={() => handleOnClick(item.label)}
              className="py-1 text-gray-800 whitespace-nowrap autoType300 before-focus-style">
              <span aria-hidden>{item.label}</span>
            </a>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="lg:w-72" data-testid="destinations-dropdown">
      <DestinationsSectionGrid
        destinations={rvSortedDestinations}
        destinationTitlePrefix="RV Rental"
      />
    </div>
  );
};

export default GlobalHeaderNavDestinations;
