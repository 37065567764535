import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { LOADING } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

const Loading: React.FC<{
  /** if defined, this will overwrite the default className defined below */
  className?: string;
  style?: CSSProperties;
  embedded?: boolean;
  additionalClassName?: string;
}> = ({ embedded, additionalClassName, ...props }) => {
  const intl = useIntl();
  let className = 'flex items-center justify-center text-gray-500 bg-white';

  if (embedded) {
    className += ' h-full w-full';
  } else {
    className += ' absolute inset-0';
  }

  if (additionalClassName) {
    className = `${className} ${additionalClassName}`;
  }

  return (
    <div aria-live="polite" data-testid="loading" className={className} {...props}>
      <span className="sr-only">
        {intl.formatMessage({
          defaultMessage: 'loading',
        })}
      </span>
      <Icon className="block text-gray-500 animate-spin" name={LOADING} size={IconSize.large} />
    </div>
  );
};

export default Loading;
